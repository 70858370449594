const _ = require('lodash');
const Bacon = require('baconjs');
const path4js = require('path4js');
const Yajas = require('yajas');
const $Payment = require('../modules/payment/view.js');
const { fetchInvoice } = require('../clever-client/billing-payment.js');
const { getPaymentMethods } = require('@clevercloud/client/esm/api/v4/billing.js')
const { sendToApi } = require('../send-to-api.js');

const sp = new (require('./AbstractSP.js'))({ name: 'BillSP' });

sp.getStreams = function (req) {
  const invoiceNumber = req.params.bid;

  const s_ownerId = Console.User.map((user) => req.params.oid || user.id);

  const s_invoice = s_ownerId.flatMapLatest((ownerId) => {
    return Bacon.fromPromise(fetchInvoice({ ownerId, invoiceNumber }));
  });

  const s_methods = s_ownerId.flatMapLatest((ownerId) => {
    return s_invoice.skipErrors().flatMapLatest(function () {
      return Bacon.fromPromise(getPaymentMethods({ id: ownerId }).then(sendToApi));
    });
  });

  const s_providers = s_invoice.skipErrors().flatMapLatest(function () {
    return [
      { name: 'CREDITCARD', imgUrl: '/img/payment-methods/credit-card.svg', comingSoon: false, title: T('payment.CREDITCARD') },
      { name: 'SEPA_DEBIT', imgUrl: '/img/payment-methods/sepa.svg', comingSoon: false, title: T('payment.SEPA_DEBIT') },
      { name: 'PAYPAL', imgUrl: '/img/payment-methods/paypal.svg', comingSoon: false, title: T('payment.PAYPAL') },
    ];
  });

  return {
    s_ownerId: s_ownerId,
    s_invoice: s_invoice,
    s_methods: s_methods,
    s_providers: s_providers,
  };
};

sp.on('onload', function (req, $container, streams) {
  Bacon.onValues(streams.s_ownerId, streams.s_invoice, streams.s_providers, streams.s_methods, _.partial(sp.payInvoice, $container));
});

sp.payInvoice = function ($container, ownerId, invoice, providers, methods) {
  const defaultMethod = _.find(methods, method => method.isDefault);
  var $payment = $Payment({
    container: $container.find('.payment'),
    invoice: invoice,
    providers: providers,
    methods: methods,
    orgaId: ownerId,
    defaultMethod,
  });

  $payment.onValue(function () {
    Yajas.path4js.launchPath(path4js.Request.fromUri((ownerId.startsWith('orga_') ? '/organisations/' + ownerId : '/users/me') + '/invoices'));
  });
};

module.exports = sp;
