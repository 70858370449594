/*
 * This module is a cache module.
 * Everything "durty" (understand: cached without bacon.js) that needs to be cached
 * should be cached with this module
 */

module.exports = window.Cache = (() => {
  const _ = require("lodash");

  let cache = {};
  let cacheTTLs = {};

  let $Cache = {};

  $Cache.checkType = (key, type) => {
    const _type = typeof key;

    if(_type.toLowerCase() !== type.toLowerCase()){
      throw new TypeError(`Value must be a ${type}. Actual type: ${_type}`);
    }

    return true;
  };

  $Cache.set = (key, data, ttl) => {
    if($Cache.checkType(key, "string")){
      cache[key] = data;
    }

    if(cacheTTLs[key]){
      clearTimeout(cacheTTLs[key]);
    }

    if(ttl !== undefined && $Cache.checkType(ttl, "number")){
      cacheTTLs[key] = setTimeout(() => {
        $Cache.delete(key);
      }, ttl);
    }

    return true;
  };

  $Cache.get = (key) => {
    return $Cache.checkType(key, "string") && cache[key];
  };

  $Cache.delete = (key) => {
    if(key === "*"){
      throw new Error("You can't delete all keys");
    }

    const star = key.indexOf("*");
    if(star >= 0){
      const prefix = key.substr(0, star);
      _.each(cache, (v, k) => {
        if(k.indexOf(prefix) === 0){
          delete cache[k];
        }
      });
    } else{
      $Cache.checkType(key, "string") && delete cache[key];
    }
  };

  return $Cache;
})();
