import _ from 'lodash';
import Bacon from 'baconjs';
import Templates from './templates/desktop-notifications._tmpl.js';
import { API_HOST } from '../../configuration.js';
import { EventsStream } from '@clevercloud/client/esm/streams/events.browser.js';
import { getTokens } from '../../send-to-api.js';

export function createEventsNotifications (settings) {

  var state = {
    excludeEvents: (settings.excludeEvents || []).concat(['socket_ready']),
    API: settings.API,
    SummaryProxy: settings.SummaryProxy,
    InstanceProxy: settings.InstanceProxy,
    DeploymentProxy: settings.DeploymentProxy,
    Templates,
    $Notification: settings.$Notification,
    T: settings.T,
  };

  state.EVENTS = ALL_EVENTS;

  state.events = init(state);
  state.s_events = state.events.s_events;
  state.s_rawEvents = state.events.s_rawEvents;

  return state;
};

function init (state) {
  var s_events = getEventsStream();

  var s_user = state.SummaryProxy.fetchUserOnce().toProperty();

  var s_madeEvents = Bacon
    .combineTemplate({
      message: s_events,
      user: s_user,
    })
    .map(_.partial(makeEvents, state));

  var s_filteredEvents = s_madeEvents.filter(_.partial(filterEvents, state));

  var s_rawEvents = s_filteredEvents
    .filter((data) => {
      var event = data.message.event || data.message.message_type;
      return !state.EVENTS[event].body;
    });

  var s_formattedEvents = s_filteredEvents
    .filter((data) => {
      var event = data.message.event || data.message.message_type;
      return state.EVENTS[event].body;
    })
    .flatMapLatest(_.partial(getSettings, state))
    .toProperty();

  s_madeEvents
    .flatMapLatest(_.partial(updateProxys, state))
    .onValue(() => {
      // Don't let it to be lazy
    });

  return {
    s_events: s_formattedEvents,
    s_rawEvents: s_rawEvents,
  };
};

function getOwnerIdFromAppId (state, appId) {
  return state
    .SummaryProxy
    .fetchOnce()
    .map((summary) => {
      return [summary.user].concat(_.reduce(summary.organisations, (arr, orga) => {
        return arr.concat([orga]);
      }, []));
    })
    .map((orgas) => {
      return _.find(orgas, (orga) => {
        var found = _.find(orga.applications, (app) => {
          return app.id === appId;
        });
        return found;
      });
    })
    .filter((orga) => orga)
    .map('.id');
};

function updateProxys (state, obj) {
  var event = obj.message;
  var e = event.event;
  var data = event.data;
  // We use "" to avoid calling indexOf on an undefined
  var ownerId = data.ownerId || '';

  var s_summary;

  if (['ADDON_CREATION', 'ADDON_DELETION'].includes(e)) {
    s_summary = state.SummaryProxy.updateAddons(ownerId);
  }
  else if (e === 'APPLICATION_CREATION') {
    s_summary = state.SummaryProxy.updateApplications(event.data.ownerId);
  }
  else if (['APPLICATION_EDITION', 'APPLICATION_DELETION', 'DEPLOYMENT_SUCCESS', 'UNDEPLOY_ACTION_END', 'DEPLOYMENT_ACTION_BEGIN', 'DEPLOYMENT_ACTION_END', 'DEPLOYMENT_FAIL', 'APPLICATION_REDEPLOY'].includes(e)) {
    s_summary = getOwnerIdFromAppId(state, data.appId).flatMapLatest((owner) => {
      return state.SummaryProxy.updateApplications(owner);
    });
  }
  else {
    s_summary = Bacon.constant();
  }

  var s_instances;
  var s_deployments;

  if (['DEPLOYMENT_FAIL', 'DEPLOYMENT_SUCCESS', 'DEPLOYMENT_ACTION_BEGIN', 'DEPLOYMENT_ACTION_CANCEL', 'UNDEPLOY_ACTION_END', 'APPLICATION_REDEPLOY'].includes(e)) {
    var s_owner;
    var appId;
    if (['DEPLOYMENT_ACTION_BEGIN', 'DEPLOYMENT_ACTION_CANCEL', 'UNDEPLOY_ACTION_END'].indexOf(e) > -1) {
      s_owner = getOwnerIdFromAppId(state, data.appId);
      appId = data.appId;
    }
    else {
      s_owner = Bacon.constant(ownerId);
      appId = data.id;
    }

    s_instances = s_owner.flatMapLatest((owner) => {
      if (e === 'DEPLOYMENT_ACTION_BEGIN') {
        // more frequent refreshes for one minute
        const interv = setInterval(() => state.InstanceProxy.update(owner), 5000);
        setTimeout(() => clearInterval(interv), 60000);
      }
      return state.InstanceProxy.update(owner);
    });

    s_deployments = s_owner.flatMapLatest((owner) => {
      return state.DeploymentProxy.update(owner, appId);
    });

    s_owner.onValue((ownerId) => {
      if (!ownerId || (ownerId.indexOf('orga_') !== 0 && ownerId.indexOf('user_') !== 0)) {
        const error = new Error('Got ownerId ' + ownerId + ' which is not an orga or a user');
        console.error(error);
      }
    });
  }
  else {
    s_instances = Bacon.constant();
    s_deployments = Bacon.constant();
  }

  return Bacon.combineAsArray([s_instances, s_deployments, s_summary]);
};

// Different streams getters
function getEventsStream () {
  return Bacon.fromBinder((sink) => {
    const eventsStream = new EventsStream({ apiHost: API_HOST, tokens: getTokens() });
    eventsStream.on('event', sink);
    eventsStream.on('error', (error) => sink(new Bacon.Error(error)));
    eventsStream.open({ autoRetry: true });
    return () => eventsStream.close();
  });
};

function getMemberInOrga (state, orgaId, memberId) {
  return state.API.organisations._.members.get().withParams([orgaId]).send().map((users) => {
    return _.find(users, (user) => {
      return user.member.id === memberId;
    });
  }).map((user) => {
    return user.member;
  });
};

function getOrgaAndAuthor (state, data) {
  var message = data.message;
  var authorId = message.authorId.toLowerCase();
  var s_ownerId = message.data.ownerId ?
    Bacon.once(message.data.ownerId) :
    getOwnerIdFromAppId(state, message.data.appId);

  var s_author;
  var s_orga;

  return s_ownerId.flatMapLatest((ownerId) => {
    var isOwnerUser = ownerId.indexOf('user_') === 0;

    if (authorId === 'infra') {
      s_orga = isOwnerUser ?
        Bacon.once({ name: T('console.event-notifications.personal-space') }) :
        state.SummaryProxy.fetchOrgaOnce(ownerId);

      s_author = Bacon.once({
        id: null,
        name: 'Clever Cloud support',
      });
    }
    else if (!isOwnerUser) {
      s_orga = state.SummaryProxy.fetchOrgaOnce(ownerId);
      s_author = getMemberInOrga(state, ownerId, authorId);
    }
    else {
      s_orga = Bacon.once({ name: T('console.event-notifications.personal-space') });
      s_author = Bacon.once(data.user);
    }

    return Bacon.combineTemplate({
      orga: s_orga,
      author: s_author,
    });
  });
};

// Create body of notification
function getSettings (state, data) {
  return getOrgaAndAuthor(state, data)
    .flatMapLatest((authorInfo) => {
      return state.EVENTS[data.message.event].body(state, _.extend(data, {
        author: authorInfo.author,
        orga: authorInfo.orga,
      }));
    })
    .map((body) => {
      return _.extend(data, {
        body: body,
        clickUrl: state.EVENTS[data.message.event].url ? state.EVENTS[data.message.event].url(data) : null,
      });
    });
};

// Some events don't have a nice description
// E.g. DEPLOYMENT_ACTION_BEGIN is the only event triggered when an
// application has undeployed (with cause: UNDEPLOY). No _ACTION_END
// So we rename them here to have a better logic

function makeEvents (state, data) {
  var message = data.message;
  if (message.event === 'DEPLOYMENT_ACTION_BEGIN' && message.data.action === 'UNDEPLOY') {
    data.message.event = 'UNDEPLOY_ACTION_END';
  }
  else if (message.event === 'DEPLOYMENT_ACTION_BEGIN' && message.data.action === 'CANCEL') {
    data.message.event = 'DEPLOYMENT_ACTION_CANCEL';
  }

  return data;
};

// Filter Events

function filterEvents (state, data) {
  var message = data.message;
  var user = data.user;
  var emitter = message.authorId;
  var ownerId = message.data.ownerId;

  var event = message.event || message.message_type;

  if (!emitter || !state.EVENTS[event]) {
    return false;
  }
  else if (emitter === user.id && state.EVENTS[event].notifyEmitter) {
    return true;
  }
  else if (emitter !== user.id && ownerId) {
    return true;
  }
  else {
    return false;
  }
};

// All bodys of different notifications

function addonBody (state, data) {
  var message = data.message;
  var s_addon = state.API.organisations._.addons._.get().withParams([message.data.ownerId, message.data.id]).send();

  return s_addon
    .mapError(() => {
      return { id: message.data.id };
    })
    .map((addon) => {
      return state.Templates['ADDON']({
        author: data.author,
        addon: addon,
        orga: data.orga,
        action: state.EVENTS[message.event].text,
      }, state.T);
    });
};

function applicationInfoBody (state, data) {
  var message = data.message;
  var s_app = state.API.organisations._.applications._.get().withParams([message.data.ownerId, message.data.appId]).send();

  return s_app
    .mapError(() => {
      return { id: message.data.appId };
    })
    .map((app) => {
      return state.Templates['APPLICATION_INFO']({
        author: data.author,
        app: app,
        orga: data.orga,
        action: state.EVENTS[message.event].text,
      }, state.T);
    });
};

function applicationActionBody (state, data) {
  var message = data.message;
  var s_app;

  // If the application has been redeployed for maintenance,
  // we have to get its app
  if (message.authorId === 'infra' && message.data.ownerId.indexOf('user_') === 0) {
    s_app = state.API.self.applications._.get().withParams([message.data.appId]).send();
  }
  else {
    s_app = state.API.organisations._.applications._.get().withParams([message.data.ownerId, message.data.appId]).send();
  }

  return s_app
    .mapError(() => {
      return { appId: message.data.appId };
    })
    .map((app) => {
      return state.Templates['APPLICATION_ACTION']({
        author: data.author,
        app: app,
        orga: data.orga,
        action: state.EVENTS[message.event].text,
        cause: message.data.cause,
      }, state.T);
    });
};

function creditsAddedBody (state, data) {
  var s_dropValue = state.API.products.prices.get().send();
  return s_dropValue.map((dropsValue) => {
    return _.find(dropsValue, (dropValue) => {
      return dropValue.currency === 'EUR';
    });
  }).map((currency) => {
    return state.Templates['CREDITS_ADDED']({
      author: data.author,
      orga: data.orga,
      amount: parseInt(data.message.data.amount) * parseInt(currency.value),
    }, state.T);
  });
};

function deploymentStatusBody (state, data) {
  var message = data.message;
  var app = {
    appId: message.data.id,
    name: message.data.name,
  };

  return state.Templates['DEPLOYMENT_STATUS']({
    author: data.author,
    orga: data.orga,
    app: app,
    action: state.EVENTS[message.event].text,
  }, state.T);
};

function deploymentActionBody (state, data) {
  var message = data.message;
  var s_app = data.orga.id ?
    state.API.organisations._.applications._.get().withParams([data.orga.id, message.data.appId]).send() :
    state.API.self.applications._.get().withParams([message.data.appId]).send();

  return s_app.map((app) => {
    return state.Templates['DEPLOYMENT_STATUS']({
      author: data.author,
      orga: data.orga,
      app: app,
      action: state.EVENTS[message.event].text,
    }, state.T);
  });
};

function applicationUndeployedBody (state, data) {
  var message = data.message;
  // Orga can be {"name": "personal space"} without ID
  var s_app = data.orga.id ?
    state.API.organisations._.applications._.get().withParams([data.orga.id, message.data.appId]).send() :
    state.API.self.applications._.get().withParams([message.data.appId]).send();

  return s_app.map((app) => {
    return state.Templates['APPLICATION_ACTION']({
      action: 'UNDEPLOY_ACTION_END',
      app: app,
      author: data.author,
      orga: data.orga,
      cause: message.data.cause,
    }, state.T);
  });
};

// All events

const ALL_EVENTS = {
  'ADDON_CREATION': {
    body: addonBody,
    status: 'success',
    text: 'ADDON_CREATION',
    url: ({ message, orga }) => `/organisations/${orga.id}/addons/${message.data.id}`,
  },
  'ADDON_DELETION': {
    body: addonBody,
    status: 'success',
    text: 'ADDON_DELETION',
    url: ({ orga }) => `/organisations/${orga.id}`,
  },
  'APPLICATION_CREATION': {
    body: applicationInfoBody,
    status: 'success',
    text: 'APPLICATION_CREATION',
    url: ({ message, orga }) => `/organisations/${orga.id}/applications/${message.data.appId}`,
  },
  'APPLICATION_DELETION': {
    body: applicationInfoBody,
    status: 'success',
    text: 'APPLICATION_DELETION',
    url: ({ orga }) => `/organisations/${orga.id}`,
  },
  'APPLICATION_EDITION': {
    body: applicationInfoBody,
    status: 'success',
    text: 'APPLICATION_EDITION',
    url: ({ message, orga }) => `/organisations/${orga.id}/applications/${message.data.appId}/information`,
  },
  'APPLICATION_REDEPLOY': {
    body: applicationActionBody,
    status: 'success',
    text: 'APPLICATION_REDEPLOY',
    url: ({ message, orga }) => `/organisations/${orga.id}/applications/${message.data.appId}/logs`,
  },
  // This event is triggered when a STOP request is made
  // But instances might still be there when it arrives
  'APPLICATION_STOP': {
    body: applicationActionBody,
    status: 'success',
    text: 'APPLICATION_STOP',
    url: ({ message, orga }) => `/organisations/${orga.id}/applications/${message.data.appId}`,
  },
  'CREDITS_ADDED': {
    body: creditsAddedBody,
    status: 'success',
    text: 'CREDITS_ADDED',
    url: ({ orga }) => `/organisations/${orga.id}/invoices`,
  },
  'DEPLOYMENT_ACTION_BEGIN': {
    body: deploymentActionBody,
    notifyEmitter: true,
    status: 'success',
    text: 'DEPLOYMENT_ACTION_BEGIN',
    url: ({ message, orga }) => {
      return orga && orga.id ?
        `/organisations/${orga.id}/applications/${message.data.appId}/logs` :
        `/users/me/applications/${message.data.appId}/logs`;
    },
  },
  'DEPLOYMENT_ACTION_CANCEL': {
    body: deploymentActionBody,
    notifyEmitter: true,
    status: 'success',
    text: 'DEPLOYMENT_ACTION_CANCEL',
    url: ({ message, orga }) => {
      return orga && orga.id ?
        `/organisations/${orga.id}/applications/${message.data.id}/deployments` :
        `/users/me/applications/${message.data.appId}/deployments`;
    },
  },
  'DEPLOYMENT_ACTION_END': {
    notifyEmitter: true,
  },
  'DEPLOYMENT_FAIL': {
    body: deploymentStatusBody,
    notifyEmitter: true,
    status: 'fail',
    text: 'DEPLOYMENT_FAIL',
    url: ({ message, orga }) => {
      return orga ?
        `/organisations/${orga.id}/applications/${message.data.id}/logs` :
        `/users/me/applications/${message.data.id}/logs`;
    },
  },
  'DEPLOYMENT_SUCCESS': {
    body: deploymentStatusBody,
    notifyEmitter: true,
    status: 'success',
    text: 'DEPLOYMENT_SUCCESS',
    url: ({ message, orga }) => {
      return orga ?
        `/organisations/${orga.id}/applications/${message.data.id}/logs` :
        `/users/me/applications/${message.data.id}/logs`;
    },
  },
  // crafted event from DEPLOYMENT_ACTION_BEGIN, cause: UNDEPLOY
  'UNDEPLOY_ACTION_END': {
    body: applicationUndeployedBody,
    notifyEmitter: true,
    status: 'success',
    text: 'UNDEPLOY_ACTION_END',
    url: ({ message, orga }) => {
      return orga ?
        `/organisations/${orga.id}/applications/${message.data.appId}` :
        `/self/applications/${message.data.appId}`;
    },
  },
};
