const { getAllInstances } = require('@clevercloud/client/esm/api/v2/organisation.js')
const { sendToApi } = require('../../../send-to-api.js');

// We have an abnormal quantity of calls made to /organisations/:id/instances
// This problem is hard to investigate and solve property
// see https://gitlab.corp.clever-cloud.com/clever-cloud/console3/-/issues/1078
// This quick-and-dirty solution caches the results for 2 seconds
const INSTANCE_CACHE_DURATION = 2000;
const instancesPromisesCache = {};

async function fetchInstancesWithCache (ownerId) {
  if (instancesPromisesCache[ownerId] != null) {
    return instancesPromisesCache[ownerId];
  }
  const id = (ownerId === 'user') ? null : ownerId;
  const promise = getAllInstances({ id }).then(sendToApi);
  instancesPromisesCache[ownerId] = promise;
  setTimeout(() => {
    delete instancesPromisesCache[ownerId];
  }, INSTANCE_CACHE_DURATION);
  return promise;
}

module.exports = function(settings){
  var Bacon = require('baconjs');
  var _ = require('lodash');

  var InstanceProxy = {};

  var switchs = {};
  var bfetchers = {};
  var sfetchers = {};

  // Return the ID of the given organisation, or "user" whether the owner is an orga or not
  var getOwnerKey = function(orgaId) {
    if(orgaId){
      return orgaId.indexOf('user_') === 0 ? "user" : orgaId;
    } else{
      return "user";
    }
  };

  // Get a (AppID -> [Instance]) map via a HTTP call to the API
  var fetchInstances = function(owner) {
    return Bacon.fromPromise(fetchInstancesWithCache(owner)).flatMapError(function(err){
      var errId = parseInt(err.id);
      if(errId === 2001 || errId === 4002){ // Not connected or 404
        return Bacon.once(new Bacon.Error(err));
      } else{
        return Bacon.later(5000).flatMapLatest(function(){
          return fetchInstances(owner);
        });
      }
    });
  };

  InstanceProxy.fetchAllForOwner = function(orgaId) {
    var owner = getOwnerKey(orgaId);
    var id;

    if(!bfetchers[owner]) {
      bfetchers[owner] = new Bacon.Bus();
      sfetchers[owner] = bfetchers[owner].toProperty();

      var s_instances = Bacon.fromBinder(function(sink) {
        switchs[owner] = true;

        var sendError = function(error) {
          sink(error);
        };

        var callback = function(value) {
          sink(value);
          if(switchs[owner]) {
            id = setInterval(function() {
              var s_fetchInstances = fetchInstances(owner).first();
              s_fetchInstances.onValue(sink);
              s_fetchInstances.onError(function(err){
                sendError(err);
                clearInterval(id);
              });
            }, settings.INSTANCE_PROXY_FETCH_INTERVAL);
          }
        };

        var s_instancesInner = fetchInstances(owner);
        s_instancesInner.onValue(callback);
        s_instancesInner.onError(sendError);

        return function() {
          switchs[owner] = false;
          clearInterval(id);
        };
      });

      bfetchers[owner].plug(s_instances);
    }

    return sfetchers[owner];
  };

  InstanceProxy.fetchAllForOwnerOnce = function(orgaId) {
    return InstanceProxy.fetchAllForOwner(orgaId).take(1);
  };

  InstanceProxy.fetchAll = function(appId, orgaId) {
    return InstanceProxy.fetchAllForOwner(orgaId).map(function(instancesByApp) {
      if(_.has(instancesByApp, 'type') && instancesByApp.type === "error"){
        return instancesByApp;
      } else{
        return instancesByApp[appId] || [];
      }
    });
  };

  InstanceProxy.fetchAllOnce = function(appId, orgaId) {
    return InstanceProxy.fetchAll(appId, orgaId).take(1);
  };

  InstanceProxy.update = function(orgaId){
    var owner = getOwnerKey(orgaId);
    var s_update;
    if(bfetchers[owner]){
      s_update = fetchInstances(owner).first();
      bfetchers[owner].plug(s_update);
    } else{
      s_update = InstanceProxy.fetchAllForOwner(owner);
    }

    return s_update;
  };

  return InstanceProxy;
};
