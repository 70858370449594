'use strict';

const _sample = require('lodash/sample.js');

// We need this before translation is loaded (and it was not really translated anyway)
const SENTENCES = [
  `Science isn't about WHY. <br>It's about WHY NOT.`,
  `Warming up internal Large Hadron Collider`,
  `Please wait, <br>the front-end devs are still drafting`,
  `Checking the gravitational constant <br>in your locale`,
  `Brain the size of a planet<br> and all you want me to do <br>is recite a funny loading message...`,
  `Please wait, <br>the satellite moves into position`,
  `Houston, this is Explorer, do you copy?`,
  `Fixing the failure of the <br>parabolic antenna on the ship`,
  `I’m completely operational, <br>and all my circuits are functioning perfectly.`,
  `Please wait, <br>Opening pod-bay doors`,
  `Aligning covariance matrices`,
  `Calculating inverse probability matrices`,
  `Calibrating support bot`,
  `Deciding what message to display next`,
  `Charging Arc Reactor™ `,
  `Initializing massive improbability field`,
  `Flushing wormholes`,
  `Coalescing cloud formations`,
  `Destabilizing configuration inconcistency`,
];

module.exports = function ($element, command) {

  function displayQuotes () {
    $element.html(_sample(SENTENCES)).fadeIn(1200).delay(7000).fadeOut(1200);
  }

  if (command === 'start') {
    $element.data('randomSentenceIntervalId', setInterval(displayQuotes, 10000));
    displayQuotes();
  }
  else if (command === 'stop') {
    clearInterval($element.data('randomSentenceIntervalId'));
  }

  return $element;
};
