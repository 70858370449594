module.exports = (function() {
  var $ = require('jquery');
  var _ = require('lodash');

  var Templates = require('../../../generated/templates.js');
  var T = require('../../models/technical/translation.js');
  var $Github = require('../github/main.js');

  var $UserInformation = function(settings) {
    var state = {
      container: $(settings.selector),
      information: settings.user,
      error: settings.error,
      Console: settings.Console,
      languages: settings.languages
    };

    $UserInformation.displayForm(state);

    return state;
  };

  $UserInformation.displayForm = function(state) {
    state.container.html(Templates["user-information"](_.extend({}, state, {
      configuration: state.Console.configuration,
      currentLang: T.getCurrentLanguage(),
    })));

    var s_submission = state.container.find("button.update").asEventStream("click");
    var s_githubLink = state.container.find("button.github-link").asEventStream("click");
    var s_githubUnlink = state.container.find("button.github-unlink").asEventStream("click");

    state.user = s_submission.flatMapLatest(function() {
      state.container.find("[data-status='error']").removeAttr("data-status").next("span").empty();

      var s_user = API.self.put().send(JSON.stringify({
        name: state.container.find("[name='name']").val().trim(),
        phone: state.container.find("[name='phone']").val(),
        address: state.container.find("[name='address']").val(),
        city: state.container.find("[name='city']").val(),
        zipcode: state.container.find("[name='zipcode']").val(),
        country: state.container.find("[name='country']").val()
      }));

      s_user.onError(function(error) {
        _.each(error.fields, function(message, field) {
          state.container.find("[name='" + field + "']").attr("data-status", "error").next("span").text(message);
        });
      });

      var s_userWithUpdate = s_user
        .skipErrors()
        .flatMapLatest(function(user) {
          return SummaryProxy.updateOrganisation().map(user);
        });

      state.container.find("button.update").loadStream(s_userWithUpdate);

      return s_userWithUpdate;
    });

    state.user.onValue(_.partial($Notification.displaySuccess, {message: T("console.user-informations.INFORMATIONS_UPDATED") }));
    state.user.onError($Notification.displayError);

    var Github = $Github();

    s_githubLink.onValue(function(e){
      var s_link = Github.link();
      $(e.target).loadStream(s_link);
    });
    var s_githubUnlinkResult = s_githubUnlink.flatMapLatest(function(e){
      return Github.unlink($(e.target));
    });

    s_githubUnlinkResult.onError($Notification.displayError);
    s_githubUnlinkResult.onValue(function() {
      state.information.oauthApps = _.reject(state.information.oauthApps, function(app) {
        return app === "github";
      });

      $UserInformation.displayForm(state);
      $('.github-avatar').hide();
    });

    var $language = state.container.find("[name='language']");
    state.s_languages = $language.asEventStream('change')
      .map(() => $language.val().toUpperCase())
      .flatMapLatest((lang) => {
        return API.self.put().send(JSON.stringify({ lang }));
      });

    state.s_languages.onValue((user) => {
      T.setLanguageTranslator(user.lang, true)
    });
  };

  return $UserInformation;
})();
