import { dispatchCustomEvent } from '../helpers/event.js';

export function getApp (ownerId, appId, $main) {
  return Console.SummaryProxy.fetchOrga(ownerId).firstToPromise()
    .then((owner) => {
      const app = owner.applications.find((app) => app.id === appId);
      if (app != null) {
        return app;
      }
      dispatchCustomEvent($main, 'cc-notification', { type: 'error', key: 'APPLICATION_NOT_FOUND' });
      // Throw error so we can trigger "error" to the returned promise
      throw new Error('APPLICATION_NOT_FOUND');
    });
}

// Right now, we trigger env var validation mode to strict if the app is not Java
export function getEnvVarValidationMode (app) {
  const instanceType = app?.instance?.type ?? app.instanceType;
  return (instanceType === 'java')
    ? 'simple'
    : 'strict';
}
