module.exports = (() => {
  // this is to map with the LogsEvent enum from console3-logs
  const LogsEvent = {
    DeploymentCanceled             : 0,
    MessageDeploymentSuccess       : 1,
    MessageDeploymentFailed        : 2,
    MessageDeploymentEnded         : 3,
    MessageDeploymentSuccessAnswer : 4,
    MessageDeploymentFailedAnswer  : 5,
    FetchDeploymentInstances       : 6,
    FetchDeploymentInstancesAnswer : 7,
    DeploymentCanceledAnswer       : 8
  };

  const $ = require("jquery");
  const Bacon = require("baconjs");
  let $Console3Logs;

  const { API_HOST } = require('../../configuration.js');
  const { getTokens } = require('../../send-to-api.js')
  const Templates = require('../../../generated/templates.js');
  const $Modal = require('../modals/main.js');
  const T = require("../../models/technical/translation.js");

  const $Logs = (settings) => {
    const state = {
      $container: settings.$container,
      source: settings.source,
      ownerId: settings.ownerId,
      urlSearchParams: settings.urlSearchParams,
      deploymentEventsSubscription: settings.deploymentEventsSubscription || [],
    };

    return Bacon.fromPromise(import(/* webpackChunkName: "console3-logs" */ '@clevercloud/console3-logs'))
      .flatMapLatest((mod) => {
        $Console3Logs = mod.default;
      })
      .flatMapLatest(() => $Logs.init(state));
  };

  $Logs.init = (state) => {
    const s_initialDeployments = state.source.sourceType === "application" ?
      $Logs.fetchDeployments(state) :
      Bacon.constant(null);

    const s_newDeployment = s_initialDeployments
      .skip(1) // We only want new deployments
      .map(_.first)
      .filter(deployment => deployment)
      .skipDuplicates(_.isEqual)
      .filter(({ state }) => state !== "WIP");

    const s_currentWipDeployment = s_initialDeployments
      .map(deployments => {
        return deployments.find(({ state }) => state === "WIP")
      })
      .filter(deployment => deployment)
      .skipDuplicates(_.isEqual);

    const s_instances = state.source.sourceType === "application" ?
      s_initialDeployments.flatMapLatest(deployments => $Logs.fetchInstances(state, deployments)) :
      Bacon.constant(null);

    const s_deployments = Bacon
      .combineTemplate({
        source: state.source.sourceObject,
        deployments: s_initialDeployments,
        instances: s_instances
      })
      .flatMapLatest(({ source, deployments, instances }) => {
        if(deployments.length === 0 && instances.length > 0 && source.state === "SHOULD_BE_UP") {
          const firstInstance = _.first(instances);
          const deploymentId = firstInstance.deployId;
          // We only have the deployId field in the instances since mid april 2018. Old instances might not have this field.
          // Fetch all deployments and filter the wanted deployment
          if(deploymentId === null) {
            const deployNumber = firstInstance.deployNumber;
            return $Logs.fetchAllDeployments(state).map(deployments => [deployments.find(d => d.id === deployNumber)]);
          } else {
            return $Logs.fetchDeploymentById(state, deploymentId).map(deployment => [deployment]);
          }
        } else {
          return Bacon.constant(deployments);
        }
      })
      .toProperty();

    const logs = new $Console3Logs({
      container: state.$container,
      ownerId: state.ownerId,
      source: state.source,
      apiHost: API_HOST,
      tokens: getTokens(),
      urlSearchParams: state.urlSearchParams,
      s_stop: Console.s_requestUnload.first(),
      s_deployments,
      s_instances,
      modals: $Logs.getModals(),
      T,
    });

    // These 3 lines of Bacon magic don't make much sens but, Bacon ;-)
    const s_logsEvents = Bacon.once(logs.getEvents()).toProperty();
    const s_b_ingoing = s_logsEvents.map(events => events.b_ingoing);
    const s_outgoing = s_logsEvents.flatMapLatest(events => events.s_outgoing);

    // canceled deployment
    const s_cancel = s_outgoing
      .filter(event => event.event === LogsEvent.DeploymentCanceled)
      .flatMapLatest(event => {
        return s_currentWipDeployment.first().flatMapLatest(deployment => {
          return state.source.sourceObject.first().flatMapLatest(({ id }) => {
            return $Logs.deploymentCanceled(event, state.ownerId, id, deployment);
          })
        });
      });

    const s_instancesForDeployment = s_outgoing
      .filter(event => event.event === LogsEvent.FetchDeploymentInstances)
      .flatMapLatest(event => {
        return state.source.sourceObject.first().flatMapLatest(({ id }) => {
          return $Logs.fetchInstancesForDeployment(state.ownerId, id, event.data.deploymentId)
            .map(instances => ({ instances, event }));
        });
      });

    const s_deploymentEvent = s_newDeployment
      .map(deployment => {
        const wantsOK = state.deploymentEventsSubscription.find(e => e === "OK");
        const wantsFail = state.deploymentEventsSubscription.find(e => e === "FAIL");
        if(deployment.state === "OK" && wantsOK) {
          return LogsEvent.MessageDeploymentSuccess;
        } else if(deployment.state === "FAIL" && wantsFail) {
          return LogsEvent.MessageDeploymentFailed;
        } else {
          return LogsEvent.MessageDeploymentEnded;
        }
      })
      .map(event => ({ event, data: null }));


    // See https://gitlab.corp.clever-cloud.com/clever-cloud/console3-logs/issues/18
    s_b_ingoing.onValue(b_ingoing => {
      b_ingoing.plug(s_cancel
        .map(() => ({ event: LogsEvent.DeploymentCanceledAnswer, data: true }))
      );

      b_ingoing.plug(s_cancel
        .flatMapError(() => Bacon.once({ event: LogsEvent.DeploymentCanceledAnswer, data: null }))
      );

      b_ingoing.plug(s_instancesForDeployment
        .map(({ event, instances }) => {
          return { event: LogsEvent.FetchDeploymentInstancesAnswer, data: instances };
        })
      );

      b_ingoing.plug(s_instancesForDeployment
        .flatMapError(({ event, error }) => {
          return Bacon.once({ event: LogsEvent.FetchDeploymentInstancesAnswer, data: null });
        })
      );

      b_ingoing.plug(s_deploymentEvent);
    });

    return s_outgoing;
  };

  $Logs.deploymentCanceled = (event, ownerId, appId, deployment) => {
    if(Console.LoginAs.isLoggedAsAdmin()) {
      const error = new Error("You can't cancel a deployment using the LoginAs feature.");
      // We do this here because the module doesn't handle an error on cancel
      // and doesn't have access to the Notification object.
      $Notification.displayError(error);
      return Bacon.once(new Bacon.Error(error));
    }

    const params = [ownerId, appId, deployment.id];
    const s_cancel = API.organisations._.applications._.deployments._.instances.delete().withParams(params).send();
    $(event.data.element).loadStream(s_cancel);

    return s_cancel;
  };

  $Logs.fetchInstancesForDeployment = (ownerId, appId, deploymentId) => {
    const query = { deploymentId: deploymentId, withDeleted: true };
    return API.organisations._.applications._.instances.get().withParams([ownerId, appId]).withQuery(query).send();
  };

  $Logs.fetchDeployments = (state) => {
    return state.source.sourceObject.first().flatMapLatest(source => {
      const scalability = $Logs.getApplicationScalability(source);
      return DeploymentProxy
        .fetch(state.ownerId, source.id)
        .map(deployments => $Logs.addDeploymentsEndDate(deployments, source))
        .map(deployments => {
          return deployments.filter(({ action, endDate }) => {
            return action === "DEPLOY" || (action === "DOWNSCALE" && scalability === "SCALABILITY_VERTICAL") || action === "UPSCALE";
          });
        });
    })
    .flatMapLatest(deployments => $Logs.getRetentionDays().map(retention => ({ retention, deployments })))
    .map(({ deployments, retention }) => $Logs.getWantedDeployments(state, deployments, retention))
    .takeUntil(Console.s_requestUnload.first())
    .toProperty();
  };

  $Logs.fetchDeploymentById = (state, deploymentId) => {
    return state.source.sourceObject.first().flatMapLatest(({ id }) => {
      return API.organisations._.applications._.deployments._.get().withParams([state.ownerId, id, deploymentId]).send();
    });
  };

  $Logs.fetchAllDeployments = (state) => {
    return state.source.sourceObject.first().flatMapLatest(({ id }) => {
      return API.organisations._.applications._.deployments.get().withParams([state.ownerId, id]).send()
        .map(deployments => _.chain(deployments).sortBy("id").reverse().value());
    });
  };

  $Logs.fetchInstances = (state, deployments) => {
    return state.source.sourceObject.first().flatMapLatest(({ id }) => {
      return (deployments.length > 0 ?
        $Logs.fetchInstancesForDeployment(state.ownerId, id, _.first(deployments).uuid) :
        API.organisations._.applications._.instances.get().withParams([state.ownerId, id]).send()
      ).takeUntil(Console.s_requestUnload.first());
    });
  };

  $Logs.getModals = () => {
    const modalPublishGist = (title, text) => {
      const modal = $Modal({
        type: 'confirmation',
        title: title,
        body: text,
        submitButtonText: T("console.logs.header.utils.share.modal.button"),
        Templates: Templates
      });

      const s_confirm = modal.s_confirm.map(true);
      const load = (s_load) => {
        $Modal.loadStream(modal, s_load);
        s_load.onValue(() => $Modal.remove(modal));
      };

      return {
        s_done: s_confirm,
        load: load
      };
    };

    const modalGistPublished = (title, text) => {
      const modal = $Modal({
        type: 'information',
        title: title,
        body: text,
        submitButtonText: "Ok",
        Templates: Templates
      });

      modal.s_confirm.onValue(() => $Modal.remove(modal));

      return {
        s_done: modal.s_confirm,
      };
    };

    return {
      'gist-publish': modalPublishGist,
      'gist-published': modalGistPublished
    };
  };

  $Logs.getWantedDeployments = (state, deployments, retention_days) => {
    if(deployments.length === 0) {
      return [];
    }

    const retention = Date.now() - (3600 * 24 * retention_days * 1000);
    const filteredDeployments = deployments.filter(({ endDate }) => endDate ? endDate >= retention : true);

    if(filteredDeployments.length === 0) {
      if(state.source.sourceObject.state === "SHOULD_BE_UP") {
        const deployment = _.find(deployments, ({ state }) => state === "OK");
        if(deployment) {
          return [deployment];
        }
      }
    }

    return filteredDeployments;
  }

  $Logs.getRetentionDays = () => {
    return Bacon.fromPromise(fetch(`${Console.configuration.API_HOST}/v2/logs/retention`))
      .flatMapLatest(body => Bacon.fromPromise(body.json()))
      .map(".durationInDays")
      .mapError(7); // If logs API is unavailable, default to 7 days of logs
  };

  $Logs.getApplicationScalability = (source) => {
    const sameMinMaxInstances = source.instance.minInstances === source.instance.maxInstances;
    const sameMinMaxFlavor = _.isEqual(source.instance.minFlavor, source.instance.maxFlavor);

    if(sameMinMaxInstances && sameMinMaxFlavor) {
      return "SCALABILITY_FIXED";
    } else if(sameMinMaxInstances && !sameMinMaxFlavor) {
      return "SCALABILITY_VERTICAL";
    } else if(!sameMinMaxInstances && sameMinMaxFlavor) {
      return "SCALABILITY_HORIZONTAL";
    } else {
      return "SCALABILITY_BOTH";
    }
  };

  $Logs.getEndLoggingDeployment = (deployment, deployments, source) => {
    // if current deployment was a running deployment, we need to find the next deployment that have shutdown the instances
    // of this deployment
    const scalability = $Logs.getApplicationScalability(source);
    if(deployment.action === "DEPLOY" && deployment.state === "OK") {
      return deployments.find(({ action, state }) => {
        if(action === "DEPLOY" && state === "OK" || action === "UNDEPLOY") {
          return true;
        } else if(action === "DOWNSCALE" && scalability === "SCALABILITY_VERTICAL" && state === "OK") {
          // if our application scales verticaly, then instances will be killed and new ones will be started
          // which means that this downscale deployment is the end of the previous deployment
          return true;
        }
      });
    } else {
      return deployments.length > 0 ?
        deployments[0] :
        null;
    }
  };

  $Logs.addDeploymentsEndDate = (deployments, source) => {
    const reversedDeployments = _.cloneDeep(deployments).reverse();
    const finalDeployments = reversedDeployments.map((deployment, index) => {
      const remainingDeployments = _.slice(reversedDeployments, index + 1);
      const endLoggingDeployment = $Logs.getEndLoggingDeployment(deployment, remainingDeployments, source);

      if(!endLoggingDeployment) {
        return deployment;
      } else {
        return _.extend({}, deployment, {
          endDate: endLoggingDeployment.date
        });
      }
    });

    return _.reverse(finalDeployments);
  };

  return $Logs;
})();
