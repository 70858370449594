import generatedConfiguration from './configuration.js';
import COUNTRIES from './lib/countries.js';
import { initializeConsole } from './Console.js';

window.addEventListener('load', function () {

  const Console = initializeConsole({
    ...generatedConfiguration,
    COUNTRIES,
    DEPLOYMENT_PROXY_FETCH_INTERVAL: 120000,
    INSTANCE_PROXY_FETCH_INTERVAL: 120000,
    MEMBER_ROLES: ['ADMIN', 'ACCOUNTING', 'DEVELOPER', 'MANAGER'],
    METRICS_TOKEN_REFRESH: 120000,
  });

  Console.start();

  // This should be useless once every this cleanup is effective on clients' browsers
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
      .then((registrations) => Promise.all(registrations.map((r) => r.unregister())))
      .then((r) => r.length > 0 && console.log('All service workers are unregistered'));
  }
});
