module.exports = (function() {
  var Bacon = require('baconjs');
  var $ = require('jquery');
  var _ = require('lodash');

  var NumberFormat = require('../helpers/numberformat.js');
  var T = require("../models/technical/translation.js");
  var $Dropdown = require('@clevercloud/console3-dropdown');
  const { sendToApi } = require('../send-to-api.js');
  const { getAllZones } = require('@clevercloud/client/esm/api/v4/product.js');
  const { getZoneWithText } = require('../clever-client/various.js')
  require('@clevercloud/components/dist/cc-zone.js');

  var sp = new (require("./AbstractSP.js"))({
    name: "AppServicesDependenciesSP",
    title: "SERVICES_DEPENDENCIES"
  });

  var orgaId;
  var appId;

  var b_appAddons = new Bacon.Bus();
  var s_appAddons = b_appAddons.toProperty([]);

  s_appAddons.onValue(function(){
    /* lazyness */
  });

  var b_appDependencies = new Bacon.Bus();
  var s_appDependencies = b_appDependencies.toProperty([]);

  s_appDependencies.onValue(function(){
    /* lazyness */
  });

  sp.getStreams = function(req){
    orgaId = req.params.oid;
    appId = req.params.appId;

    var s_price = API.products.prices.get().send().map(function(prices) {
      return _.find(prices, function(price) {
        return price.currency === "EUR";
      });
    });

    var s_app = orgaId ?
      API.organisations._.applications._.get().withParams([orgaId, appId]).send() :
      API.self.applications._.get().withParams([appId]).send();

    var s_zones = Bacon.fromPromise(getAllZones().then(sendToApi).then((zones) => zones.map((z) => getZoneWithText(z))));

    var s_addons = (orgaId ?
      API.organisations._.addons.get().withParams([orgaId]).send() :
      API.self.addons.get().send()
    ).map(function(addons){
      return _.chain(addons)
        .map(function(addon){
          if(!addon.name){
            addon.name = addon.id;
          }

          return addon;
        })
        .sortBy(function(addon){
          return addon.name.toLowerCase();
        })
        .value();
    });

    var s_appAddons = orgaId ?
      API.organisations._.applications._.addons.get().withParams([orgaId, appId]).send() :
      API.self.applications._.addons.get().withParams([appId]).send();

    var s_dependencies = (orgaId ?
      API.organisations._.applications._.dependencies.get().withParams([orgaId, appId]).send() :
      API.self.applications._.dependencies.get().withParams([appId]).send()
    ).map(function(dependencies){
      return _.sortBy(dependencies, function(dependency){
        return dependency.name;
      });
    });

    var s_apps = SummaryProxy
      .fetchOrgaOnce(orgaId)
      .map(".applications")
      .map(function(apps){
        return _.sortBy(apps, function(app){
          return app.name;
        });
      });

    var s_instances = InstanceProxy.fetchAllForOwnerOnce(orgaId);

    return {
      s_price: s_price,
      s_app: s_app,
      s_zones: s_zones,
      s_addons: s_addons,
      s_appAddons: s_appAddons,
      s_dependencies: s_dependencies,
      s_apps: s_apps,
      s_instances: s_instances
    };
  };

  sp.on('onload', function(req, $container, streams) {
    Bacon.onValues(streams.s_app, streams.s_zones, _.partial(sp.displayApp, $container));

    b_appAddons.plug(streams.s_appAddons);
    Bacon.onValues(streams.s_price, streams.s_addons, b_appAddons, streams.s_zones, _.partial(sp.displayAddons, $container));

    b_appDependencies.plug(streams.s_dependencies);
    Bacon.onValues(b_appDependencies, streams.s_apps, streams.s_instances, _.partial(sp.displayAppDependencies, $container));
  });

  sp.displayApp = function($container, app, zones) {
    $container
      .find(".app")
      .attr("data-status", "info")
      .html(Templates["AppServicesDependenciesSP.app"]({
        app: app,
        zone: zones.find((zone) => zone.name === app.zone),
      }));
  };

  sp.displayAddons = function($container, price, addons, appAddons, zones) {
    appAddons = _.groupBy(appAddons, "id");

    var linkedAddons = _.filter(addons, function(addon) {
      return _.has(appAddons, addon.id);
    });

    $container.find(".addons").html(Templates["AppServicesDependenciesSP.addons"]({
      orgaId: orgaId,
      price: price,
      addons: linkedAddons,
      NumberFormat: NumberFormat,
      zones: zones.map((zone) => ({ type: 'loaded', ...zone })),
    }));

    var $dropdown = sp.displayAddonsDropdown($container, addons, linkedAddons);
    $container.find(".addons-services button.link").css("display","inline-block");

    sp.listenForAddonsEvents($container, addons, $dropdown);
  };

  sp.displayAddonsDropdown = function($container, addons, linkedAddons){
    var unlinkedAddons = _.filter(addons, function(addon){
      return !_.find(linkedAddons, function(lAddon){
        return lAddon.id === addon.id;
      });
    });

    var addonText = T("ADDONS");
    var mapAddons = _.reduce(unlinkedAddons, function(obj, addon){
      if(obj[addonText]){
        obj[addonText] = obj[addonText].concat([addon]);
      } else{
        obj[addonText] = [addon];
      }
      return obj;
    }, {});

    return $Dropdown({
      container: $container.find('.dropdown-pick-addons .dropdown-container').empty(),
      map: mapAddons,
      Templates: Templates,
      defaultText: T("ADDONS"),
      T
    });
  };

  sp.listenForAddonsEvents = function($container, addons, $dropdown){
    var $buttonAdd = $container.find('button.addon-link');
    var s_add = $dropdown
      .s_search
      .sampledBy($buttonAdd.off("click").asEventStream('click'))
      .flatMapLatest(function(addon){
        var body = JSON.stringify(addon.id);
        var s_link = orgaId ?
          API.organisations._.applications._.addons.post().withParams([orgaId, appId]).send(body) :
          API.self.applications._.addons.post().withParams([appId]).send(body);

        $buttonAdd.loadStream(s_link);

        return s_link.map(addon);
      });

    var s_remove = $container
      .find('.unlink-addon')
      .asEventStream('click')
      .map(function(e){
        var $addon = $(e.target);
        return {
          id: $addon.parents("tr[data-addon]").attr("data-addon"),
          $addon: $addon
        };
      })
      .flatMapLatest(function(addon){
        var s_delete = orgaId ?
          API.organisations._.applications._.addons._.delete().withParams([orgaId, appId, addon.id]).send() :
          API.self.applications._.addons._.delete().withParams([appId, addon.id]).send();

        addon.$addon.loadStream(s_delete);

        return s_delete.map(addon);
      });

    s_add.onValue(function(newAddon){
      $Notification.displaySuccess({message: T("console.app-addons.linked-addon")});
      $Dropdown.clearSelected($dropdown);

      var addedAddon = _.find(addons, function(addon){
        return addon.id === newAddon.id;
      });

      var s_newAppAddons = s_appAddons
        .first()
        .map(function(appAddons){
          return appAddons.concat([addedAddon]);
        });

      b_appAddons.plug(s_newAppAddons);
    });
    s_add.onError($Notification.displayError);

    s_remove.onValue(function(addon){
      $Notification.displaySuccess({message: T("console.app-addons.unlinked-addon")});
      var s_newAppAddons = s_appAddons
        .first()
        .map(function(appAddons){
          return _.filter(appAddons, function(appAddon){
            return appAddon.id !== addon.id;
          });
        });

      b_appAddons.plug(s_newAppAddons);
    });
    s_remove.onError($Notification.displayError);

  };

  sp.displayAppDependencies = function($container, dependencies, apps, instances){
    apps = _.filter(apps, function(app){
      return app.id !== appId;
    });

    var dependenciesState = _.map(dependencies, function(dependency){
      var status;

      if(instances[dependency.id]){
        status = 'on';
      } else{
        status = 'off';
      }

      return _.extend({}, dependency, {
        status: status
      });
    });

    var unlinkedApps = _.filter(apps, function(app){
      return !_.find(dependencies, function(dep){
        return dep.id === app.id;
      });
    });

    $container.find(".app-dependencies").html(Templates["AppServicesDependenciesSP.dependencies"]({
      dependencies: dependenciesState,
      orgaId: orgaId
    }));

    var $dropdown = sp.displayDependenciesDropdown($container, unlinkedApps);
    $container.find(".app-dependencies-services button.link").css("display","inline-block");

    sp.listenForDependenciesEvents($container, dependencies, apps, $dropdown);
  };

  sp.displayDependenciesDropdown = function($container, unlinkedApps){
    var applicationText = T("APPLICATIONS");
    var mapDependencies = _.reduce(unlinkedApps, function(obj, app){
      if(obj[applicationText]){
        obj[applicationText] = obj[applicationText].concat([app]);
      } else{
        obj[applicationText] = [app];
      }

      return obj;
    }, {});

    return $Dropdown({
      container: $container.find('.dropdown-pick-dependencies .dropdown-container').empty(),
      map: mapDependencies,
      Templates: Templates,
      defaultText: T("APPLICATIONS"),
      T
    });
  };

  sp.listenForDependenciesEvents = function($container, dependencies, apps, $dropdown){
    var $addButton = $container.find("button.dependency-link");
    var s_add = $dropdown
      .s_search
      .sampledBy($addButton.off("click").asEventStream("click"))
      .flatMapLatest(function(app){
        var realApp = _.find(apps, function(a){
          return a.id === app.id;
        });

        var s_link = orgaId ?
          API.organisations._.applications._.dependencies._.put().withParams([orgaId, appId, app.id]).send() :
          API.self.applications._.dependencies._.put().withParams([appId, app.id]).send();

        $addButton.loadStream(s_link);

        return s_link.map(realApp);
      });

    var s_remove = $container
      .find("button.unlink-dependency")
      .asEventStream('click')
      .map(function(e){
        var $button = $(e.target);
        return {
          id: $button.parents("tr[data-dependency]").attr("data-dependency"),
          $button: $button
        };
      })
      .flatMapLatest(function(dep){
        var s_delDep = orgaId ?
          API.organisations._.applications._.dependencies._.delete().withParams([orgaId, appId, dep.id]).send() :
          API.self.applications._.dependencies._.delete().withParams([appId, dep.id]).send();

        dep.$button.loadStream(s_delDep);
        return s_delDep.map(dep);
      });

    s_add.onValue(function(newApp){
      $Notification.displaySuccess({message: T("console.clever-deps.dependency-added")});
      var s_newDep = s_appDependencies
        .first()
        .map(function(dependencies){
          var newDep = _.find(apps, function(app){
            return app.id === newApp.id;
          });

          return dependencies.concat([newDep]);
        });

      b_appDependencies.plug(s_newDep);
    });
    s_add.onError($Notification.displayError);

    s_remove.onValue(function(dep){
      $Notification.displaySuccess({message: T("console.clever-deps.dependency-deleted")});

      var s_updatedDependencies = s_appDependencies
        .first()
        .map(function(dependencies){
          return _.filter(dependencies, function(dependency){
            return dependency.id !== dep.id;
          });
        });

      b_appDependencies.plug(s_updatedDependencies);
    });
    s_remove.onError($Notification.displayError);
  };

  return sp;

})();
