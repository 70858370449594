const Bacon = require('baconjs');
const _ = require('lodash');
const $ = require('jquery');
const { formatCurrency } = require('@clevercloud/components/dist/lib/i18n-number.js');
const { sanitize } = require('@clevercloud/components/dist/lib/i18n-sanitize.js');

  module.exports = (function(){

  let $AddonPlans = function(settings){
    let state = {
      $container: settings.$container,
      provider: settings.provider,
      buttonText: settings.buttonText,
      addon: settings.addon,
      selectedPlan: settings.selectedPlan,
      zones: settings.zones,

      Templates: settings.Templates
    };

    return $AddonPlans.displayPlans(state);
  };

  $AddonPlans.displayPlans = function(state){

    /* Sort plans by price */
    var plans = _.chain(state.provider.plans)
      .sortBy('price')
      .map((plan) => {

        const features = _.sortBy(plan.features, 'name');
        const featuresWithHtml = features.map((feature) => {

          // Each add-on has plans, each plan has features, and each feature has a name and a value
          // We want to allow HTML in plan feature values but keep it safe in the current lodash template system
          // We use the sanitizer from the components, but it was designed to produce a DOMFragment
          // This is why we have to create a temporary div
          // We're not supposed to use this `sanitize` API outside our compoments
          // It's a quick-win while we wait for a full rework of this add-on creation
          // TODO: this is supposed to be temporary (lol)
          const fragment = sanitize(feature.value);
          const container = document.createElement('div');
          container.appendChild(fragment);

          return {
            ...feature,
            value: container.innerHTML,
          }
        })

        // Add the price as a feature for the templating
        if (shouldDisplayPriceColumn(state.provider)) {
          featuresWithHtml.push({
            name: T('addon.pricing-table.price-30-days'),
            value: formatCurrency(T.getCurrentLanguage(), plan.price),
            rightAligned: true,
          });
        }

        return {
          ...plan,
          features: featuresWithHtml,
        }
      })
      .value();

    let $plans = $(state.Templates["AddonPlans.plans"]({
      provider: _.extend({}, state.provider, {
        plans: plans,
      }),
      zones: state.zones,
      selectedPlan: state.selectedPlan || null,
      buttonText: state.buttonText
    }));

    state.$container.html($plans);

    let s_trClick = $plans.find('tr[data-plan]').asEventStream('click');
    let s_radioClick = $plans.find('input[type="radio"][data-plan]').asEventStream('click').doAction('.stopPropagation');
    let s_clicks = Bacon.mergeAll(s_trClick, s_radioClick).map(e => {
      return $(e.currentTarget).attr('data-plan');
    });

    let s_defaultPlan = state.selectedPlan ?
      Bacon.constant(state.selectedPlan) :
      Bacon.never();

    let s_planId = Bacon.mergeAll(s_clicks, s_defaultPlan).toProperty();

    s_planId.onValue(planId => {
      state.$container.find(`input[type="radio"][data-plan="${planId}"]`).prop('checked', true);
    });

    let $next = $plans.find('cc-button.next');

    let s_defaultElem = state.provider.plans.length === 1 ? Bacon.constant(plans[0]) : Bacon.never();

    let s_next = $next.asEventStream('cc-button:click');

    let s_plansSelect = s_planId.map(planId => {
      return _.find(state.provider.plans, plan => plan.id === planId);
    });

    let s_planSelected = Bacon.mergeAll(s_defaultElem, s_plansSelect).toProperty();

    s_planSelected.onValue(() => $next.removeAttr('disabled'));

    return s_planSelected.sampledBy(s_next).map(plan => {
      const zone = state.$container.find('select.zones').val();
      return { plan, zone };
    });
  };

  return $AddonPlans;
})();

// Add-ons with consumption based pricing are a bit special so we don't display a simple "price" column
function shouldDisplayPriceColumn (provider) {

  const isConsumptionBasedOrFree = [
    'addon-matomo',
    'addon-pulsar',
    'cellar-addon',
    'config-provider',
    'fs-bucket',
    'keycloak',
    'kv',
  ].includes(provider.id);

  return !isConsumptionBasedOrFree;
}
