module.exports = (function() {
  var _ = require('lodash');
  var $ = require('jquery');
  var Bacon = require('baconjs');
  var Yajas = require('yajas');
  var path4js = require('path4js');

  var $Modal = require('../../src/modules/modals/main.js');
  var Role = require('../models/business/role.js');
  var Templates = require('../../generated/templates.js');
  var T = require('../models/technical/translation.js');
  var $Avatar = require('../modules/avatar/view.js');
  var $OrgaInformations = require('../modules/orga-informations/main.es6.js');

  var sp = new (require("./AbstractSP.js"))({
    name: 'OrgaSP'
  });

  var orgaId;

  sp.getStreams = function(req){
    orgaId = req.params.ownerId;

    var s_orga = API.organisations._.get().withParams([orgaId]).send();
    var s_me = SummaryProxy.fetchUserOnce().flatMapLatest(function(user){
      return API.organisations._.members.get().withParams([orgaId]).send().map(function(members){
        return _.find(members, member => member.member.id === user.id);
      });
    });

    var s_orgaInfo = Bacon.combineWith(function(orga, me){
      return _.extend({}, orga, {
        role: me.role
      });
    }, s_orga, s_me);

    return {
      s_orga: s_orga,
      s_me: s_me,
      s_orgaInfo: s_orgaInfo
    };
  };

  sp.on('onload', function(req, $container, streams) {
    streams.s_orgaInfo.onValue(_.partial(sp.displayOrganisation, $container, req));
  });

  sp.displayOrganisation = function($container, req, orga) {
    var requiredFields = ["company"];
    var orgaRequiredFields = _.map(requiredFields, function(field){
      return orga[field] && orga[field] !== "";
    });
    var readonly = !Role.canEditOrga(orga.role);
    var _orga = _.extend({
      isCompany: _.every(orgaRequiredFields)
    }, orga);

    var vatDataStatus, vatDataHelper;
    if(_orga.vatState == "INVALID"){
      vatDataStatus = "error";
      vatDataHelper = T("INVALID_VAT_NUMBER");
    } else if(orga.vatState == "PENDING_VALIDATION"){
      vatDataStatus = "warning";
      vatDataHelper = T("VAT_IN_PENDING");
    } else {
      vatDataHelper = vatDataStatus = '';
    }

    var vatData = {
      status: vatDataStatus,
      helper: vatDataHelper
    };

    $container.find('.orga-sp-container').html(Templates['OrgaSP.information']({
      countries: Console.configuration.COUNTRIES,
      orga: _orga,
      readonly: readonly,
      Role: Role,
      vatData: vatData,
      isPersonalOrga: orgaId.startsWith('user_'),
    }));

    var $form = $container.find('form');

    // don't add the listeners if orga is readonly
    // also, don't show the Avatar's setup
    if(readonly){
      return;
    }

    $form.find('[name="orga-is-company"]').click(_.partial(sp.onOrgaIsCompany, $form, $container, _orga, readonly, vatData));
    $form.find('[name="orga-no-vat"]').click(_.partial($OrgaInformations.onOrgaNoVAT, $form));
    $form.find('[name="orga-vat"]').focusout(_.partial($OrgaInformations.onVATFocusOut, $form));
    $form.submit(_.partial(sp.onOrgaSubmit, $form, req));

    var $remove = $form.find('button.remove');
    $remove.click(_.partial(sp.removeOrga, _orga.name, $remove));

    var avatar = $Avatar({
      container: $container.find(".avatar-container"),
      owner: _orga
    });

    avatar.s_state.flatMapLatest(function(){
      return SummaryProxy.updateOrganisation(orgaId);
    }).onValue(function(){
      $Notification.displaySuccess({
        message: T("ORGA_PIC_UPDATED")
      });
    });
    avatar.s_state.onError($Notification.displayError);
  };

  sp.removeOrga = function(orgaName, $remove) {
    var $modal = $Modal({
      type: 'confirmation',
      textCheck: function(input) { return input === orgaName; },
      title: T("REMOVE_ORGANISATION"),
      body: T("ORGANISATION_TYPE_NAME_DELETE", {orgaName: orgaName}),

      Templates: Templates
    });

    var s_confirm = $modal.s_confirm;
    var s_response = s_confirm.flatMapLatest(function() {
      var s_remove = API.organisations._.delete().withParams([orgaId]).send().flatMapLatest(function(){
        return SummaryProxy.updateOrganisations();
      });
      $Modal.loadStream($modal, s_remove);
      return s_remove;
    });

    s_response.onError($Notification.displayError);
    s_response.onValue(function() {
      Yajas.path4js.launchPath(path4js.Request.fromUri('/users/me'));
    });
  };

  sp.onOrgaIsCompany = function($form, $container, orga, readonly, vatData) {
    var $companyInformation = $form.find('fieldset[data-step="advanced_information"]');
    var isCompany = $(this).val() === "true";

    $container.find('.company-details').html(Templates["OrgaSP.company-details"]({
      countries: Console.configuration.COUNTRIES,
      orga: _.extend({}, orga, {isCompany: isCompany}),
      readonly: readonly,
      Role: Role,
      vatData: vatData
    }));

    if(!isCompany) {
      // don't block the submit button because of the VAT if it's not a company
      $container.find('button[type="submit"]').prop("disabled", false).addClass('btn-blue');
    }

    $form.find('[name="orga-no-vat"]').click(_.partial($OrgaInformations.onOrgaNoVAT, $form));
    $form.find('[name="orga-vat"]').focusout(_.partial($OrgaInformations.onVATFocusOut, $form));
  };

  sp.onOrgaSubmit = function($form, req, e) {
    e.preventDefault();
    var orga = $OrgaInformations.onOrgaSubmit.call(this);

    var res = API.organisations._.put().withParams([orgaId]).send(JSON.stringify(orga)).flatMapLatest(function(response) {
      return SummaryProxy.updateOrganisation(orgaId).map(response);
    });

    $form.find("[type='submit']").loadStream(res, 1000);

    res.onError($Notification.displayError);
    res.onValue(function() {
      if(req.search.redirect){
        Yajas.path4js.launchPath(path4js.Request.fromUri(decodeURIComponent(req.search.redirect)));
      } else{
        Yajas.path4js.launchPath(path4js.Request.fromUri(window.location.pathname));
      }
    });
  };

  return sp;
})();
