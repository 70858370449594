const { getLanguage } = require('@clevercloud/components/dist/i18n.js');
const { prepareNumberBytesFormatter } = require('@clevercloud/components/dist/lib/i18n-number.js');
module.exports = (function() {
  var Bacon = require('baconjs');
  var $ = require('jquery');
  var Templates = require('../../../generated/templates.js');
  var NumberFormat = require('../../helpers/numberformat.js');

  const lang = getLanguage();
  const formatBytes = (lang === 'fr')
    ? prepareNumberBytesFormatter(lang, 'o', '\u202f')
    : prepareNumberBytesFormatter(lang, 'B', ' ');

  var $Pricer = function(settings) {
    var $pricer = {
      $elem: $(settings.selector),
      instance: settings.instance,
      price: settings.price,
      configuration: settings.configuration || {
        autoscalability: false,
        staticConfiguration: {
          minInstances: 1,
          maxInstances: 1,
          minFlavor: settings.instance.defaultFlavor,
          maxFlavor: settings.instance.defaultFlavor
        },
        dynamicConfiguration: {
          minInstances: 1,
          maxInstances: settings.instance.maxInstances < 4 ? settings.instance.maxInstances : 4,
          minFlavor: settings.instance.defaultFlavor,
          maxFlavor: _.last(settings.instance.flavors)
        }
      }
    };

    $Pricer.init($pricer);

    return $pricer;
  };

  $Pricer.init = function($pricer) {
    $pricer.$elem.html(Templates["Pricer"]($pricer.configuration));

    var $autoscalability = $Pricer.displayAutoscalability($pricer);

    var $horizontalStatic = $Pricer.displayHorizontalStatic($pricer);
    var $horizontalDynamic = $Pricer.displayHorizontalDynamic($pricer);

    var $verticalStatic = $Pricer.displayVerticalStatic($pricer);
    var $verticalDynamic = $Pricer.displayVerticalDynamic($pricer);

    var s_staticConfiguration = Bacon.mergeAll($horizontalStatic.configuration, $verticalStatic.configuration).map(function(configuration) {
      return {
        staticConfiguration: configuration
      };
    });

    var s_dynamicConfiguration = Bacon.mergeAll($horizontalDynamic.configuration, $verticalDynamic.configuration).map(function(configuration) {
      return {
        dynamicConfiguration: configuration
      };
    });

    $pricer.configuration = Bacon.mergeAll($autoscalability.configuration, s_staticConfiguration, s_dynamicConfiguration).scan($pricer.configuration, function(oldConf, newConf) {
      return {
        autoscalability: typeof newConf.autoscalability !== "undefined" ? newConf.autoscalability : oldConf.autoscalability,
        staticConfiguration: _.extend({}, oldConf.staticConfiguration, newConf.staticConfiguration),
        dynamicConfiguration: _.extend({}, oldConf.dynamicConfiguration, newConf.dynamicConfiguration)
      };
    });

    /* Show/hide sections whether autoscalability is enabled */
    $autoscalability.configuration.map(".autoscalability").onValue(function(autoscalability) {
      $horizontalStatic[!autoscalability ? "show" : "hide"]();
      $horizontalDynamic[autoscalability ? "show" : "hide"]();

      $verticalStatic[!autoscalability ? "show" : "hide"]();
      $verticalDynamic[autoscalability ? "show" : "hide"]();
    });

    $pricer.configuration.onValue(function(configuration) {
      $Pricer.displayConfigurationPrice($pricer, configuration);
    });
  };

  $Pricer.displayAutoscalability = function($pricer) {
    var $autoscalability = $pricer.$elem.find("section.autoscalability")
      .html(Templates["Pricer.autoscalability"]($pricer.configuration));

    var s_enabled = $autoscalability.find("#autoscalability-enabled").asEventStream("click");
    var s_disabled = $autoscalability.find("#autoscalability-disabled").asEventStream("click");

    var stream = Bacon.mergeAll(s_enabled.map(true), s_disabled.map(false)).skipDuplicates().map(function(autoscalability) {
      return {
        autoscalability: autoscalability
      };
    });

    var s_configuration = stream.toProperty($pricer.configuration);

    return _.extend($autoscalability, {
      configuration: s_configuration
    });
  };

  $Pricer.displayHorizontalStatic = function($pricer) {
    var $horizontalStatic = $pricer.$elem.find("section.horizontal-static")
      .html(Templates["Pricer.horizontal-static"]($pricer.configuration.staticConfiguration));

    var $rangepicker = $horizontalStatic.find(".rangepicker").slider({
      range: false,
      min: 1,
      max: $pricer.instance.maxAllowedInstances,
      values: [$pricer.configuration.staticConfiguration.maxInstances]
    });

    var stream = Bacon.fromBinder(function(sink) {
      $rangepicker.on("slide", function(event, data) {
        sink({
          minInstances: data.values[0],
          maxInstances: data.values[0]
        });
      });
    });

    var s_configuration = stream.toProperty($pricer.configuration.staticConfiguration);
    s_configuration.map(".maxInstances").assign($rangepicker.find(".ui-slider-handle"), "text");

    return _.extend($horizontalStatic, {
      configuration: s_configuration
    });
  };

  $Pricer.displayHorizontalDynamic = function($pricer) {
    var $horizontalDynamic = $pricer.$elem.find("section.horizontal-dynamic")
      .html(Templates["Pricer.horizontal-dynamic"]($pricer.configuration.dynamicConfiguration));

    var $rangepicker = $horizontalDynamic.find(".rangepicker").slider({
      range: true,
      min: 1,
      max: $pricer.instance.maxAllowedInstances,
      values: [$pricer.configuration.dynamicConfiguration.minInstances, $pricer.configuration.dynamicConfiguration.maxInstances]
    });

    var stream = Bacon.fromBinder(function(sink) {
      $rangepicker.on("slide", function(event, data) {
        sink({
          minInstances: data.values[0],
          maxInstances: data.values[1]
        });
      });
    });

    var s_configuration = stream.toProperty($pricer.configuration.dynamicConfiguration);

    s_configuration.map(".minInstances").assign($rangepicker.find(".ui-slider-handle").eq(0), "text");
    s_configuration.map(".maxInstances").assign($rangepicker.find(".ui-slider-handle").eq(1), "text");

    return _.extend($horizontalDynamic, {
      configuration: s_configuration
    });
  };

  $Pricer.displayVerticalStatic = function($pricer) {
    var $verticalStatic = $pricer.$elem.find("section.vertical-static")
      .html(Templates["Pricer.vertical-static"]($pricer.configuration.staticConfiguration));

    var streams = [];
    var $flavorpicker = $verticalStatic.find(".flavorpicker");

    const split = Math.ceil($pricer.instance.flavors.length / 2);
    _.each($pricer.instance.flavors, function(flavor, index) {
      if(index === split) {
        const $split = $('<div>').addClass('flavor-split');
        $flavorpicker.append($split);
      }

      // Use the RAM value from the API and internationalize it
      flavor.formattedMem = formatBytes(flavor.memory.value);

      var $flavor = $(Templates["Pricer.flavor"](_.extend(flavor, {
        selected: $pricer.configuration.staticConfiguration.minFlavor.name === flavor.name,
        disabled: !flavor.available
      })));

      var s_flavor = $flavor.find("input").asEventStream("click").map({
        minFlavor: flavor,
        maxFlavor: flavor
      });

      streams.push(s_flavor);
      $flavorpicker.append($flavor);
    });

    var s_configuration = Bacon.mergeAll(streams).toProperty($pricer.configuration.staticConfiguration);

    return _.extend($verticalStatic, {
      configuration: s_configuration
    });
  };

  $Pricer.displayVerticalDynamic = function($pricer) {
    var $verticalDynamic = $pricer.$elem.find("section.vertical-dynamic")
      .html(Templates["Pricer.vertical-dynamic"]($pricer.configuration.dynamicConfiguration));

    var flavors = $pricer.instance.flavors;

    var indexMin = _.findIndex(flavors, function(flavor) {
      return flavor.name === $pricer.configuration.dynamicConfiguration.minFlavor.name;
    });

    var indexMax = _.findIndex(flavors, function(flavor) {
      return flavor.name === $pricer.configuration.dynamicConfiguration.maxFlavor.name;
    });

    var $rangepicker = $verticalDynamic.find(".rangepicker").slider({
      range: true,
      min: 0,
      max: flavors.length - 1,
      values: [indexMin, indexMax]
    });

    var $flavors = [];
    var $marker = $rangepicker.find(".ui-slider-range");
    _.each(flavors, function(flavor, index) {
      $a = $('<a>').text(flavor.name.replace(/ML_/i, '')).attr('href', '#').addClass('ui-slider-fake-handle').css('left', (Math.round(10000 * 100 * index / (flavors.length - 1)) / 10000) + '%');

      $a.insertAfter($marker);
      $flavors.push($a);
    });

    var stream = Bacon.fromBinder(function(sink) {
      $rangepicker.on("slide", function(event, data) {
        sink({
          minFlavor: flavors[data.values[0]],
          maxFlavor: flavors[data.values[1]]
        });
      });
    });

    var s_configuration = stream.toProperty($pricer.configuration.dynamicConfiguration);

    s_configuration.onValue(function(configuration) {
      var indexMin = _.findIndex(flavors, function(flavor) {
        return flavor.name === configuration.minFlavor.name;
      });

      var indexMax = _.findIndex(flavors, function(flavor) {
        return flavor.name === configuration.maxFlavor.name;
      });

      _.each($flavors, function($flavor, index) {
        var active = index >= indexMin && index <= indexMax;

        if(index === indexMin || index === indexMax) {
          $flavor.hide();
        }
        else if(index > indexMin && index < indexMax) {
          $flavor.show().addClass("ui-slider-fake-handle-enabled");
        }
        else {
          $flavor.show().removeClass("ui-slider-fake-handle-enabled");
        }
      });

      return [indexMin, indexMax];
    });

    s_configuration.map(conf => conf.minFlavor.name.replace(/ML_/i, '')).assign($rangepicker.find(".ui-slider-handle").eq(0), "text");
    s_configuration.map(conf => conf.maxFlavor.name.replace(/ML_/i, '')).assign($rangepicker.find(".ui-slider-handle").eq(1), "text");

    return _.extend($verticalDynamic, {
      configuration: s_configuration
    });
  };

  $Pricer.displayConfigurationPrice = function($pricer, configuration) {
    $pricer.$elem.find(".pricer-price").html(Templates["Pricer.price"](_.extend(configuration, {
      price: $pricer.price,
      NumberFormat: NumberFormat
    })));
  };

  return $Pricer;
})();
