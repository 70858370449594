module.exports = (function() {
  var Bacon = require('baconjs');
  var _ = require('lodash');
  var Yajas = require("yajas");
  var path4js = require("path4js");

  var $Modal = require('../../src/modules/modals/main.js');
  var Templates = require('../../generated/templates.js');
  var T = require('../models/technical/translation.js');
  var $AddonAppAccess = require('../modules/addon-app-access/main.js');
  const { getAllZones } = require('@clevercloud/client/esm/api/v4/product.js');
  const { sendToApi } = require('../send-to-api.js');
  require('@clevercloud/components/dist/cc-env-var-form.js');
  require('@clevercloud/components/dist/cc-zone.js');

  var sp = new (require("./AbstractSP.js"))({
    name: 'AddonSP',
    title: "ADDON_OVERVIEW"
  });

  var orgaId;
  var addonId;

  sp.getStreams = function(req){
    orgaId = req.params.oid;
    addonId = req.params.addonId;

    var s_addon = sp.getAddon(addonId, orgaId).toProperty();

    var s_apps = orgaId ?
      API.organisations._.addons._.applications.get().withParams([orgaId, addonId]).send() :
      API.self.addons._.applications.get().withParams([addonId]).send();

    var s_zones = Bacon.fromPromise(getAllZones().then(sendToApi));

    return {
      s_addon: s_addon,
      s_apps: s_apps,
      s_zones,
    };
  };

  sp.on('onload', function(req, $container, streams) {
    Bacon.onValues(streams.s_addon, streams.s_apps, streams.s_zones, _.partial(sp.displayAddon, $container));
  });

  sp.getAddon = function(addonId, orgaId) {
    var s_addon = orgaId ?
      API.organisations._.addons._.get().withParams([orgaId, addonId]).send() :
      API.self.addons._.get().withParams([addonId]).send();

    var s_envs = (orgaId ?
      API.organisations._.addons._.env.get().withParams([orgaId, addonId]).send() :
      API.self.addons._.env.get().withParams([addonId]).send()
    ).map(function(envs){
      return _.sortBy(envs, function(env){
        return env.name;
      });
    });

    var s_tags = sp.getAddonTags(addonId, orgaId).toProperty();

    return Bacon.combineWith(function(addon, tags, envs) {
      return _.extend(addon, {tags: tags, envs: envs});
    }, s_addon, s_tags, s_envs).toProperty();
  };

  sp.getAddonTags = function(addonId, orgaId) {
    return orgaId ?
      API.organisations._.addons._.tags.get().withParams([orgaId, addonId]).send() :
      API.self.addons._.tags.get().withParams([addonId]).send();
  };

  sp.displayAddon = function($container, addon, apps, zones) {
    var codeExemples = $AddonAppAccess({
      apps: _.uniqBy(apps, function(app){ return app.instance.type; }),
      addon: addon,

      Templates: Templates
    });

    var $addon = $container.find('.addon').html(Templates["AddonSP.addon"](_.extend(addon, {
      apps: apps,
      orgaId: orgaId,
      href: (orgaId ? '/organisations/' + orgaId : '/users/me') + '/addons/' + addon.id,
      addon: addon,
      zoneState: { type: 'loaded', ...zones.find((z) => z.name === addon.region) },
      codeExemples: codeExemples
    })));

    $addon.find('cc-env-var-form').prop('state', { type: 'loaded', variables: addon.envs });

    var s_removeTag = $addon
      .find(".addon-tags.tags")
      .asEventStream('click', 'li .tags-close')
      .map(function(e) {
        return encodeURIComponent(e.target.getAttribute('data-tag-val').trim());
      })
      .flatMapLatest(function(val){
        return orgaId ?
          API.organisations._.addons._.tags._.delete().withParams([orgaId, addon.id, val]).send() :
          API.self.addons._.tags._.delete().withParams([addon.id, val]).send();
      });

    s_removeTag.onValue(function(tags){
      $addon.find('.tags-list').html(Templates["AddonSP.addon-tags"]({tags: tags}));
    });

    s_removeTag.onError($Notification.displayError);

    $addon.find('button.remove').click(function() {
      var addonName = addon.name || T("DELETE").toUpperCase();
      var modalBody;

      // fs-bucket of PHP FTP apps
      if(addon.id.indexOf("app_") === 0){
        var link = orgaId ?
          '/organisations/' + orgaId + '/applications/' + addon.id + '/information' :
          '/users/me/applications/' + addon.id + '/information';

        modalBody = Templates["modal.addon-deletion-ftp-app"]({
          link: link
        });
      } else if(apps.length > 0){
        modalBody = Templates["modal.addon-deletion-apps-linked"]({apps: apps, addonName: addonName, orgaId: orgaId});
      } else {
        modalBody = T("ADDON_TYPE_NAME_DELETE", {addonName: addonName});
      }

      if(addon.id.indexOf("app_") !== 0 && addon.provider.id === "fs-bucket"){
        modalBody += " " + T("console.addonsp.delete-fs-bucket-git-warning");
      }

      var isFsBucket = addon.id.indexOf("app_") === 0;
      var $modal = $Modal({
        type: isFsBucket ? 'information' : 'confirmation',
        textCheck: isFsBucket ? null : function(input){ return input === addonName; },
        title: T("REMOVE_ADDON"),
        body: modalBody,

        Templates: Templates
      });

      var s_response = $modal.s_confirm.flatMapLatest(function() {
        if(isFsBucket){
          $Modal.remove($modal);
          return Bacon.never();
        }
        var s_removeAddon = orgaId ?
          API.organisations._.addons._.delete().withParams([orgaId, addonId]).send() :
          API.self.addons._.delete().withParams([addonId]).send();

        var s_update = s_removeAddon.flatMapLatest(function(response) {
          return SummaryProxy.updateAddons(orgaId).map(function() {
            return response;
          });
        });

        $Modal.loadStream($modal, s_update);
        return s_update;
      });

      s_response.onError($Notification.displayError);
      s_response.onValue(function() {
        Yajas.path4js.launchPath(path4js.Request.fromUri(orgaId ? "/organisations/" + orgaId : "/users/me"));
      });
    });

    var $addonTags = $addon.find('.addon-tags.tags');
    var $tagsList = $addonTags.find('.tags-list');
    var $tagInput = $addonTags.find('input[name="add-addon-tag"]');

    var s_createAddons = $tagInput
      .asEventStream('keypress')
      .filter(function(e){
        return e.keyCode === 13;
      })
      .map(function(e){
        return encodeURIComponent(e.target.value.trim());
      })
      .flatMapLatest(sp.createAddonTag);

    s_createAddons.onValue(function(tags){
      $tagInput.val('');
      $tagsList.html(Templates["AddonSP.addon-tags"]({tags: tags}));
    });

    s_createAddons.onError($Notification.displayError);

    var s_updateName = $addon
      .find('.addon-update-name')
      .asEventStream('click')
      .flatMapLatest(function(e){
        var $modal = $Modal({
          type: 'user-input',
          title: T('console.addonsp.addon-modal-update-name-title'),
          body: Templates["modal.input-text"]({value: addon.name, required: true}),
          Templates: Templates,
        });

        return $modal
          .s_confirm
          .map(function(e){
            return e.target["input_text"].value.trim();
          })
          .flatMapLatest(function(name){
            var body = JSON.stringify({
              name: name
            });

            var s_update = (orgaId ?
              API.organisations._.addons._.put().withParams([orgaId, addonId]).send(body) :
              API.self.addons._.put().withParams([addonId]).send(body)
            ).flatMapLatest(function(){
              return SummaryProxy.updateAddons(orgaId);
            });

            $Modal.loadStream($modal, s_update);

            return s_update;
          });
      });

    s_updateName.onValue(function(){
      Yajas.path4js.launchPath(path4js.Request.fromUri((orgaId ? "/organisations/" + orgaId : "/users/me") + "/addons/" + addonId + "/informations"));
    });
    s_updateName.onError($Notification.displayError);
  };

  sp.createAddonTag = function(value){
    return orgaId ?
      API.organisations._.addons._.tags._.put().withParams([orgaId, addonId, value]).send() :
      API.self.addons._.tags._.put().withParams([addonId, value]).send();
  };

  return sp;
})();
