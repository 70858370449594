module.exports = (() => {
  let validUrl = require('valid-url');
  let $ = require('jquery');
  let _ = require('lodash');
  let Bacon = require('baconjs');

  let $OauthConsumerCreation = (settings) => {
    let state = {
      $container: settings.$container,
      orgaId: settings.orgaId,

      API: settings.API,
      Templates: settings.Templates,
      SummaryProxy: settings.SummaryProxy,

      oauthConsumer: {}
    };

    return $OauthConsumerCreation.init(state);
  };

  $OauthConsumerCreation.init = (state) => {
    return $OauthConsumerCreation.fetchOauthRights(state)
      .map(rights => $OauthConsumerCreation.parseOauthRights(state, rights))
      .flatMapLatest(state => $OauthConsumerCreation.display("consumer-info", state))
      .flatMapLatest(state => $OauthConsumerCreation.display("consumer-rights", state))
      .flatMapLatest(state => $OauthConsumerCreation.createConsumer(state));
  };

  $OauthConsumerCreation.fetchOauthRights = (state) => {
    return state.API.oauth.rights.get().send();
  };

  $OauthConsumerCreation.parseOauthRights = (state, rights) => {
    let parsedRights = _.reduce(rights, (obj, val, key) => {
      if(key.indexOf('manage') === 0 || key.indexOf('change') === 0){
        obj.manage[key] = val;
      } else{
        obj.access[key] = val;
      }

      return obj;
    }, {manage: {}, access: {}});

    return _.extend({}, state, {
      rights: parsedRights
    });
  };

  $OauthConsumerCreation.display = (step, state) => {
    state.$container.find('form').hide();
    state = _.extend({}, state, {
      $step: state.$container.find('form[data-step=' + step + ']').show()
    });

    return $OauthConsumerCreation.display[step](state);
  };

  $OauthConsumerCreation.display["consumer-info"] = (state) => {
    state.$step.html(state.Templates["OauthConsumerCreation.consumer-info"]({consumer: null}));

    return state.$step
      .asEventStream('submit')
      .doAction('.preventDefault')
      .flatMapLatest(e => {
        let target = e.target;

        let baseUrl = target["application-oauth-callback"];
        let url = target["application-homepage"];
        let picture = target["application-image"];
        let inputs = [baseUrl, url, picture];

        _.each(inputs, input => {
          $(input).removeAttr("data-status").next("span").empty();
        });

        let errors = _.filter(inputs, input => !validUrl.isUri(input.value.trim()));

        if(errors.length > 0){
          _.each(errors, input => $(input).attr("data-status", "error").next('span').text(T("console.oauth-consumers.valid-url")));
          return Bacon.never();
        } else{
          return Bacon.once(_.extend({}, state, {
            oauthConsumer: {
              name        : target["application-name"].value,
              baseUrl     : baseUrl.value.trim(),
              description : target["application-description"].value,
              url         : url.value.trim(),
              picture     : picture.value.trim()
            }
          }));
        }
      });
  };

  $OauthConsumerCreation.display["consumer-rights"] = (state) => {
    state.$step.html(state.Templates["OauthConsumerCreation.consumer-rights"]({rights: state.rights, consumer: null}));

    return state.$step
      .asEventStream('submit')
      .doAction('.preventDefault')
      .map(e => {
        let rights = _.reduce($(e.target).find('input[type="checkbox"]'), (rights, elem) => {
          rights[elem.getAttribute('name')] = elem.checked;
          return rights;
        }, {});

        state.oauthConsumer.rights = rights;
        return state;
      });
  };

  $OauthConsumerCreation.createConsumer = (state) => {
    let body = JSON.stringify(state.oauthConsumer);

    let s_create = (state.orgaId ?
      state.API.organisations._.consumers.post().withParams([state.orgaId]).send(body) :
      state.API.self.consumers.post().send(body)
    ).flatMapLatest(consumer => state.SummaryProxy.updateConsumers(state.orgaId).map(consumer));

    state.$step.find('[type="submit"]').loadStream(s_create);

    return s_create;
  };

  return $OauthConsumerCreation;
})();
