module.exports = (() => {
  const onVATFocusOut = ($form, e) => {
    const $vat = $(e.target);
    const value = $vat.val().replace(/\s*/g, "");
    // remove this class to keep the button readable
    const $submitButton = $form.find('button[type="submit"]').removeClass('btn-blue');
    const $select = $form.find('select[name="orga-country"]');

    // check if the field is filled. As only EU companies must edit it, we don't have to block other companies
    if(value.length > 0){

      // disable save button until we check validity of the VAT number
      $submitButton.prop('disabled', true);

      const country = value.slice(0, 2).toUpperCase();
      const query = {
        country: country,
        vat: value.slice(2)
      };

      $select.val(country);

      // Check if the country has been found in the select
      if($select.val() === country){
        $select.attr('disabled', 'disabled');
      } else {
        $select.removeAttr('disabled');
      }

      API.vat_check.get().withQuery(query).send().onValue(result => {
        if(result.valid) {
          $vat.removeAttr("data-status");
          $vat.next(".input-helper").empty();
          $submitButton.prop('disabled', false).addClass('btn-blue');
        }
        else {
          $vat.attr("data-status", "error");
          $vat.next(".input-helper").text(T("INVALID_TVA_NUMBER"));
        }
      });
    } else{
      $submitButton.prop('disabled', false).addClass('btn-blue');
      $vat.removeAttr("data-status");
      $vat.next(".input-helper").empty();
      $select.removeAttr('disabled');
    }
  };

  const onOrgaNoVAT = ($form, e) => {
    const $elem = $(e.target);
    const $vatInput = $form.find('input[name="orga-vat"]');
    const $formField = $vatInput.parents('.form-field');

    if($elem.is(':checked')) {
      $vatInput.removeAttr('data-status').val("").attr('disabled', 'disabled');

      $vatInput.next('.input-helper').empty();
      $form.find('select[name="orga-country"]').removeAttr('disabled');
      $form.find('button[type="submit"]').removeAttr('disabled');
    } else {
      $vatInput.removeAttr('disabled');
    }
  };

  const onOrgaSubmit = function(){
    let orga = {
      name: this["orga-name"].value.trim(),
      description: this["orga-description"].value || this["orga-name"].value,
      billingEmail: this["orga-billing-email"].value,
      address: this["orga-address"].value,
      city: this["orga-city"].value,
      zipcode: this["orga-zipcode"].value,
      country: this["orga-country"].value,
      customerFullName: this["orga-customer-full-name"].value
    };

    if($(this).find('[name="orga-is-company"]:checked').val() === "true"){
      var vat = this["orga-vat"].value.replace(/\s*/g, '');
      orga = _.extend({}, orga, {
        company: this["orga-company"].value,
        VAT: vat || null,
        country: vat.length > 0 ? null : orga.country
      });
    } else{
      orga = _.extend({
        company: null,
        VAT: null,
      }, orga);
    }

    return orga;
  };

  const getInformationsHTML = (orga = {}, readonly = false, vatData = {}) => {
    return Templates["OrgaSP.informations"]({
      orga: orga,
      readonly: readonly,
      vatData: vatData
    });
  };

  return {
    onVATFocusOut,
    onOrgaNoVAT,
    onOrgaSubmit: onOrgaSubmit
  };
})();


