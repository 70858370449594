var moment = require('moment');

module.exports = (function(){
  var $UniformisedDateTime = {};
  $UniformisedDateTime.getWithMilliseconds = function(date){
    return moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
  };

  $UniformisedDateTime.getWithSeconds = function(date){
    return moment(date).format('YYYY-MM-DD[T]HH:mm:ssZ');
  };

  $UniformisedDateTime.getWithMinutes = function(date){
    return moment(date).format('YYYY-MM-DD[T]HH:mmZ');
  };

  $UniformisedDateTime.getHumanReadableWithSeconds = function(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };

  return $UniformisedDateTime;
})();
