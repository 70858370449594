'use strict';

const Bacon = require('baconjs');
const _ = require('lodash');

module.exports = function (settings) {
  const DeploymentProxy = {};

  const s_deployments = {};
  const b_deployments = {};

  DeploymentProxy.fetch = function (owner, appId) {
    return DeploymentProxy.fetchDeploymentsForOwner(owner).map((deployments) => {
      return _.get(deployments, appId);
    });
  };

  DeploymentProxy.fetchFirst = function (orgaId, appId) {
    return DeploymentProxy
      .fetch(orgaId, appId)
      .map(_.head);
  };

  DeploymentProxy.fetchFirstOnce = function (orgaId, appId) {
    return DeploymentProxy
      .fetchFirst(orgaId, appId)
      .first();
  };

  DeploymentProxy.fetchOnce = function (orgaId, appId) {
    return DeploymentProxy.fetch(appId, orgaId).first();
  };

  DeploymentProxy.update = function (owner) {

    let s_update;

    if (b_deployments[owner] == null) {
      s_update = DeploymentProxy.fetchDeploymentsForOwner(owner);
    } else {
      s_update = fetchDeploymentsForOwner(owner);
      b_deployments[owner].plug(s_update);
      s_deployments[owner].first().onValue(() => {
        // This stream might have no subscribers
        // but we still want the property to be updated
        // for future subscribers
      });
    }

    return s_update;
  };

  function fetchDeploymentsForOwner (owner) {
    const limit = { limit: 10 };
    return settings.API.organisations._.deployments.get().withParams([owner]).withQuery(limit).send().flatMapError((err) => {
      if (err.id === 2001 || err.id === 4002) { // Not connected or 404
        return Bacon.once(new Bacon.Error(err));
      } else {
        return Bacon.later(5000).flatMapLatest(() => fetchDeploymentsForOwner(owner));
      }
    })
    .map(appsDeployments => {
      return _.mapValues(appsDeployments, (deployments) => {
        return _.chain(deployments).sortBy("id").reverse().value();
      });
    });
  }

  DeploymentProxy.fetchDeploymentsForOwner = function (owner) {
    if (b_deployments[owner] == null) {
      b_deployments[owner] = new Bacon.Bus();
      s_deployments[owner] = b_deployments[owner].toProperty();

      const s_fetch = Bacon
        .repeat(() => {
          return Bacon.later(settings.DEPLOYMENT_PROXY_FETCH_INTERVAL).flatMapLatest(() => {
            return fetchDeploymentsForOwner(owner);
          });
        })
        .merge(fetchDeploymentsForOwner(owner).toEventStream());

      s_fetch.first().onValue(() => {
        // Sometimes, the stream would be stopped (takeUntil) before the XHR request is finished
        // But we still want to keep the value we fetched
        // We had a listener so it will be plugged in the bus and be available for further use
      });

      b_deployments[owner].plug(s_fetch);
    }

    return s_deployments[owner];
  };

  return DeploymentProxy;
};
