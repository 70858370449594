module.exports = (function() {
  var $ = require('jquery');
  var Bacon = require('baconjs');
  var _ = require('lodash');

  var Templates = require('../../generated/templates.js');

  AbstractSP = function(settings, tplParams) {
    this.initialize(settings);
    this.tplParams = tplParams;
  };

  var p = AbstractSP.prototype = new (require('path4js').StatePoint)();
  p.initialise_sp = p.initialize;

  p.initialize = function(settings) {
    this.initialise_sp();
    this.name = settings.name;
    this.title = settings.title;
  };

  p.launch = function(req) {
    // I'm not sure about why we had this, so I moved the param on ownerId so special cases can use it
    if (req.params.oid) {
      // If you need both orga IDs and user IDs => use `ownerId`
      req.params.ownerId = req.params.oid;
      if (req.params.oid.indexOf('user_') === 0) {
        delete req.params.oid;
      }
    }

    var timestamp = Date.now();

    var $main = $("main");
    var $container = $(Templates["container"]({
      name: this.name,
      timestamp: timestamp
    }));

    $main.html($container.html(Templates[this.name](_.extend({}, req, this.tplParams))));

    this.selector = $container;

    if(this.getStreams && typeof this.getStreams === "function"){
      this.fireEvent('onload', req, $container, this.loadStreams(req, $container));
    } else{
      this.fireEvent('onload', req, $container, {});
    }
  };

  p.loadStreams = function(req, $container){
    var streams = this.getStreams(req, $container);

    var s_unload = Bacon.fromEvent(this, 'onunload').first();
    var streamsEnd = _.mapValues(streams, function(stream){
      return stream.takeUntil(s_unload).toProperty();
    });
    var streamsFirst = _.map(streamsEnd, function(stream){
      return stream.first();
    });
    var streamsNoErrors = _.mapValues(streamsEnd, function(stream){
      return stream.skipErrors();
    });

    var s_allStreams = Bacon.mergeAll(_.map(streamsFirst, function(stream, name){
      return stream;
    }));

    s_allStreams
      .errors()
      // skipDuplicates won't work on errors since it needs to be a property
      .mapError(function(err){ return err; })
      .skipDuplicates(_.isEqual)
      .onValue($Notification.displayError);

    return streamsNoErrors;
  };

  p.support = {
    verb: ['*'],
    headers: {
      'Accept': ['text/html']
    }
  };

  return AbstractSP;
})();
