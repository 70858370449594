module.exports = (function() {
  var Bacon = require('baconjs');
  var _ = require('lodash');
  var $ = require('jquery');

  var $AppCreation = require('../modules/app-creation/view.js');
  var $AddonCreation = require('../modules/addon-creation/view.js');
  var $AppDeployment = require('../modules/app-deployment/main.js')(Console);
  var Templates = require('../../generated/templates.js');
  var Role = require("../models/business/role.js");
  const { getAllZones } = require('@clevercloud/client/esm/api/v4/product.js')
  const { getAllAddonProviders } = require('@clevercloud/client/esm/api/v2/product.js');
  const { sendToApi } = require('../send-to-api');

  var sp = new (require("./AbstractSP.js"))({
    name: 'AppCreationSP'
  });

  sp.getStreams = function(req){
    var orgaId = req.params.oid;

    var s_hasAddonCreateRights = !orgaId ?
      Bacon.constant(true) :
      API.organisations._.members.get().withParams([orgaId]).send()
        .flatMapLatest(function(members){
          return SummaryProxy.fetchUserOnce()
            .map(function(user) {
              var member = _.find(members, function(member){
                return member.member.id === user.id;
              });

              return member;
            });
        })
        .map(function(member){
          return Role.canAddAddon(member.role);
        });

    var s_githubApps = API.self.get().send().map(function(user){
      var githubLinked = _.find(user.oauthApps, function(app){
        return app.toLowerCase() === "github";
      });
      if(githubLinked){
        return API.github.applications.get().send().flatMapError(function(error){
          if(parseInt(error.id) === 1602){
            return Bacon.once(null);
          } else{
            return Bacon.once(new Bacon.Error(error));
          }
        });
      } else{
        return null;
      }
    });

    var s_productsInstances = API.products.instances.get().withParams([orgaId]).send();

    var s_euro = API.products.prices.get().send().map(function(prices) {
      return _.find(prices, function(price) {
        return price.currency === "EUR";
      });
    });

    var s_zones = Bacon.fromPromise(getAllZones().then(sendToApi));

    const s_owner = SummaryProxy.fetchOrgaOnce(orgaId);

    var s_providers = s_owner
      .flatMapLatest(owner => {
        return Bacon.fromPromise(getAllAddonProviders({ orgaId: owner.id }).then(sendToApi));
      });

    var s_userId = SummaryProxy.fetchUserOnce().map('.id');

    return {
      s_githubApps: s_githubApps,
      s_productsInstances: s_productsInstances,
      s_euro: s_euro,
      s_zones: s_zones,
      s_providers: s_providers,
      s_userId: s_userId,
      s_hasAddonCreateRights: s_hasAddonCreateRights,
    };
  };

  sp.on('onload', async function(req, $container, streams) {
    await import(/* webpackChunkName: "jquery-ui.lazy" */ 'jquery-ui');
    var orgaId = req.params.oid;
    Bacon.onValues(
      streams.s_githubApps,
      streams.s_productsInstances,
      streams.s_euro,
      streams.s_zones,
      streams.s_providers,
      streams.s_userId,
      streams.s_hasAddonCreateRights,
      _.partial(sp.displayCreationForm, $container, orgaId));
  });

  sp.displayCreationForm = function($container, orgaId, githubApps, instances, euro, zones, providers, userId, hasAddonCreateRights) {
    var $appCreation = $AppCreation({
      selector: $container.find("form.app-creation"),
      orgaId: orgaId,
      githubApps: githubApps,
      instances: instances,
      price: euro,
      zones: zones,

      SummaryProxy: SummaryProxy,
      Console: Console,
    });

    // Update the proxy of the owner
    var s_app = $appCreation.application.toProperty();

    // Then create an add-on (if needed)
    var s_addon = s_app.flatMapLatest(function(app) {
      var $header = $container.find("header");
      $container.find("form.app-creation").hide();
      // hide in two steps because later, we want the steps hided but not its container
      $header.find(".header-content.app-creation").hide().find(".header-steps").hide();

      // If the user doesn't have right to create an add-on, we don't show him the process
      // we still setup the HTML above
      if(!hasAddonCreateRights){
        return Bacon.once();
      }

      $header.append(Templates["AddonCreationSP.header"]());
      $container.find("form.addon-creation").show();

      var $addonCreation = $AddonCreation({
        selector: $container,
        providers: providers,
        applications: [],
        app: app,
        orgaId: orgaId,
        userId: userId,
        embeded: true,
        zones,
        SummaryProxy: SummaryProxy,
        Console: Console,
        hasAddonCreateRights: hasAddonCreateRights,
      });

      var $steps = $header.find(".header-content.addon-creation [data-step]");
      $addonCreation.p_step.onValue(function(step) {
        $steps.removeClass("active").filter("[data-step='" + step + "']").addClass("active");
      });

      $steps.click(function() {
        var step = $(this).attr("data-step");

        $addonCreation.p_step.take(1).onValue(function(currentStep) {
          if($AddonCreation.steps.indexOf(step) < $AddonCreation.steps.indexOf(currentStep)) {
            $AddonCreation.display(step, $addonCreation);
          }
        });
      });

      return $addonCreation.s_addon.flatMapError(function(error) {
        var allowedErrors = ["NO_PROVIDER_SELECTED", "USER_DOESNT_HAVE_CREATION_RIGHTS"];
        return allowedErrors.indexOf(error.code) > -1 ?
          Bacon.once() :
          Bacon.once(new Bacon.Error(error));
      });
    }).toProperty();

    // Wait for the provisioning of an add-on before managing the app deployment, even if it's not necessary
    var s_deployment = s_addon.flatMapLatest(function() {
      $container.find("form.addon-creation").hide();
      $container.find(".app-deployment").show();
      $container.find("header .header-content.addon-creation").hide();
      $container.find("header .header-content.app-creation").show();

      return s_app.flatMapLatest(function(app) {
        var state = $AppDeployment({
          selector: $container.find(".app-deployment"),
          app: app,
          orgaId: orgaId,
          Console: Console
        });

        return state.s_deployment;
      });
    }).toProperty();

    Bacon.onValues(s_app, s_addon, s_deployment, _.partial(sp.displaySuccess, $container, orgaId));

    var $steps = $container.find("header ul.header-steps [data-step]");

    $steps.click(function() {
      $AppCreation.display($(this).attr("data-step"), $appCreation);
    });

    $appCreation.displayStream.onValue(function(step) {
      var $step = $steps.filter("[data-step='" + step + "']");
      if($step.length > 0) {
        $steps.removeClass("active");
        $step.addClass("active");
      }
    });
  };

  sp.displaySuccess = function($container, orgaId, app, addon) {
    $container.find(".app-deployment").hide();
    $container.find(".app-success").html(Templates["AppCreationSP.success"]({
      orgaId: orgaId,
      app: app,
      addon: addon,
    })).show();
  };

  return sp;
})();
