module.exports = (function() {
  var Bacon = require('baconjs');
  var _ = require('lodash');
  var $ = require('jquery');
  var Role = require("../models/business/role.js");
  const { getAllZones } = require('@clevercloud/client/esm/api/v4/product.js');
  const { sendToApi } = require('../send-to-api.js');
  const { getAllAddonProvidersFiltered } = require('../clever-client/various.js');
  require('@clevercloud/components/dist/cc-zone-input.js')

  var $AddonCreation = require('../modules/addon-creation/view.js');

  var sp = new (require("./AbstractSP.js"))({
    name: 'AddonCreationSP'
  });

  var orgaId;

  sp.getStreams = function(req){
    orgaId = req.params.oid;

    const s_owner = SummaryProxy.fetchOrgaOnce(orgaId);

    var s_apps = s_owner.map(function(owner) {
      return owner.applications;
    });

    const s_zones = Bacon.fromPromise(getAllZones().then(sendToApi));

    var s_providers = s_owner
      .flatMapLatest(owner => {
        return Bacon.fromPromise(getAllAddonProvidersFiltered(owner.id));
      });

    var s_hasCreateRights = !orgaId ?
      Bacon.constant(true) :
      API.organisations._.members.get().withParams([orgaId]).send()
        .flatMapLatest(function(members){
          return SummaryProxy.fetchUserOnce()
            .map(function(user) {
              var member = _.find(members, function(member){
                return member.member.id === user.id;
              });

              return member;
            });
        })
        .map(function(member){
          return Role.canAddAddon(member.role);
        });

    var s_userId = SummaryProxy.fetchUserOnce().map(".id");

    return {
      s_apps: s_apps,
      s_providers: s_providers,
      s_userId: s_userId,
      s_hasCreateRights: s_hasCreateRights,
      s_zones,
    };
  };

  sp.on('onload', function(req, $container, streams) {
    Bacon.onValues(
      streams.s_providers,
      streams.s_apps,
      streams.s_userId,
      streams.s_hasCreateRights,
      streams.s_zones,
      _.partial(sp.displayAddonCreation, $container));
  });

  sp.displayAddonCreation = function($container, providers, applications, userId, hasCreateRights, zones) {
    if(!hasCreateRights){
      sp.userMissesCreateRights($container);
      return;
    }

    var $addonCreation = $AddonCreation({
      selector: $container,
      providers: providers,
      applications: applications,
      app: null,
      orgaId: orgaId,
      userId: userId,
      zones,

      SummaryProxy: SummaryProxy,
      Console: Console
    });

    var $steps = $container.find(".header-steps [data-step]");
    $steps.click(function() {
      var step = $(this).attr("data-step");

      $addonCreation.p_step.take(1).onValue(function(currentStep) {
        if($AddonCreation.steps.indexOf(step) < $AddonCreation.steps.indexOf(currentStep)) {
          $AddonCreation.display(step, $addonCreation);
        }
      });
    });

    var s_addon = $addonCreation.s_addon.toProperty();

    s_addon.onValue(function(addon) {
      var Yajas = require('yajas');
      var path4js = require('path4js');
      Yajas.path4js.launchPath(path4js.Request.fromUri((orgaId ? '/organisations/' + orgaId : '/users/me') + '/addons/' + addon.id));
    });

    $addonCreation.p_step.onValue(function(step) {
      $steps.removeClass("active").filter("[data-step='" + step + "']").addClass("active");
    });
  };

  sp.userMissesCreateRights = function($container){
    $container.append(Templates["AddonCreationSP.user-misses-create-rights"]);
  };

  return sp;
})();
