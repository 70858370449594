'use strict';

const $ = require('jquery');
const templates = require('../../generated/templates.js');

// this function compiles a template/data to HTML and then,
// it replaces the old HTML with the new one
// the closure is there to remember the last inserted DOM element
function applyTemplate (originalInsertPointSelector, templateName) {
  const compileTemplate = templates[templateName];
  let $lastInsertPoint = $(originalInsertPointSelector);
  return (data) => {
    let html = compileTemplate(data);
    // if template is empty, use a hidden placeholder DOM node
    if (html.trim() === '') {
      html = `<div hidden><!-- placeholder for ${templateName} --></div>`;
    }
    const $newInsertPoint = $(html);
    $lastInsertPoint.replaceWith($newInsertPoint);
    $lastInsertPoint = $newInsertPoint;
    return $newInsertPoint;
  };
}

module.exports = applyTemplate;
