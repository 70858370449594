'use strict';

const _ = require('lodash');
const Bacon = require('baconjs');
const { getStatus: getAppStatus } = require('@clevercloud/client/esm/utils/app-status.js')
const getAddonStatus = require('../../helpers/addonStatus').getAddonStatus;

function getOwnerDetails (s_owner) {
  return s_owner.map((owner) => ({
    ownerHref: owner.href,
    cleverEnterprise: owner.cleverEnterprise,
    isUser: owner.id.startsWith('user'),
    ownerName: owner.name,
    selected: (owner.selection === owner.href),
    ticketCenterSelected: (owner.selection === `${owner.href}/ticket-center`),
  }));
}

function getAllApps (s_owner, Console) {

  const s_deployments = s_owner.flatMapLatest((owner) => {
    return Console.DeploymentProxy.fetchDeploymentsForOwner(owner.id);
  });

  const s_instances = s_owner.flatMapLatest((owner) => {
    return Console.InstanceProxy.fetchAllForOwner(owner.id);
  });

  return Bacon.combineAsArray(s_owner, s_deployments, s_instances)
    .map(([owner, deploymentsByApp, instancesByApp]) => {
      return _.map(owner.applications, ({ id, state, homogeneous, variantLogoUrl, variantSlug, instance, instanceVariant, instanceType, name, archived }) => {
        const linkUrl = `${owner.href}/applications/${id}`;
        const selected = (linkUrl === owner.selection);
        const status = getAppStatus({ state, homogeneous }, deploymentsByApp[id], instancesByApp[id]);
        const logoUrl = _.get(instance, 'variant.logo', variantLogoUrl);
        const logoLabel = _.get(instance, 'variant.name', instanceVariant);
        const slug = _.get(instance, 'variant.slug', variantSlug);
        const type = _.get(instance, 'variant.deployType', instanceType);
        const matchers = _.uniq(['is:app', `is:${slug}`, `is:${type}`]);
        return { id, selected, linkUrl, logoUrl, logoLabel, name, status, archived, matchers };
      });
    })
    .scan([], (oldApps, newApps) => {
      return _.map(newApps, (app) => {
        if (app.status !== 'unknown') {
          return app;
        }
        const oldApp = _.find(oldApps, { id: app.id });
        const oldStatus = _.get(oldApp, 'status', 'unknown');
        return _.assign({}, app, { status: oldStatus });
      });
    })
    .skip(1);
}

function getAddons (s_owner) {
  const s_instances = s_owner.flatMapLatest((owner) => {
    return Console.InstanceProxy.fetchAllForOwnerOnce(owner.id);
  });

  const s_fullAddons = s_owner.flatMapLatest((owner) => {
    return API.organisations._.addons.get().withParams([owner.id]).send();
  });

  return Bacon.combineAsArray(s_owner, s_instances, s_fullAddons)
    .map(([owner, instancesByAddon, addons]) => {
      return _.map(addons, (addon) => {
        const providerId = addon.provider.id;
        const logoUrl = addon.provider.logoUrl;
        const addonSlug = providerId.replace(/-addon$/, '');
        const matchers = ['is:addon', `is:${addonSlug}`];
        const linkUrl = `${owner.href}/addons/${addon.id}`;
        const instances = _.get(instancesByAddon, addon.realId);
        const selected = (linkUrl === owner.selection)
          || addon.realId === owner.currentAddonId
          || addon.id === owner.currentAddonId;
        return {
          selected,
          linkUrl,
          logoUrl,
          logoLabel: providerId,
          providerId,
          name: addon.name || addon.id,
          status: getAddonStatus(addon, instances),
          matchers,
        };
      });
  });
}

function getOauthConsumers (s_owner) {
  return s_owner.map((owner) => {
    return _.map(owner.consumers, ({ lastUrl, key, picture, name }) => {
      const linkUrl = lastUrl || `${owner.href}/oauth-consumers/${key}`;
      // this is broken for now
      const selected = (linkUrl === owner.selection);
      const logoUrl = picture;
      const logoLabel = name;
      return { selected, linkUrl, logoUrl, logoLabel, name };
    });
  });
}

function getAddonProviders (s_owner) {
  return s_owner.map((owner) => {
    return _.map(owner.providers, ({ lastUrl, id, logoUrl, name }) => {
      const linkUrl = lastUrl || `${owner.href}/providers/${id}`;
      const selected = (linkUrl === owner.selection);
      const logoLabel = name;
      return { selected, linkUrl, logoUrl, logoLabel, name: name || id };
    });
  });
}

module.exports = {
  getOwnerDetails,
  getAllApps,
  getAddons,
  getOauthConsumers,
  getAddonProviders,
};
