module.exports = (function(){
  var $ = require('jquery');
  var _ = require('lodash');
  var Bacon = require('baconjs');

  var Templates = require('../../../generated/templates.js');
  var T = require('../../models/technical/translation.js');

  var limits = {
    px: {
      max: {
        width: 300,
        height: 300
      },
      min: {
        width: 75,
        height: 75
      }
    },
    size: (500 * 1024 * 1024) // 500kb
  };

  var $Avatar = function(settings) {
    var $avatarSettings = {
      $container: $(settings.container),
      owner: settings.owner,
      isOrga: settings.owner.id.indexOf("orga_") === 0,
      avatarUrl: settings.url || settings.owner.avatar || "",
      githubAvatar: settings.githubAvatar,
      gravatarAvatar: settings.gravatarAvatar,
      limits: limits,
      s_state: null
    };

    $avatarSettings.s_state = $Avatar.display($avatarSettings);

    return $avatarSettings;
  };

  $Avatar.display = function($avatarSettings){
    var type = $avatarSettings.avatarUrl.indexOf("gravatar") >= 0 ? "gravatar" :
               $avatarSettings.avatarUrl.indexOf("githubusercontent") >= 0 ? "github" :
               $avatarSettings.avatarUrl.indexOf("ccapiavatars") >= 0 ? "ccapi" :
               "";

    var $avatar = $(Templates["Avatar"]({
      isOrga: $avatarSettings.isOrga,
      avatarUrl: $avatarSettings.avatarUrl,
      avatarType: type,
      gravatarAvatar: $avatarSettings.gravatarAvatar,
      githubAvatar: $avatarSettings.githubAvatar
    }));

    $avatarSettings.$container.html($avatar);
    $Avatar.updateSelection($avatarSettings, $avatarSettings.owner.avatar || "");

    var s_changeSource = $avatar.find('button[data-source]')
      .asEventStream('click')
      .flatMapLatest(_.partial($Avatar.changeSource, $avatarSettings));

    var s_file = $Avatar.getFileInput($avatarSettings);
    var s_upload = s_file.flatMapLatest(_.partial($Avatar.upload, $avatarSettings));

    var s_update = Bacon.mergeAll(s_upload, s_changeSource);

    s_update.onValue(_.partial($Avatar.updateSelection, $avatarSettings));

    return s_update;
  };

  $Avatar.getFileInput = function($avatarSettings) {
    var $avatar = $avatarSettings.$container;
    var $input = $avatar.find('input[type="file"]');

    $avatar.find('button.upload').asEventStream('click').onValue(function(){
      $avatar.find('input[type="file"]').click();
    });

    return $input.asEventStream("change")
      .flatMapLatest(function(e) {
        var files = $input[0].files;
        return files.length <= 0 ? new Bacon.Error({message: T("NO_AVATAR_PROVIDED")}) : _.head(files);
      })
      .flatMapLatest(_.partial($Avatar.checkImage, $avatarSettings));
  };

  // this is a filter function, but for error handling, we use flatMap
  $Avatar.checkImage = function($avatarSettings, file){
    return $Avatar.createImage(file)
    .flatMapLatest(_.partial($Avatar.checkImagePxSize, $avatarSettings))
    .flatMapLatest(_.partial($Avatar.checkImageSize, $avatarSettings))
    .flatMapLatest(_.partial($Avatar.displayPreview, $avatarSettings))
    .map(function(){
      return file;
    });
  };

  $Avatar.createImage = function(file){
    var img = new Image();
    var reader = new FileReader();
    var s_img = Bacon.fromEventTarget(reader, "load").flatMapLatest(function(){
      var s_load = Bacon.fromEventTarget(img, "load");
      img.src = reader.result;
      return s_load;
    }).map(function(){
      return img;
    });
    reader.readAsDataURL(file);
    return s_img;
  };

  $Avatar.checkImagePxSize = function($avatarSettings, img){
    var pxLimits = limits.px;
    if((img.width > pxLimits.max.width || img.height > pxLimits.max.height) ||
       (img.width < pxLimits.min.width || img.height < pxLimits.min.height)){

      return new Bacon.Error({
        message: T("AVATAR_BAD_PX_SIZE", $avatarSettings)
      });
    } else{
      return Bacon.once(img);
    }
  };

  $Avatar.checkImageSize = function($avatarSettings, img){
    if(img.size > (limits.size)){
      return new Bacon.Error({
        message: T("AVATAR_BAD_SIZE", $avatarSettings)
      });
    } else{
      return Bacon.once(img);
    }
  };

  $Avatar.displayPreview = function($avatarSettings, img){
    var $img = $('<img>').attr('src', img.src).addClass('avatar-img');
    $avatarSettings.$container.find('.custom-avatar .avatar').empty().html($img);
    return Bacon.once(img);
  };

  $Avatar.upload = function($avatarSettings, img){
    var s_response = $Avatar.sendChangeRequest($avatarSettings, img, { "Content-Type": img.type });

    $avatarSettings.$container.find("button.btn.upload").loadStream(s_response);

    return s_response;
  };

  $Avatar.changeSource = function($avatarSettings, e) {
    var body = JSON.stringify({
      source: $(e.target).attr("data-source")
    });
    var s_response = $Avatar.sendChangeRequest($avatarSettings, body, { "Content-Type": "application/json" });

    $(e.target).loadStream(s_response);

    return s_response;
  };

  $Avatar.sendChangeRequest = function($avatarSettings, body, headers) {
    var s_response = $avatarSettings.isOrga ?
      API.organisations._.avatar.put().withParams([$avatarSettings.owner.id]).withHeaders(headers || {}).send(body) :
      API.self.avatar.put().withHeaders(headers || {}).send(body);

    return s_response;
  };

  $Avatar.updateSelection = function($avatarSettings, avatar) {
    var $avatar = $avatarSettings.$container;

    avatar = avatar.url || avatar;

    $avatar.find('.chosen-avatar').removeClass('active');
    $avatar.find('.choose-avatars button').prop('disabled', false);

    if(avatar.indexOf('gravatar') >= 0){
      $avatar.find('.gravatar-avatar .chosen-avatar').addClass('active');
      $avatar.find('.gravatar-avatar button').prop('disabled', true);
    }
    else if(avatar.indexOf('githubusercontent') >= 0){
      $avatar.find('.github-avatar .chosen-avatar').addClass('active');
      $avatar.find('.github-avatar button').prop('disabled', true);
    }
    else if(avatar.indexOf('ccapiavatars') >= 0){
      $avatar.find('.custom-avatar .chosen-avatar').addClass('active');
    }
  };

  return $Avatar;
})();
