window.consoleLoaded = true;

const T = require('./models/technical/translation.js');
const { createEventsNotifications } = require('./modules/events-notifications/main.js');

var Templates = require('./../generated/templates.js');

var moment = require('moment');
var Bacon = require('baconjs');
var _ = require('lodash');

var $DesktopNotification = require('./modules/desktop-notifications/main.js');
var $ShortCuts = require('./modules/shortcuts/main.es6.js');
var $QuickSearch = require('./modules/quick-search/main.es6.js');
var QueryString = require('./helpers/querystring.js');
var Yajas = require('yajas');
var path4js = require('path4js');
var Request = path4js.Request;

var $ = jQuery = require("jquery");
$.fn.asEventStream = Bacon.$.asEventStream;

require('./lib/tipsy.js');
require('./helpers/loader.js')();
require('./etc/routes.js');

// Used in various places to provide a readonly value with "copy to clipboard" button
require('@clevercloud/components/dist/cc-input-text.js');
// Used once to provide a global notification mechanism
require('@clevercloud/components/dist/cc-toaster.js');

module.exports.initializeConsole = function (configuration = {}) {

  const Console = {};
  window.Console = Console;

  Console.configuration = configuration;

  Console.DOC_BASE_URL = `https://developers.clever-cloud.com/`;

  /*
  * Disable log if quiet option is set to true
  * logger4js is not a dependency of the console
  * but a dependency of events4js: path4js -> events4js -> logger4js
  * so we can require it here anyways
  */
  if (configuration.QUIET_ROUTER_ENABLED) {
    require('logger4js').noprint();
  }

  // We used to only expose those through a config (ACCESS_GLOBAL) but it was always true
  window.$ = $;
  window._ = _;
  window.Bacon = Bacon;
  window.moment = moment;

  Console.LoginAs = require('./modules/loginAs/main.es6.js');
  window.LoginAs = Console.LoginAs;

  /* Models initialization */

  /* Views initialization */
  const $Loader = require('./modules/loader/main.js');
  Console.$Loader = $Loader;
  const $Notification = require('./views/notification.js')(configuration);
  Console.$Notification = $Notification;
  window.$Notification = $Notification;
  const $Panes = require('./modules/panes/main.js');
  Console.$Panes = $Panes;
  const $Headbar = require('./modules/headbar/main.js');
  Console.$Headbar = $Headbar;
  const $Ping = require('./modules/ping/main.js');
  Console.$Ping = $Ping;

  Console.bindListeners = function (loader) {

    // Replace the native [title] behaviour by tooltips
    loader.dom.onValue(function () {
      $('[title]:not(.no-tipsy)').tipsy({ fade: false, delayIn: 500 });
    });

    var s_sharedObjects = loader.api.map(function (api) {
      var moduleConfiguration = _.extend({}, configuration, {
        API: api,
      });

      return {
        InstanceProxy: require('./models/business/proxys/instance')(moduleConfiguration),
        DeploymentProxy: require('./models/business/proxys/deployment')(moduleConfiguration),
        VhostsProxy: require('./models/business/proxys/vhosts.js')(moduleConfiguration),
        API: api,
      };
    }).toProperty();

    s_sharedObjects.onValue(function (objects) {
      Console.API = objects.API;
      window.API = objects.API;
      Console.InstanceProxy = objects.InstanceProxy;
      window.InstanceProxy = objects.InstanceProxy;
      Console.DeploymentProxy = objects.DeploymentProxy;
      window.DeploymentProxy = objects.DeploymentProxy;

      // We used to only expose those through a config (ACCESS_GLOBAL) but it was always true
      window.API = objects.API;
      window.DeploymentProxy = objects.DeploymentProxy;
      window.InstanceProxy = objects.InstanceProxy;
      window.VhostsProxy = objects.VhostsProxy;
    });

    loader.summaryProxy.onValue(function (summaryProxy) {
      Console.SummaryProxy = summaryProxy;
      window.SummaryProxy = summaryProxy;
    });
    // Share some streams
    Console.User = loader.user;
    Console.Organisations = loader.orgas;
    Console.CrispTicketCenterHash = loader.crispTicketCenterHash

    // Display errors when failing to get critical resources
    Bacon.mergeAll(loader.user, loader.orgas, loader.crispTicketCenterHash)
      .errors()
      .mapError(function (err) {
        return err;
      })
      .skipDuplicates(_.isEqual)
      .onValue($Notification.displayError);

    // Redirect to the login page if the user is not connected anymore
    loader.api
      .first()
      .flatMapLatest(api => {
        return Bacon.mergeAll(loader.summary, loader.crispTicketCenterHash)
          .errors()
          .mapError(error => ({ api, error }));
      })
      .onValue(({ api, error }) => {
        if ([2001, 7002].indexOf(error.id) >= 0) {
          LoginAs.login(api);
        }
      });

    // Wait for Yajas loading
    var s_path4js = Bacon.fromEventTarget(Yajas, 'yajas.onload');

    // Extract all statepoints
    var s_statepoints = s_path4js.map(function (path4js) {
      return _.chain(path4js.pathResolver.paths).map('statepoint').uniq().value();
    });

    // Listen to all statepoints loading
    var s_request = s_statepoints.flatMapLatest(function (statepoints) {
      var streams = _.map(statepoints, function (statepoint) {
        return Bacon.fromEventTarget(statepoint, 'onload');
      });

      return Bacon.mergeAll(streams);
    }).toProperty();

    // Listen to all statepoints unloading
    // Console.s_requestOnload will be used instead of global lock variables
    var s_requestUnload = s_statepoints.flatMapLatest(function (statepoints) {
      var streams = _.map(statepoints, function (statepoint) {
        return Bacon.fromEventTarget(statepoint, 'onunload');
      });

      return Bacon.mergeAll(streams);
    });
    Console.s_requestUnload = s_requestUnload;

    s_requestUnload.onValue(function () {
      // Don't let it to be lazy
    });

    // Listen to DOM event keypress for shortcuts
    $ShortCuts({
      $container: $('.shortcuts-container'),
      s_summary: loader.summary,
      s_request: s_request,
      s_vhosts: s_sharedObjects.map('.VhostsProxy'),
    });

    // Watch links to override the browser behaviour
    s_path4js.onValue(function (path4js) {
      path4js.watchLinks();
    });

    // Listen to links all links, detect those who come from shadow DOM and if they target the app
    // if so, trigger a path4js navigation
    window.addEventListener('click', (e) => {
      const link = e.composedPath().find((node) => {
        return (node.nodeName != null)
          && (node.nodeName.toUpperCase() === 'A')
          && (node.href != null);
      });
      if (link != null && !link.classList.contains('that-is-a-yajas-link-comes-to-save-princess-zelda')) {
        const currentUrl = new URL(location.href);
        const linkUrl = new URL(link.href);
        if (currentUrl.origin === linkUrl.origin) {
          e.preventDefault();
          Yajas.path4js.launchPath(Request.fromUri(linkUrl.pathname + linkUrl.search));
        }
      }
    });

    // Update the headbar when browsing
    Bacon.onValues(
      loader.api.toProperty(),
      loader.summaryProxy.toProperty(),
      s_sharedObjects.map('.InstanceProxy'),
      s_sharedObjects.map('.DeploymentProxy'),
      s_sharedObjects.map('.VhostsProxy'),
      s_request,
      Bacon.constant(s_requestUnload),
      _.partial($Headbar.update, Templates, Console.T),
    );

    // Send a notification to Matomo when browsing
    s_request.filter(configuration.ANALYTICS_ENABLED).delay(0).onValue(function (req) {
      window._paq.push(['setCustomUrl', req.request.uri]);
      if (req.statepoint && req.statepoint.title) {
        window._paq.push(['setDocumentTitle', req.statepoint.title]);
      }
      window._paq.push(['setGenerationTimeMs', 0]);
      window._paq.push(['trackPageView']);
    });

    // Give the panes a way to follow browsing updates
    s_path4js.onValue(function (path4js) {
      Console.$panes = $Panes({
        Console: Console,

        orgasPane: '.l-orgas',
        appsPane: '[data-tpl="apps-pane"]',
        settingsPane: '.l-settings',

        noPaneStatepoints: [
          'HomeSP',
          'PaypalCanceledSP',
          'PaypalSuccessSP',
          'UserEmailsSP',
          'UserInformationSP',
          'UserSecuritySP',
          'UserSshKeysSP',
          'UserTokensSP',
          'UserDeletionSP',
        ],

        s_user: Console.User,
        s_orgas: Console.Organisations,
        s_request: s_request,
        s_providers: loader.providers,
      });

      Console.$panes.orgasPane.find('.console-loading').removeClass('console-loading');
    });

    // Set the console under maintenance, if needed
    loader.maintenance
      .filter(function (maintenance) {
        return maintenance;
      })
      .onValue(function () {
        if (Console.$panes) {
          $Panes.remove(Console.$panes);
        }
        $Ping.displayMaintenance(Templates, $('main'));
      });

    // Reload the console once the maintenance period is over
    loader.maintenance
      .changes()
      .skipWhile(function (maintenance) {
        return !maintenance;
      })
      .filter(function (maintenance) {
        return !maintenance;
      })
      .onValue(function () {
        window.location.href = '/';
      });

    // Ask to refresh the console if we detect a new commit or a new config
    loader.dom
      .flatMapLatest(function () {
        return loader.consoleHash.filter(function (consoleHash) {
          return consoleHash !== null;
        }).skip(1).changes();
      })
      .onValue($Ping.displayRefreshPrompt);

    // Listen and display events
    if (configuration.EVENT_API_ENABLED) {
      Bacon
        .combineTemplate({
          api: loader.api,
          summaryProxy: loader.summaryProxy,
          summary: loader.summary, // Wait for /summary to be instanciated by loader module, not anyone else
          InstanceProxy: s_sharedObjects.map('.InstanceProxy'),
          DeploymentProxy: s_sharedObjects.map('.DeploymentProxy'),
          VhostsProxy: s_sharedObjects.map('.VhostsProxy'),
        })
        .firstToPromise()
        .then((obj) => {
          const eventsNotifications = createEventsNotifications({
            API: obj.api,
            SummaryProxy: obj.summaryProxy,
            InstanceProxy: obj.InstanceProxy,
            DeploymentProxy: obj.DeploymentProxy,
            VhostsProxy: obj.VhostsProxy,
            Templates,
            T,
          });
          $DesktopNotification({
            events: eventsNotifications,
            $Notification,
            T: Console.T,
          });
        });
    }
  };

  Console.start = async function () {
    Console.initAnalytics();

    // Init Login As module
    Console.LoginAs();

    // Prepare i18n
    // TODO: try to not expose translations globally
    Console.T = T;
    window.T = Console.T;

    // Wait for first lang to be set (will be last saved lang or english)
    await T.setLanguageTranslator();

    /* Fetch essential data */
    var loader = $Loader(_.defaults({
      container: '.main-loader',
      Templates: Templates,
    }, configuration));

    Console.bindListeners(loader);

    // Set i18n following user's preference
    loader.user.take(1).onValue((user) => {
      // user.lang is null if user never set its language
      if (user.lang) {
        // If the loading of the user takes too much time,
        // and if the setting is french,
        // and if nothing was previously stored in local storage (fresh login),
        // we may end up with a english/french interface.
        // This should not happen that much and it will be improved once we rework the skeleton of the whole app.
        const language = user.lang.toUpperCase();
        T.setLanguageTranslator(language)
      }
    });

    loader.all.take(1).flatMapLatest(function (obj) {
      return obj.api.self.get().send();
    }).first().onValue(function (user) {

      Yajas.start();
      if (!user.emailValidated && moment().subtract(24, 'hours').isAfter(user.creationDate)) {
        Console.makeUnavailable(user);
      }
      else {
        Console
          .magicSearch(loader)
          .onValue(request => {
            Yajas.path4js.launchPath(request);
          });
      }
    });
  };

  Console.makeUnavailable = function (user) {
    $Panes.remove(Console.$panes);

    $('main').removeAttr('data-statepoint').html(Templates['unavailable-email'](user));
    var $resend = $('main button.resend');

    var s_confirmationEmail = $resend
      .asEventStream('click')
      .flatMapLatest(function () {
        var s_resend = API.self.confirmation_email.get().send().delay(1000);
        $resend.loadStream(s_resend);
        return s_resend;
      });

    s_confirmationEmail.onValue($Notification.displaySuccess);
    s_confirmationEmail.onError($Notification.displayError);
  };

  Console.initAnalytics = function () {
    // We use matomo
    if (Console.configuration.ANALYTICS_ENABLED) {
      // You **MUST** have _paq in the window scope
      // otherwise the matomo.js file won't be able to access it and set the tracker
      window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      var u = 'https://matomo.corp.clever-cloud.com/';
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', '4']);
      var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = u + 'matomo.js';
      s.parentNode.insertBefore(g, s);
    }
  };

  Console.magicSearch = (loader) => {
    return loader
      .all
      .first()
      .flatMapLatest(streams => streams.summaryProxy.fetchOnce())
      .map(summary => {
        const search = QueryString.parse(window.location.search, false);
        if (_.has(search, 'search')) {
          const summaryUpdated = $QuickSearch.orgasAsArray(summary);
          // We create this request because if we use Console.s_request, it would do a deadlock
          // (since yajas is only initialized at the end of this function)
          // We just need the params field which we don't care
          const request = { params: {} };

          const searchTerms = decodeURIComponent(search.search.replace(/\+/g, ' ')).toLowerCase();
          const results = $QuickSearch.sortResults(searchTerms, summaryUpdated, request);
          const concat = [...results.currentOrga, ...results.orgas, ...results.others];
          if (concat && concat.length === 1) {
            return path4js.Request.fromUri(_.first(concat).url);
          }
          else {
            const s_request = Bacon.constant({ params: {} });
            $QuickSearch({
              $container: $('body .quick-search-container'),
              s_summary: loader.summary,
              s_request: s_request,
              initialSearch: searchTerms,
            });
          }
        }
        return new Request({
          verb: 'GET',
          uri: window.location.pathname + window.location.search,
        });
      });
  };

  return Console;
};
