module.exports = (function(){
  const Bacon = require("baconjs");
  const _ = require("lodash");
  const Yajas = require("yajas");
  const path4js = require("path4js");
  const $ = require("jquery");
  const moment = require("moment");
  const T = require("../models/technical/translation.js");
  const $Modal = require("../modules/modals/main.js");
  const LoginAs = require("../modules/loginAs/main.es6.js");
  const { getAllZones } = require('@clevercloud/client/esm/api/v4/product.js');
  const { sendToApi } = require('../send-to-api');
  const { getZoneWithText, getAddonZones, getAddonProviderFiltered } = require('../clever-client/various.js');
  require('@clevercloud/components/dist/cc-beta.js');
  require('@clevercloud/components/dist/cc-datetime-relative.js');
  require('@clevercloud/components/dist/cc-notice.js');
  require('@clevercloud/components/dist/cc-zone.js');

  const sp = new (require("./AbstractSP.js"))({
    name: 'AddonMigrationSP',
    title: 'ADDON_MIGRATION'
  });

  sp.getStreams = (req) => {
    const orgaId = req.params.oid;
    const addonId = req.params.addonId;

    const s_owner = SummaryProxy.fetchOrgaOnce(orgaId);
    const s_addon = orgaId ?
      API.organisations._.addons._.get().withParams([orgaId, addonId]).send() :
      API.self.addons._.get().withParams([addonId]).send();

    const s_provider = Bacon.combineTemplate({ s_owner, s_addon }).flatMapLatest(function({ s_owner, s_addon }) {
      return Bacon.fromPromise(getAddonProviderFiltered(s_addon.provider.id, s_owner.id));
    });

    const s_migrations = Bacon.combineTemplate({addon: s_addon, owner: s_owner})
      .flatMapLatest(({ owner, addon }) => sp.getMigrations(owner, addon));

    const s_allZones = Bacon.fromPromise(getAllZones().then(sendToApi).then((zones) => zones.map((z) => getZoneWithText(z))));

    const s_providerVersions = s_addon.flatMapLatest(addon => {
      return API.products.addonproviders._.versions.get().withParams([addon.provider.id]).send();
    });

    const s_preorderPlans = Bacon
      .combineTemplate({
        provider: s_provider,
        addon: s_addon,
        owner: s_owner
      })
      .flatMapLatest(({ provider, addon, owner }) => {
        return Bacon.combineAsArray(provider.plans.map(plan => {
          return API.organisations._.addons._.migrations.preorders.get()
            .withParams([owner.id, addon.id])
            .withQuery({ planId: plan.id })
            .send()
            .map(preorder => {
              return { planId: plan.id, invoice: preorder};
            });
        }));
      });

    const s_linkedApplications = Bacon.combineTemplate({
      addon: s_addon,
      owner: s_owner
    }).flatMapLatest(({ addon, owner }) => {
      return API.organisations._.addons._.applications.get().withParams([owner.id, addon.id]).send();
    }).map(applications => {
      return applications.map(application => {
        const url = (orgaId ?
          `/organisations/${orgaId}` :
          "/users/me") + `/applications/${application.id}`;

        return _.extend({}, application, { url });
      });
    });

    return {
      s_addon,
      s_provider,
      s_allZones,
      s_owner,
      s_migrations,
      s_providerVersions,
      s_preorderPlans,
      s_linkedApplications
    };
  };

  sp.on('onload', function(req, $container, streams){
    const s_init = Bacon.combineTemplate({
      addon: streams.s_addon,
      provider: streams.s_provider,
      allZones: streams.s_allZones,
      owner: streams.s_owner,
      migrations: streams.s_migrations,
      providerVersions: streams.s_providerVersions,
      preorderPlans: streams.s_preorderPlans,
      linkedApplications: streams.s_linkedApplications
    }).flatMapLatest(objects => sp.init($container, req, objects));

    s_init.onError($Notification.displayError);
  });

  sp.init = ($container, req, {addon, provider, allZones, owner, migrations, providerVersions, preorderPlans, linkedApplications}) => {
    const migrationToDisplay = sp.getMigrationToDisplay(req, migrations);

    const rawAddon = owner.addons.find(item => item.id === addon.id);
    if (rawAddon.providerId === 'es-addon') {
      sp.displayBetaNotice($container);
    }

    if(migrationToDisplay) {
      if(!req.params.migrationId) {
        sp.updateMigrationHistory(owner, addon, migrationToDisplay);
      }
      return sp.displayMigration($container, owner, addon, migrationToDisplay);
    } else {
      return sp.displayInformations($container, addon, migrations, owner)
        .flatMapLatest(() => sp.displayPlans($container, addon, owner, provider, providerVersions, preorderPlans, linkedApplications, allZones))
        .flatMapLatest(migration => {
          sp.updateMigrationHistory(owner, addon, migration);
          return sp.displayMigration($container, owner, addon, migration);
        });
    }
  };

  sp.displayInformations = ($container, addon, migrations, owner) => {
    const migrationsWithAborted = migrations.map(migration => {
      const isAborted = migration.steps.find(step => step.status === "ABORTED");
      return _.extend({}, migration, {
        isAborted: isAborted ? true : false
      });
    });

    $container
      .find('.card-container.content')
      .html(Templates["AddonMigrationSP.informations"]({ migrations: migrationsWithAborted }));

    const s_switchMigration = $container
      .find('.activity-block')
      .asEventStream('click')
      .first()
      .map(e => $(e.currentTarget).attr('data-migration-id'));

    const s_next = $container
      .find("button.next")
      .asEventStream('click')
      .first();

    s_switchMigration
      .takeUntil(Bacon.mergeAll(s_next, Console.s_requestUnload.first()))
      .onValue(migrationId => {
        const url = (owner.id.indexOf("orga_") === 0 ?
          `/organisations/${owner.id}` :
          `/users/me`) + `/addons/${addon.id}/migrations/${migrationId}`;

        Yajas.path4js.launchPath(path4js.Request.fromUri(url));
      });

    return s_next
      .takeUntil(Bacon.mergeAll(s_switchMigration, Console.s_requestUnload.first()));
  };

  sp.displayPlans = ($container, addon, owner, provider, providerVersions, preorderPlans, linkedApplications, allZones) => {
    const biggerPlans = LoginAs.isLoggedAsAdmin() ?
      provider.plans :
      provider.plans.filter(plan => plan.price >= addon.plan.price);

    const biggerPlansSorted = _.sortBy(biggerPlans, plan => plan.price);
    const providerPlans = biggerPlansSorted.map(plan => {
      const features = _.sortBy(plan.features, feature => feature.name)
        .filter(feature => feature.name.toLowerCase() !== "type")
        .map(feature => {
          return `${feature.value} ${feature.name}`;
        })
        .join(' - ');

      return _.extend({}, plan, {
        featuresJoin: features,
      });
    });

    const providerUpdatedPlans = _.extend({}, provider, {
      plans: providerPlans
    });

    const nextMonth = moment().add(1, "months").format("MMMM");
    const pricingFrom = moment().format("DD/MM");
    const pricingTo = moment().endOf("month").format("DD/MM");

    const versions = sp.getAvailableVersionsForAddon(addon, addon.plan, providerVersions);

    const currentZone = allZones.find((z) => addon.region === z.name);
    const zones = getAddonZones(provider, allZones, addon.plan);

    $container
      .find('.card-container.content')
      .html(Templates["AddonMigrationSP.plans"]({
        addon,
        provider: providerUpdatedPlans,
        ticketCenterURL: owner.id.indexOf("orga_") === 0 ? `/organisations/${owner.id}/ticket-center` : '/users/me/ticket-center',
        currentZone: {
          ...currentZone,
          type: 'loaded',
        },
        zones,
        versions,
        currentSelectedVersion: null, // TODO: once we have an API for this, use the value from the API
        T,
        pricingNextMonth: {
          nextMonth,
          from: pricingFrom,
          to: pricingTo
        }
      }));

    const $form = $container.find('.target-database-migration');

    const s_reset = $form
      .asEventStream('reset')
      .first();

    s_reset.onValue(() => {
      const url = owner.id.indexOf("orga_") === 0 ?
        `/organisations/${owner.id}/addons/${addon.id}/migrations` :
        `/users/me/addons/${addon.id}/migrations`;

      Yajas.path4js.launchPath(path4js.Request.fromUri(url));
    });

    const defaultPlan = provider.plans.find(plan => plan.id === addon.plan.id) ?? _.first(biggerPlansSorted);

    const s_form = $form.find('select[name="target-database-plan"]')
      .asEventStream('change')
      .map(e => $(e.target).val())
      .map(planId => {
        const plan = provider.plans.find(p => p.id === planId);
        const versions = sp.getAvailableVersionsForAddon(addon, plan, providerVersions);
        // Check if the current selected version is in the available versions. If it is, return it so we can select it
        let currentSelectedVersion = $form.find('[name="target-database-version"]').val();
        if(!versions.find(v => v === currentSelectedVersion)) {
          currentSelectedVersion = null;
        }

        const priceEndOfMonth = sp.getPlanPriceUntilEndOfMonth(plan, preorderPlans, biggerPlansSorted);

        return { plan, versions, priceEndOfMonth, currentSelectedVersion };
      })
      .toProperty({
        priceEndOfMonth: sp.getPlanPriceUntilEndOfMonth(addon.plan, preorderPlans, biggerPlansSorted),
        versions: sp.getAvailableVersionsForAddon(addon, addon.plan, providerVersions),
        currentSelectedVersion: null, // TODO: once we have an API for this, use the value from the API
        plan: defaultPlan
      });

    s_form
      .onValue(({ plan, versions, priceEndOfMonth, currentSelectedVersion }) => {
        $container.find('.target-database-version-number').html(Templates["AddonMigrationSP.version"]({
          provider,
          versions,
          currentSelectedVersion,
        }));
        $container.find('.monthly-summary-price-content').text(plan.price);
        $container.find('.monthly-current-price').text(`${priceEndOfMonth}€`);
        const zones = getAddonZones(provider, allZones, plan);
        $container.find('select[name="target-database-location"]').html(Templates['AddonMigrationSP.zone']({ addon, zones }));
      });

    return $form
      .asEventStream('submit')
      .doAction('.preventDefault')
      .takeUntil(s_reset)
      .flatMapLatest(e => {
        return s_form.first().flatMapLatest(({ plan, versions, priceEndOfMonth }) => {
          const version = $(e.target).find('[name="target-database-version"]').val();
          const modal = $Modal({
            type: 'custom',
            title: T("console.addon-migration.confirm-modal.title"),
            body: Templates["modal.addon-migration"]({ plan, version, priceEndOfMonth, linkedApplications }),
            customClasses: ["addon-migration"],
            Templates,
          });

          return modal.s_confirm.first().map(() => {
            $Modal.remove(modal);
            return e;
          });
        });
      })
      .first()
      .flatMapLatest(e => {
        const $form = $(e.target);
        const body = {
          planId: $form.find('[name="target-database-plan"]').val(),
          region: $form.find('[name="target-database-location"]').val(),
          version: $form.find('[name="target-database-version"]').val()
        };

        const s_migrate = sp.launchMigration($container, body, addon, owner);
        $container.find('button[type="submit"]').loadStream(s_migrate);
        return s_migrate;
      });
  };

  sp.displayMigration = ($container, owner, addon, migration) => {
    // FIXME: find a better way without the global flag
    let migrationWip = true;
    const s_migrationUpdate = Bacon.repeat(() => {
      if(!migrationWip) {
        return false;
      }

      return Bacon.later(1000)
        .flatMapLatest(() => sp.getMigration(owner, addon, migration.migrationId))
    })
    .skipDuplicates(_.isEqual)
    .takeUntil(Console.s_requestUnload.first())
    .toProperty(migration)

    const s_next = $container
      .asEventStream("click", ".migration-actions button.next")
      .first();

    const s_abort = $container
      .asEventStream("click", ".migration-actions button.abort")
      .flatMapLatest(e => {
        const s_abort = API.organisations._.addons._.migrations._.delete()
          .withParams([owner.id, addon.id, migration.migrationId])
          .send();

        $(e.target).loadStream(s_abort);
        return s_abort;
      })
      .onValue(() => { /* lazy */ });

    s_next.onValue(() => {
      const url = owner.id.indexOf("orga_") === 0 ?
        `/organisations/${owner.id}/addons/${addon.id}` :
        `/users/me/addons/${addon.id}`;

      Yajas.path4js.launchPath(path4js.Request.fromUri(url));
    });

    s_migrationUpdate.onValue(migration => {
      if(migration.status === "OK" || migration.status === "FAILED" || migration.status === "RECOVERED") {
        migrationWip = false;
      }

      if(migration.status === "RECOVERING" || migration.status === "RECOVERED") {
        migration = sp.migrationRecover(migration);
      }

      const migrationRuntime = sp.getMigrationRuntime(migration);
      const currentMigrationStep = migration.steps.find(step => step.status === "RUNNING");
      const isAborted = migration.steps.find(step => step.status === "ABORTED");
      const outputMessage = migration.steps
        .filter(step => step.status === "FAILED" && _.has(step, "message"))
        .map(step => step.message)
        .join('\n');

      const ticketCenterURL = owner.id.indexOf("orga_") === 0 ? "/organisations/"+ owner.id + "/ticket-center" : "/users/me/ticket-center";

      $container
        .find('.card-container.content')
        .html(Templates["AddonMigrationSP.migration"]({
          migration,
          ticketCenterURL,
          supportMessage: {
            title: encodeURIComponent(T("console.addon-migration.contact-support-title")),
            content: encodeURIComponent(sp.generateMigrationSupportMessage(owner, addon, migration))
          },
          migrationRuntime,
          currentMigrationStep,
          isAborted,
          outputMessage
        }));
    });

    return s_next;
  };

  sp.getMigrations = (owner, addon) => {
    return API.organisations._.addons._.migrations.get().withParams([owner.id, addon.id]).send()
      .map(migrations => {
        const sortedMigrations = _.sortBy(migrations, migration => new Date(migration.requestDate).getTime());
        return sortedMigrations.reverse();
      });
  };

  sp.getMigration = (owner, addon, migrationId) => {
    return API.organisations._.addons._.migrations._.get().withParams([owner.id, addon.id, migrationId]).send();
  };

  sp.getMigrationToDisplay = (req, migrations) => {
    const migrationId = req.params.migrationId;
    if(migrationId) {
      return migrations.find(migration => migration.migrationId === migrationId);
    } else {
      return migrations.find(migration => migration.status === "RUNNING" || migration.status === "RECOVERING");
    }
  };

  sp.launchMigration = ($container, body, addon, owner) => {
    return API.organisations._.addons._.migrations.post().withParams([owner.id, addon.id]).send(JSON.stringify(body));
  };

  sp.updateMigrationHistory = (owner, addon, migration) => {
    const url = (owner.id.indexOf("orga_") === 0 ?
      `/organisations/${owner.id}` :
      "/users/me") + `/addons/${addon.id}/migrations/${migration.migrationId}`;
    history.pushState(null, null, url);
  };

  sp.generateMigrationSupportMessage = (owner, addon, migration) => {
    const failedSteps = migration.steps
      .filter(step => step.status === "FAILED")
      .map(step => T(`console.addon-migration.steps.${step.value}`))
      .join(',');

    return T("console.addon-migration.contact-support-message", {
      ownerId: owner.id,
      addonId: addon.id,
      step: failedSteps ? failedSteps : "",
      migrationId: migration.migrationId
    });
  };

  sp.getMigrationRuntime = (migration) => {
    if(migration.status === "OK" || migration.status === "FAILED" || migration.status === "RECOVERED") {
      const lastStep = _.last(migration.steps);
      const migrationDiff = moment(lastStep.endDate).diff(moment(migration.requestDate));

      const momentUtc = moment.utc(migrationDiff);
      let migrationTimeTemplate;
      if(migrationDiff > (3600 * 1000)) {
        migrationTimeTemplate = `HH:mm:ss [${T("console.addon-migration.end.hours")}]`;
      } else if(migrationDiff > (60 * 1000)) {
        migrationTimeTemplate = `mm:ss [${T("console.addon-migration.end.minutes")}]`;
      } else {
        const seconds = momentUtc.get("seconds");
        const translation = T("console.addon-migration.end.seconds", { seconds });
        migrationTimeTemplate = `s [${translation}]`;
      }

      return momentUtc.format(migrationTimeTemplate);
    } else {
      return null;
    }
  };

  sp.migrationRecover = (migration) => {
    if(migration.status !== "RECOVERED" && migration.status !== "RECOVERING") {
      return migration;
    }

    const stepLen = migration.steps.length;
    let passedErrorStep = false;

    for(let i = 0; i < stepLen; i++) {
      const currentStep = migration.steps[i];
      if(currentStep.status === "RUNNING") {
        break;
      } else if(passedErrorStep) {
        migration.steps[i].status = "RECOVERED";
      } else if(currentStep.status === "FAILED") {
        passedErrorStep = true;
      } else {
        continue;
      }
    }

    return migration;
  };

  sp.getAvailableVersionsForAddon = (addon, plan, providerVersions) => {
    const typeFeature = plan.features.find(feature => feature.name.toLowerCase() === "type")
    if(!typeFeature) {
      $Notification.displayError({message: "Missing Type feature to know if the plan is a dedicated or shared plan"});
      return;
    }

    const versions = typeFeature.value.toLowerCase() === "dedicated" ?
      providerVersions.dedicatedVersions :
      providerVersions.clusters
        .filter(cluster => cluster.zone === addon.region)
        .map(cluster => cluster.version);

    return versions.slice().reverse();
  };

  sp.getPlanPriceUntilEndOfMonth = (plan, preorderPlans, biggerPlans) => {
    let preorder = preorderPlans.find(preorderPlan => preorderPlan.planId === plan.id);

    if(!preorder) {
      const firstBigger = _.first(biggerPlans);
      preorder = preorderPlans.find(preorderPlan => preorderPlan.planId === firstBigger.id);
    }

    const invoice = preorder.invoice;

    return invoice.lines.length > 0 ?
      _.first(invoice.lines).price_total_ht :
      0;
  };

  sp.displayBetaNotice = function($container) {
    const $messageContainer = $container.find('.card-container.content');
    const message = _.template(`
      <cc-beta position="top-right">
        <cc-notice class="migration-notice" intent="info" heading="${T('console.addon-migration.es-addon.title')}" style="margin-block-end: 1em;">
          <div slot="message">${T('console.addon-migration.es-addon.message')}</div>
        </cc-notice>
      </cc-beta>
    `)();
    $messageContainer.before(message);
  };

  return sp;
})();
