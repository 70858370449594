module.exports = (() => {
  const Bacon = require('baconjs');
  const _ = require('lodash');
  const $ = require('jquery');

  const $Modal = require('../modals/main.js');
  const $Cards = require('../cards/view.es6.js');
  const { sendToApi } = require('../../send-to-api.js');
  const { setDefaultPaymentMethod, deletePaymentMethod } = require('@clevercloud/client/esm/api/v4/billing.js')
  const LoadLibrary = require('../load-external-libraries/main.es6.js');

  const $OwnerCreditCards = (settings) => {
    const cardsWithHolderNameNotNull = _.map(settings.cards, card => {
      if(card.holderName === "") {
        return _.extend({}, card, {
          holderName: null
        });
      }

      return card;
    });

    const state = {
      cards: cardsWithHolderNameNotNull,
      ownerId: settings.ownerId,
      canSepa: settings.canSepa,
      $container: settings.$container,
      removable: _.has(settings, 'removable') ? settings.removable : true,
      singleShot: _.has(settings, 'singleShot') ? settings.singleShot : false,
    };

   const s_newCard = $OwnerCreditCards.init(state);
   return s_newCard;
  };

  $OwnerCreditCards.init = (state) => {
    $OwnerCreditCards.displayCards(state, state.$container.find('.credit-cards'), state.cards);
    const s_newCard = $OwnerCreditCards.addCreditCardButton(state, state.$container);
    if (state.canSepa) {
      $OwnerCreditCards.addSepaForm(state, state.$container);
    }
    return s_newCard;
  };

  $OwnerCreditCards.displayCard = (state, card) => {
    const formattedCard = $Cards.formatMethod(card);
    const $card = $(Templates['OwnerCreditCardsSP.credit-card'](_.extend({}, formattedCard, {removable: state.removable})));
    const s_confirmDelete = $card
      .find('button.remove')
      .asEventStream('click')
      .flatMapLatest(_.partial($OwnerCreditCards.confirmDelete, card));

    if(state.removable){
      const s_remove = s_confirmDelete.flatMapLatest(($modal) => {
        const s_removeCall = Bacon.fromPromise(deletePaymentMethod({ id: state.ownerId, methodId: card.token }).then(sendToApi))

        $Modal.loadStream($modal, s_removeCall);
        return s_removeCall;
      });

      s_remove.onValue(() => {
        $card.fadeOut(() => {
          $card.remove();
        });
      });
      s_remove.onError($OwnerCreditCards.removeCreditCardError);
    }

    let s_default;

    if(card.type === "CREDITCARD" || card.type === "SEPA_DEBIT"){
      s_default = $card
        .find('button.set-default-card')
        .asEventStream('click')
        .flatMapLatest(e => {
          const $button = $(e.target);
          const token = $button.parents('.card-line').attr("data-token").trim();
          const body = JSON.stringify({
            type: 'EXISTING_CARD',
            token: token
          });

          const s_makeDefault = Bacon.fromPromise(setDefaultPaymentMethod({ id: state.ownerId }, body).then(sendToApi));

          $button.loadStream(s_makeDefault);

          return s_makeDefault;
        });

      s_default.onValue(_.partial($OwnerCreditCards.setDefaultCard, state, card));
      s_default.onError($Notification.displayError);
    } else {
      s_default = Bacon.never();
    }

    return {$card, s_default};
  };

  $OwnerCreditCards.displayCards = (state, $container, cards) => {
    $container.find("div.pulse").hide();
    $container.find(".credit-card-list-container").css('display', 'block');

    $OwnerCreditCards.displayOwnerCards(state, $container.find(".orga-credit-cards"), cards);
  };

  $OwnerCreditCards.displayOwnerCards = (state, $container, cards) => {
    const $cardsContainer = $container.css('display', 'block');
    $cardsContainer.find('.no-payment-methods').remove();
    const $cardsContainerList = $cardsContainer.find('ul.credit-card-list').empty();
    if(cards.length > 0){
      const ret = _.reduce(cards, ({$cards, s_defaults}, card) => {
        const {$card, s_default} = $OwnerCreditCards.displayCard(state, card);
        $cards.append($card);
        s_defaults.push(s_default);
        return {$cards, s_defaults};
      }, {$cards: $cardsContainerList, s_defaults: []});

      return Bacon.mergeAll(ret.s_defaults).first();
    } else{
      $cardsContainer.append(Templates['OwnerCreditCardsSP.no-cards']());
      return Bacon.never();
    }
  };

  $OwnerCreditCards.confirmDelete = (card) => {
    const cardNumber = $Cards.formatMethod(card).formattedNumber;

    const $modal = $Modal({
      type: 'confirmation',
      title: T("console.owner-cards.remove-payment-method"),
      body: T("console.owner-cards.remove-payment-method-text", { cardNumber }),

      Templates: Templates
    });
    return $modal.s_confirm.map(() => {
      return $modal;
    });
  };

  $OwnerCreditCards.addCreditCardButton = (state, $container) => {
    const s_newCard = $Cards.displayNewCreditCardForm(_.extend({}, state, {cards: state.separatedCards, container: state.$container}), $container, state.singleShot);

    s_newCard.onValue(newCard => {
      if(!state.singleShot) {
        const newState = _.extend({}, state, {
          cards: [...state.cards, newCard]
        });

        $OwnerCreditCards.init(newState);
      }
    });

    s_newCard.onError(error => {
      $Cards.displayErrorCreditCardForm(error);
    });

    return s_newCard.skipErrors();
  };

  $OwnerCreditCards.addSepaForm = (state, $container) => {
    const s_newSepa = $Cards.displayNewSepaForm(_.extend({}, state, { container: $container }), $container);

    s_newSepa.onValue(newSepa => {
      const newState = _.extend({}, state, {
        cards: [...state.cards, newSepa]
      });

      $OwnerCreditCards.init(newState);
    });

    s_newSepa.onError(error => {
      $Cards.displayErrorSepaForm(error);
    });
  };

  $OwnerCreditCards.setDefaultCard = (state, card) => {
    state.$container.find(".card-line.selected").removeClass('selected');
    state.$container.find(`.card-line[data-token="${card.token}"]`).addClass('selected');
  };

  $OwnerCreditCards.setSelectedCardFirst = (cards) => {
    return _.reduce(cards, (_cards, card) => {
      if(card.isDefault){
        return [].concat([card]).concat(_cards);
      } else {
        return [].concat(_cards).concat([card]);
      }
    }, []);
  };

  $OwnerCreditCards.removeCreditCardError = (error) => {
      $Notification.displayError(error);
  };

  return $OwnerCreditCards;
})();
