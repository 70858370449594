var $UniformisedDateTime = require('../../src/modules/uniformisedDateTime/main.js');
var $Modal = require('../../src/modules/modals/main.js');
const Bacon = require('baconjs');

module.exports = (function() {
  var sp = new (require("./AbstractSP.js"))({
    name: 'UserTokensSP'
  });

  sp.getStreams = function(req){
    var s_tokens = API.self.tokens.get().send().map(function(tokens) {
      return _.sortBy(tokens, function(token) {
        return -token.lastUtilisation;
      });
    });

    const s_user = Console.User;

    return {
      s_tokens,
      s_user,
    };
  };

  sp.on('onload', function(req, $container, streams) {
    Bacon.onValues(streams.s_tokens, streams.s_user, _.partial(sp.displayTokens, $container));
  });

  sp.displayTokens = function($container, tokens, user) {
    var currentTokens = Console.LoginAs.getTokens();
    const dateFormatLang = user.lang === 'FR' ? 'fr-FR' : 'en-US'; 
    const dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC'
    };

    var tokensFormatedTimestamp = _.map(tokens, function(token){
      const creationDate = new Date(token.creationDate).toLocaleString(dateFormatLang, dateOptions);
      const lastUtilisation = token.lastUtilisation ?  new Date(token.lastUtilisation).toLocaleString(dateFormatLang, dateOptions) : T("NEVER");
      const expirationDate = token.expirationDate ?  new Date(token.expirationDate).toLocaleString(dateFormatLang, dateOptions) : T("NEVER");
      const source =  (token.employeeId != null) ? 'Clever team' : token.consumer.name;
      return _.extend({}, token, {
        creationDate, 
        lastUtilisation,
        expirationDate,
        source
      });
    });

    var $tokens = $container.find(".tokens").html(Templates["UserTokensSP.tokens"]({
      tokens: tokensFormatedTimestamp,
      currentToken: currentTokens.user_oauth_token
    }));

    $tokens.find("[data-token] button.remove").click(function() {
      var $token = $(this).parents("[data-token]");
      var token = $token.attr("data-token");

      var s_res = API.self.tokens._.delete().withParams([token]).send();
      s_res.delay(1000).onValue(function(){
        // User can't remove the current used token because of the UI deciding so
        // But, in case he does bypass the UI, we remove the localStorage, just to be sure
        if(token === currentTokens.user_oauth_token){
          sp.clearAndRedirect();
        }
        $token.fadeOut(function(){
          $token.remove();
        });
      });
      s_res.onError($Notification.displayError);
      $(this).loadStream(s_res, 1000);
    });

    $container.find("button.remove-all").click(function(){
      var s_removeTokens = API.self.tokens.delete().send().delay(1000);
      s_removeTokens.onValue(function(){
        $container.find(".tokens [data-token]").fadeOut(function(){
          // redirect user to avoid "You're disconnected, please login" error messages
          sp.clearAndRedirect();
        });
      });
      s_removeTokens.onError($Notification.displayError);
      $(this).loadStream(s_removeTokens);
    });
  };

  sp.clearAndRedirect = function(){
    Console.LoginAs.clear();
    window.location.href = '/';
  };

  return sp;
})();
