module.exports = (function() {
  var path4js = require('path4js');

  var sp = new path4js.SimpleStatePoint({
    work: function(req) {
      sp.fireEvent('onload', req);
    }
  });

  sp.on('onload', function(req) {
    var s_confirmation = API.organisations._.members.post().withParams([req.params.oid]).withQuery(req.search).send();
    s_confirmation.onValue($Notification.displaySuccess);
    s_confirmation.onError($Notification.displayError);

    // We are doing this this way because we don't have the user's role
    // into the organisation model. And this is the only place we would need it
    // So we are just updating the summary ourselves
    var s_orga = s_confirmation.flatMapLatest(_.partial(SummaryProxy.addOrganisation, req.params.oid));

    s_orga.onValue(function() {
      var Yajas = require('yajas');
      Yajas.path4js.launchPath(path4js.Request.fromUri('/organisations/' + req.params.oid));
    });
  });

  return sp;
})();
