module.exports = (function(){
  let $Modal = require('../modules/modals/main.js');
  let $ = require('jquery');
  let _ = require('lodash');
  let Bacon = require('baconjs');
  let path4js = require("path4js");
  let Yajas = require("yajas");

  let Templates = require('../../generated/templates.js');
  let T = require('../models/technical/translation.js');

  let sp = new (require("./AbstractSP.js"))({
    name: 'OauthConsumerSP'
  });

  let orgaId;
  let consumerKey;

  sp.getStreams = function(req){
    orgaId = req.params.oid || null;
    consumerKey = req.params.consumerKey;

    let s_consumer = orgaId ?
      API.organisations._.consumers._.get().withParams([orgaId, consumerKey]).send() :
      API.self.consumers._.get().withParams([consumerKey]).send();

    let s_consumerSecret = orgaId ?
      API.organisations._.consumers._.secret.get().withParams([orgaId, consumerKey]).send() :
      API.self.consumers._.secret.get().withParams([consumerKey]).send();

    let s_consumerSecretError = s_consumerSecret
      .mapError(e => ({
        secret: ''
      }));

    return {
      s_consumer: s_consumer,
      s_consumerSecretError: s_consumerSecretError
    };
  };

  sp.on('onload', function(req, $container, streams){
    Bacon.onValues(streams.s_consumer, streams.s_consumerSecretError, (consumer, secret) => sp.displayConsumer(consumer, secret, $container));
  });

  sp.displayConsumer = function(consumer, secret, $container){
    let $content = $(Templates["OauthConsumerSP.consumer"]({consumer: consumer, secret: secret.secret}));
    $container.find('.oauth-consumer').html($content);

    let s_delete = $content
      .find('button.delete')
      .asEventStream('click')
      .flatMapLatest(e => {
        let $modal = $Modal({
          type: 'confirmation',
          textCheck: function(input){ return input === consumer.name; },
          title: T("console.oauth-consumers.remove-modal-title"),
          body: Templates["modal.oauth-consumer-deletion"]({consumer: consumer}),

          Templates: Templates
        });

        return $modal.s_confirm.flatMapLatest(e => {
          let s_delete = (orgaId ?
            API.organisations._.consumers._.delete().withParams([orgaId, consumerKey]).send() :
            API.self.consumers._.delete().withParams([consumerKey]).send()
          ).flatMapLatest(() => SummaryProxy.updateConsumers(orgaId)).delay(1000);

          $Modal.loadStream($modal, s_delete);

          return s_delete;
        });
      });

    s_delete.onValue(() => {
      $Notification.displaySuccess({
        message: T('console.oauth-consumers.has-been-deleted')
      });
      Yajas.path4js.launchPath(path4js.Request.fromUri(orgaId ? "/organisations/" + orgaId : "/users/me"));
    });

    s_delete.onError($Notification.displayError);
  };

  return sp;
})();
