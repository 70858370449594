function deploying(instance) {
  return ['BOOTING', 'STARTING', 'DEPLOYING', 'READY'].includes(instance.state);
}

function deployingOrUp(instance) {
  return deploying(instance) || instance.state === "UP";
}

module.exports = {
  deploying,
  deployingOrUp
};
