var T = require('../../../models/technical/translation.js');

var ADDON = function(data, T){
  return T("console.events-notifications." + data.action, {
    addonName: data.addon.name ? data.addon.name : data.addon.id,
    authorName: data.author.name ? data.author.name : data.author.email,
    orgaName: data.orga.name ? data.orga.name : data.orga.id
  });
};

var APPLICATION_INFO = function(data, T){
  return T("console.events-notifications." + data.action, {
    appName: data.app.name ? data.app.name : data.app.id,
    authorName: data.author.name ? data.author.name : data.author.id,
    orgaName: data.orga.name ? data.orga.name : data.orga.id
  });
};

var APPLICATION_ACTION = function(data, T){
  return T("console.events-notifications." + data.action, {
    appName: data.app.name ? data.app.name : data.app.id,
    authorName: data.author.name ? data.author.name : data.author.id,
    orgaName: data.orga.name ? data.orga.name : data.orga.id,
    cause: data.cause
  });
};

var CREDITS_ADDED = function(data, T){
  return T("console.events-notifications.CREDITS_ADDED", {
    authorName: data.author.name ? data.author.name : data.author.email,
    amount: data.amount,
    orgaName: data.orga.name ? data.orga.name : data.orga.id
  });
};

var DEPLOYMENT_STATUS = function(data, T){
  return T("console.events-notifications." + data.action, {
    appName: data.app.name ? data.app.name : data.app.id,
    orgaName: data.orga ?
      (data.orga.name ? data.orga.name : data.orga.id) :
      null
  });
};

module.exports = {
  ADDON: ADDON,
  APPLICATION_INFO: APPLICATION_INFO,
  APPLICATION_ACTION: APPLICATION_ACTION,
  CREDITS_ADDED: CREDITS_ADDED,
  DEPLOYMENT_STATUS: DEPLOYMENT_STATUS
};
