import configuration from '../configuration.js';

/**
 * Saves a feature flag into the local storage (within the whole CONSOLE_CONFIGURATION object)
 * If a settingManager is given, it also stores this feature flag through the Settings API.
 *
 * @param {string} flag
 * @param {any} value
 * @param {SettingManager} [settingManager]
 */
export function saveFeature(flag, value, settingManager = null) {
  configuration[flag] = value;
  localStorage.setItem('CONSOLE_CONFIGURATION', JSON.stringify(configuration, null, '  '));
  if (settingManager != null) {
    settingManager.putSetting(value);
  }
}

/**
 * Gets feature flag from local storage (within the whole CONSOLE_CONFIGURATION object)
 *
 * @param {string} flag
 * @return {any}
 */
export function getFeature(flag) {
  return configuration[flag];
}
