module.exports = (() => {
  const keyChars = [
    { code: 47, allowed: ['shift'] }, // / (slash)
    { code: 60, allowed: ['shift'] }, // <
    { code: 62, allowed: ['shift'] }, // >
    { code: 63, allowed: ['shift'] }, // ?
    { code: 79, required: ['shift'] }, // O (which is shift o)
    { code: 97 }, // a
    { code: 100 }, // d
    { code: 101 }, // e
    { code: 104 }, // h
    { code: 105 }, // i
    { code: 108 }, // l (minus L)
    { code: 109 }, // m
    { code: 111 }, // o
    { code: 115 }, // s
  ]; // Some keys have no keyCode (like /) but have keyChar

  let listeners = [];

  const specialKeys = ['ctrl', 'shift', 'meta', 'alt'];

  let Yajas = require('yajas');
  let path4js = require('path4js');
  let Bacon = require('baconjs');
  let _ = require('lodash');
  let $ = require('jquery');

  let $QuickSearch = require('../quick-search/main.es6.js');

  let ShortCuts = (settings) => {
    let state = {
      $container: settings.$container,
      s_summary: settings.s_summary,
      s_request: settings.s_request,
      s_vhosts: settings.s_vhosts,
      s_esc: Bacon.fromEvent(document, 'keydown').filter(e => e.keyCode === 27)
    };

    ShortCuts.addListeners();

    ShortCuts
      .listenDOMEvents()
      .onValue(e => ShortCuts.runListeners(state, e));
  };

  const SHORTCUT_IGNORED_INPUTS = ['input', 'textarea', 'select'];

  ShortCuts.listenDOMEvents = () => {
    return Bacon
      .fromEvent(document, 'keypress')
      .filter(e => {
        const target = e.composedPath()[0];
        if(target.nodeName && SHORTCUT_IGNORED_INPUTS.includes(target.nodeName.toLowerCase())){
          return false;
        } else if(_.find(keyChars, key => key.code === e.charCode && ShortCuts.matchSpecialKeys(e, key))){
          return true;
        }
        return false;
      })
      .doAction('.preventDefault');
  };

  ShortCuts.matchSpecialKeys = (e, key) => {
    if(key.required){
      return _.every(key.required, k => e[`${k}Key`]);
    } else if(!key.allowed){
      return _.every(specialKeys, s => !e[`${s}Key`]);
    } else{
      return _.chain(specialKeys)
        .filter(k => {
          return _.find(key.allowed, a => a !== k);
        })
        .every(s => !e[`${s}Key`])
        .value();
    }
  };

  ShortCuts.addListener = (charCode, cb) => {
    if(charCode instanceof Array){
      _.each(charCode, c => ShortCuts.addListener(c, cb));
      return;
    }

    if(!listeners[charCode]){
      listeners[charCode] = [];
    }

    listeners[charCode].push(cb);
  };

  ShortCuts.addListeners = () => {
    const n = ShortCuts.addListener;

    n(47, ShortCuts.QuickSearch);
    n([60, 62], ShortCuts.reduceBluePane);
    n(63, ShortCuts.displayHelp);
    n(79, ShortCuts.openApplication);
    n(97, ShortCuts.jumpToActivity);
    n(100, ShortCuts.jumpToDomainNames);
    n(101, ShortCuts.jumpToEnvironmentVariables);
    n(104, ShortCuts.showHelp);
    n(105, ShortCuts.jumpToInformations);
    n(108, ShortCuts.jumpToLogs);
    n(109, ShortCuts.jumpToMetrics);
    n(111, ShortCuts.jumpToOverview);
    n(115, ShortCuts.jumpToScalability);
  };

  ShortCuts.runListeners = (state, event) => {
    if(listeners[event.charCode]){
      _.each(listeners[event.charCode], cb => cb(state, event));
    }
  };

  ShortCuts.QuickSearch = (state, event) => {
    $QuickSearch({
      $container: $('body .quick-search-container'),
      s_summary: state.s_summary,
      s_request: state.s_request
    });
  };

  ShortCuts.displayHelp = (state) => {
    if(state.$container.is(':visible')){
      ShortCuts.close(state);
    } else{
      state.$container.html(Templates["shortcuts"]()).show();
      state.s_esc.onValue(() => ShortCuts.close(state));
    }
  };

  ShortCuts.canShortcut = (req, pane) => {
    if(req.params.appId) {
      const allowed = ["overview", "deployments", "vhosts", "variables", "logs", "information", "settings", "metrics"];
      return allowed.indexOf(pane) > -1;
    } else if(req.params.addonId) {
      const allowed = ["logs", "information"];
      return allowed.indexOf(pane) > -1;
    }

    return false;
  };

  ShortCuts.getPane = (request, pane) => {
    if(!request.params) {
      return null;
    }

    let type;
    let id;
    if(request.params.appId) {
      type = "applications";
      id = request.params.appId;
    } else if(request.params.addonId) {
      type = "addons";
      id = request.params.addonId;
    } else {
      return null;
    }

    return request.params.oid ?
      `/organisations/${request.params.oid}/${type}/${id}/${pane}` :
      `/users/me/${type}/${id}/${pane}`;
  }

  ShortCuts.openPane = (url) => {
    Yajas.path4js.launchPath(path4js.Request.fromUri(url));
  };

  ShortCuts.jumpToPane = (state, pane) => {
    state
      .s_request
      .first()
      .filter(request => ShortCuts.canShortcut(request, pane))
      .map(request => {
        if(request.params.addonId && pane === "information") {
          // Addons have the "informations" url
          pane = "informations";
        } else if(request.params.appId && pane === "overview") {
          pane = "";
        }

        return ShortCuts.getPane(request, pane);
      })
      .onValue(ShortCuts.openPane);
  };

  ShortCuts.jumpToActivity = (state) => {
    ShortCuts.jumpToPane(state, "deployments");
  };

  ShortCuts.jumpToDomainNames = (state) => {
    ShortCuts.jumpToPane(state, "vhosts");
  };

  ShortCuts.showHelp = (state) => {
    $crisp.push(["do", "chat:open"]);
  };

  ShortCuts.jumpToEnvironmentVariables = (state) => {
    ShortCuts.jumpToPane(state, "variables");
  };

  ShortCuts.jumpToInformations = (state) => {
    ShortCuts.jumpToPane(state, "information");
  };

  ShortCuts.jumpToLogs = (state) => {
    ShortCuts.jumpToPane(state, "logs");
  };

  ShortCuts.openApplication = (state) => {
    state
      .s_request
      .first()
      .filter(ShortCuts.isApp)
      .flatMapLatest(request => {
        let nodeStr = '.headbar .headbar-context-action.app-link a';
        let node = document.querySelector(nodeStr);
        if(node){
          return Bacon.once(node);
        } else{
          return Bacon.fromBinder(sink => {
            let observer = new MutationObserver(mutations => {
              sink(document.querySelector(nodeStr));
              sink(new Bacon.End());
            });

            observer.observe(document.querySelector('.headbar'), {attributes: true, childList: true, characterData: true});
          });
        }
      })
      .onValue(node => {
        node.click();
      });
  };

  ShortCuts.reduceBluePane = (state) => {
    // This shortcut is broken for now, see
    // https://gitlab.corp.clever-cloud.com/clever-cloud/console3/issues/774
    // document.querySelector('.l-settings .pane-bottom button.close').click();
  };

  ShortCuts.jumpToScalability = (state) => {
    ShortCuts.jumpToPane(state, "settings");
  };

  ShortCuts.jumpToMetrics = (state) => {
    ShortCuts.jumpToPane(state, "metrics");
  };

  ShortCuts.jumpToOverview = (state) => {
    ShortCuts.jumpToPane(state, "overview");
  };

  ShortCuts.close = (state) => {
    state.$container.hide();
  };

  return ShortCuts;
})();
