module.exports = (function(){
  let Yajas = require('yajas');
  let path4js = require('path4js');
  let Bacon = require('baconjs');
  let $ = require('jquery');
  let _ = require('lodash');

  let $OauthConsumerCreation = require('../modules/oauth-consumer-creation/main.es6.js');

  let sp = new (require("./AbstractSP.js"))({
    name: 'OauthConsumerEditSP'
  });

  let orgaId;
  let consumerKey;

  sp.getStreams = function(req){
    orgaId = req.params.oid;
    consumerKey = req.params.consumerKey;

    let s_consumer = orgaId ?
      API.organisations._.consumers._.get().withParams([orgaId, consumerKey]).send() :
      API.self.consumers._.get().withParams([consumerKey]).send();

    return {
      s_consumer: s_consumer
    };
  };

  sp.on('onload', (req, $container, streams) => {
    streams.s_consumer.onValue(consumer => sp.displayEditForm($container, consumer));
  });

  sp.displayEditForm = ($container, consumer) => {
    let rights = consumer.rights.almighty === false ? _.omit(consumer.rights, 'almighty') : consumer.rights;
    let parsedRights = $OauthConsumerCreation.parseOauthRights({}, rights).rights;
    let $consumer = $container.find('.edit-oauth-consumer').html(Templates["OauthConsumerEditSP.consumer"]({consumer: _.extend({}, consumer, {
      parsedRights: parsedRights
    })}));

    let s_updateConsumer = $consumer
      .find('form')
      .asEventStream('submit')
      .doAction('.preventDefault')
      .map(e => {
        let target = e.currentTarget;
        let rights = _.reduce($(target).find('input[type="checkbox"]'), (rights, elem) => {
          rights[elem.getAttribute('name')] = elem.checked;
          return rights;
        }, {});

        return {
          oauthConsumer: {
            name: target["application-name"].value,
            baseUrl: target["application-oauth-callback"].value,
            description: target["application-description"].value,
            url: target["application-homepage"].value,
            picture: target["application-image"].value,
            rights: rights
          },
          form: target
        };
      })
      .flatMapLatest(d => {
        let s_update = (orgaId ?
          API.organisations._.consumers._.put().withParams([orgaId, consumerKey]) :
          API.self.consumers._.put().withParams([consumerKey])
        )
        .send(JSON.stringify(d.oauthConsumer))
        .flatMapLatest(function(){
          return SummaryProxy.updateConsumers(orgaId);
        });

        $(d.form).find('button[type="submit"]').loadStream(s_update);

        return s_update;
      });

    s_updateConsumer.onValue(function(){
      Yajas.path4js.launchPath(path4js.Request.fromUri((orgaId ? '/organisations/' + orgaId : '/users/me') + '/oauth-consumers/' + consumerKey));
    });
    s_updateConsumer.onError($Notification.displayError);
  };

  return sp;
})();
