module.exports = (function() {
  var Bacon = require('baconjs');
  var _ = require('lodash');

  var $AddonProviderPlans = require('../modules/addon-provider-plans/view.js');

  var sp = new (require("./AbstractSP.js"))({
    name: 'AddonProviderPlansSP'
  });

  var orgaId;
  var providerId;

  sp.getStreams = function(req){
    orgaId = req.params.oid;
    providerId = req.params.providerId;

    var s_plans = API.organisations._.addonproviders._.plans.get().withParams([orgaId, providerId]).send().toProperty();
    var s_features = API.organisations._.addonproviders._.features.get().withParams([orgaId, providerId]).send().toProperty();

    return {
      s_plans: s_plans,
      s_features: s_features
    };
  };

  sp.on('onload', function(req, $container, streams) {
    Bacon.onValues(streams.s_plans, streams.s_features, _.partial(sp.displayPlans, $container));
    Bacon.mergeAll(streams.s_plans, streams.s_features).onError($Notification.displayError);
  });

  sp.displayPlans = function($container, plans, features) {
    var state = $AddonProviderPlans({
      selector: $container.find(".plans-configuration"),
      plans: plans,
      features: features,
      orgaId: orgaId,
      providerId: providerId
    });
  };

  return sp;
})();
