module.exports = (() => {
  const Bacon = require('baconjs');

  const Templates = require('../../generated/templates.js');
  const $OwnerCreditCards = require('../modules/owner-cards/main.es6.js');
  const LoadLibrary = require('../modules/load-external-libraries/main.es6.js');
  const { sendToApi } = require('../send-to-api.js');
  const { getPaymentMethods } = require('@clevercloud/client/esm/api/v4/billing.js');
  require('@clevercloud/components/dist/cc-header-orga.js');
  require('@clevercloud/components/dist/cc-block.js');
  const { getOrgaPaymentMethodsError } = require('@clevercloud/client/esm/utils/payment.js');

  const sp = new (require('./AbstractSP.js'))({
    name: 'PaymentSP',
    title: 'Payment',
  });

  sp.getStreams = (req) => {
    const orgaId = req.params.oid;
    let s_owner;

    // In case of Personal Space, we can not rely on the SummaryProxy
    // because it retrieves the profile info instead of the orga info
    // this is why we retrieve the userId and then call the orga API with the userId
    if(orgaId == null) {
      const userIdStream = SummaryProxy.fetchUserOnce().map(".id");
      s_owner = userIdStream.flatMapLatest((userId) => API.organisations._.get().withParams([userId]).send());
    } else {
      s_owner = SummaryProxy.fetchOrgaOnce(orgaId);
    }

    const s_methods = s_owner.flatMapLatest((owner) => {
      return Bacon.fromPromise(getPaymentMethods({ id: owner.id }).then(sendToApi));
    });

    const s_stripe = LoadLibrary('stripe-client');

    return {
      s_owner,
      s_methods,
      s_stripe,
    };
  };

  sp.on('onload', (req, $container, streams) => {
    const component = $container.find('cc-header-orga')[0];
    component.state = { type: 'loading' };

    streams.s_owner.onValue((owner) => sp.displayEnterpriseOrganization($container, owner));
    streams.s_owner.onError(() => { component.state = { type: 'error' }});

    Bacon.onValues(
      streams.s_owner,
      streams.s_methods,
      (owner, methods) => {
        sp.showMonthlyInvoiceLayout($container, owner, methods);
        sp.checkOrgaPaymentMethods($container, owner, methods);
      },
    );

    streams.s_stripe.onValue(() => { /* lazy */
    });
  });

  sp.displayEnterpriseOrganization = function ($container, owner) {
    const { avatar, cleverEnterprise, emergencyNumber } = owner;
    let name = owner.name;
    if (owner.id.startsWith('user_')) {
      name = owner.name == null || owner.name === 'Personal space'
        ? T('console.panes.personal-space')
        : `${owner.name} (${T('console.panes.personal-space')})`;
    }
    const component = $container.find('cc-header-orga')[0];
    component.state = { type: 'loaded', name, avatar, cleverEnterprise, emergencyNumber };
  };

  sp.showMonthlyInvoiceLayout = ($container, owner, methods) => {
    const canSepa = owner.canSEPA;
    $container.find('.payment-layouts').html(Templates['PaymentSP.monthly-invoice-layout']({
      canSepa,
    }));
    sp.ownerCreditCards($container, owner, methods, canSepa);
  };

  sp.ownerCreditCards = ($container, owner, methods, canSepa) => {
    $OwnerCreditCards({
      $container: $container.find('.credit-cards-container'),
      cards: methods,
      // we don't really care about the ownerId
      // that's mostly for the incoming user => organisation transition
      ownerId: owner.id,
      canSepa,
    });
  };

  sp.checkOrgaPaymentMethods = ($container, owner, methods) => {
    const errorType = getOrgaPaymentMethodsError(owner, methods);
    if (errorType != null) {
      $container
        .find('.payment-method-warning')
        .html(`
          <cc-warning-payment mode="billing" errors="[]"></cc-warning-payment>
        `)
        .find('cc-warning-payment')
        .prop('errors', [{ type: errorType }])
        .css('margin-bottom', '1em');
    }
  };

  sp.isOrga = (ownerId) => ownerId.indexOf('orga_') === 0;

  return sp;

})();
