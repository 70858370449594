const Bacon = require('baconjs');
module.exports = (function(){
  var Bacon = require('baconjs');
  var Yajas = require('yajas');
  var path4js = require('path4js');
  var _ = require('lodash');

  var $DesktopNotification = function(settings){
    var state = {
      events: settings.events,
      T: settings.T
    };

    const s_notifications = $DesktopNotification.init(state);
    s_notifications.onValue(() => { /* lazy */ });
    s_notifications.onError(error => console.error(error));
  };


  $DesktopNotification.init = function(state){
    var s_available = $DesktopNotification.available().toProperty();
    return s_available
      .flatMapLatest(function(status){
        if(status.status === "AVAILABLE"){
          return $DesktopNotification.checkPermissions();
        } else{
          return Bacon.once({allowed: "GRANTED"});
        }
      })
      .combine(s_available, function(allowed, available){
        return {
          allowed: allowed.allowed,
          available: available
        };
      })
      .filter(function(obj){
        return obj.allowed === "GRANTED";
      })
      .flatMapLatest(function(permissions){
        return $DesktopNotification.getEvents(state).map(function(event){
          return {
            permissions: permissions,
            event: event
          };
        });
      })
      .flatMapLatest(function(data){
        return $DesktopNotification.display(state, data.permissions, data.event);
      });
  };

  $DesktopNotification.available = function(){
    if(("Notification" in window)){
      return Bacon.once({status: "AVAILABLE"});
    } else{
      return Bacon.once({status: "ENOTAVAILABLE"});
    }
  };

  $DesktopNotification.checkPermissions = function(){
    if(Notification.permission === "granted"){
      return Bacon.once({allowed: "GRANTED"});
    } else if(Notification.permission !== "denied"){
      return $DesktopNotification.askForPermission();
    } else{
      return Bacon.once({allowed: "ENOTGRANTED"});
    }
  };

  $DesktopNotification.askForPermission = function(){
    return Bacon.fromBinder(function(sink){
      Notification.requestPermission(function(permission){
        if(permission === "granted"){
          sink({allowed: "GRANTED"});
        } else{
          sink({allowed: "ENOTGRANTED"});
        }
      });
    });
  };

  $DesktopNotification.getEvents = function(state){
    return state.events.s_events.map(function(data){
      return {
        title: state.T("console.events-notifications.title." + state.events.EVENTS[data.message.event].text),
        body: data.body,
        status: state.events.EVENTS[data.message.event].status,
        icon: '//' + window.location.host + '/img/svg-logo.svg',
        clickUrl: data.clickUrl,
      };
    });
  };

  $DesktopNotification.display = function(state, permissions, event){
    if(permissions.available.status === "AVAILABLE"){
      return $DesktopNotification.desktopNotification(state, event);
    } else{
      return Bacon.fromBinder(() => () => {});
    }
  };

  $DesktopNotification.desktopNotification = function(state, event){
    return Bacon.fromBinder(function(sink){
      var notification = new Notification(event.title, {
        body: event.body,
        icon: event.icon,
        tag: btoa(encodeURIComponent(JSON.stringify(event)))
      });

      notification.onclick = function(){
        if(event.clickUrl){
          Yajas.path4js.launchPath(path4js.Request.fromUri(event.clickUrl));
        }
      };

      notification.onclose = function(){
        sink(new Bacon.End());
      };

      // Force close the notification. Some browsers (at least Google Chrome) don't automatically close notifications
      setTimeout(function(){
        notification.close();
      }, 5000);

      return function(){
        notification.close();
      };
    });
  };

  return $DesktopNotification;
})();
