const { getFeature } = require('../helpers/featureFlag.js');

module.exports.getRoutes = function() {

  const addonOverviewModule = require('../statepoints-wc/addon-overview-switch-statepoint.js').init();
  const newLogsFeature = getFeature('NEW_LOGS');

  return {

    '/': require('../statepoints/HomeSP.js'),

    '/ticket-center-choice': [() => import('../statepoints-wc/ticket-center-choice.sp.js'), 'TicketCenterChoiceModuleSP', 'TICKET_CENTER_CHOOSER'],

    '/auth/logout': require('../statepoints/AuthLogoutSP.js'),

    '/users/me': [() => import('../statepoints-wc/orga-overview.sp.js'), 'OrgaOverviewSP', 'OVERVIEW'],
    '/users/me/overview': [() => import('../statepoints-wc/orga-overview.sp.js'), 'OrgaOverviewSP', 'OVERVIEW'],
    '/users/me/information': require('../statepoints/UserInformationSP.js'),
    '/users/me/authentication': require('../statepoints/UserSecuritySP.js'),
    '/users/me/applications/{appId:str}/vhosts': [() => import('../statepoints-wc/domain-names.sp.js'), 'AppDomainNamesSP', 'DOMAIN_NAMES'],
    '/users/me/emails': [() => import('../statepoints-wc/user-emails.sp.js'), 'UserEmailsSP', 'USER_EMAILS'],
    '/users/me/ssh-keys': [() => import('../statepoints-wc/user-ssh-keys.sp.js'), 'UserSshKeysSP', 'USER_SSH_KEYS'],
    '/users/me/tokens': require('../statepoints/UserTokensSP.js'),
    '/users/me/deletion': [() => import('../statepoints-wc/user-deletion.sp.js'), 'UserDeletionSP', 'USER_DELETION'],
    '/users/me/notifications': require('../statepoints/OwnerNotificationsSP.es6.js'),
    '/users/me/payment': require('../statepoints/PaymentSP.es6.js'),
    '/users/me/grafana': [() => import('../statepoints-wc/orga-grafana.sp.js'), 'GrafanaSP', 'METRICS.grafana'],
    '/users/me/ticket-center': [() => import('../statepoints-wc/ticket-center.sp.js'), 'TicketCenterSP', 'TICKET_CENTER'],

    '/users/me/applications/new': require('../statepoints/AppCreationSP.js'),
    '/users/me/applications/{appId:str}': [() => import('../statepoints-wc/app-overview.sp.js'), 'AppOverviewSP', 'OVERVIEW'],
    '/users/me/applications/{appId:str}/information': require('../statepoints/AppSP.js'),
    '/users/me/applications/{appId:str}/settings': require('../statepoints/AppConfigurationSP.js'),
    '/users/me/applications/{appId:str}/tcp-redirections': [() => import('../statepoints-wc/app-tcp-redirections.sp.js'), 'AppTcpRedirectionsSP', 'TCP_REDIRECTIONS'],
    '/users/me/applications/{appId:str}/services-dependencies': require('../statepoints/AppServicesDependenciesSP.es6.js'),
    '/users/me/applications/{appId:str}/variables': [() => import('../statepoints-wc/app-env-vars.sp.js'), 'AppEnvVariablesSP', 'ENVIRONMENT_VARS'],
    '/users/me/applications/{appId:str}/exposed-variables': [() => import('../statepoints-wc/app-exposed-variables.sp.js'), 'AppExposedVariablesSP', 'EXPOSED_VARIABLES'],
    '/users/me/applications/{appId:str}/logs': newLogsFeature
      ? [() => import('../statepoints-wc/app-logs.sp.js'), 'AppLogsSP', 'LOGS']
      : require('../statepoints/LogsSP.es6.js'),
    '/users/me/applications/{appId:str}/deployments': require('../statepoints/AppDeploymentsSP.js'),
    '/users/me/applications/{appId:str}/metrics': require('../statepoints/Metrics.es6.js'),
    '/users/me/applications/{appId:str}/metrics/{type:str}': require('../statepoints/Metrics.es6.js'),

    '/users/me/addons/new': require('../statepoints/AddonCreationSP.js'),
    '/users/me/addons/{addonId:str}': addonOverviewModule,
    '/users/me/addons/{addonId:str}/backups': [() => import('../statepoints-wc/addon-backups.sp.js'), 'AddonBackupsSP', 'ADDON_BACKUPS'],
    '/users/me/addons/{addonId:str}/informations': require('../statepoints/AddonSP.js'),
    '/users/me/addons/{addonId:str}/services-dependencies': [() => import('../statepoints-wc/addon-service-dependencies.sp.js'), 'AddonServicesDependenciesSP', 'SERVICES_DEPENDENCIES'],
    '/users/me/addons/{addonId:str}/migrations': require('../statepoints/AddonMigrationSP.es6.js'),
    '/users/me/addons/{addonId:str}/migrations/{migrationId:str}': require('../statepoints/AddonMigrationSP.es6.js'),
    '/users/me/addons/{addonId:str}/logs': require('../statepoints/LogsSP.es6.js'),
    '/users/me/addons/{addonId:str}/metrics': require('../statepoints/Metrics.es6.js'),
    '/users/me/addons/{addonId:str}/metrics/{type:str}': require('../statepoints/Metrics.es6.js'),

    '/users/me/invoices': [() => import('../statepoints-wc/invoice-list.sp.js'), 'BillsSP', 'INVOICES'],
    '/users/me/invoices/{bid:str}': require('../statepoints/BillSP.js'),

    '/users/me/oauth-consumers/new': require('../statepoints/OauthConsumerCreationSP.es6.js'),
    '/users/me/oauth-consumers/{consumerKey:str}': require('../statepoints/OauthConsumerSP.es6.js'),
    '/users/me/oauth-consumers/{consumerKey:str}/edit': require('../statepoints/OauthConsumerEditSP.es6.js'),

    '/organisations/new': require('../statepoints/OrgaCreationSP.js'),
    '/organisations/{oid:str}': [() => import('../statepoints-wc/orga-overview.sp.js'), 'OrgaOverviewSP', 'OVERVIEW'],
    '/organisations/{oid:str}/overview': [() => import('../statepoints-wc/orga-overview.sp.js'), 'OrgaOverviewSP', 'OVERVIEW'],
    '/organisations/{oid:str}/information': require('../statepoints/OrgaSP.es6.js'),
    '/organisations/{oid:str}/members': [() => import('../statepoints-wc/orga-members.sp.js'), 'OrgaMembersSP', 'ORGA_MEMBERS'],
    '/organisations/{oid:str}/join': require('../statepoints/OrgaJoinSP.js'),
    '/organisations/{oid:str}/notifications': require('../statepoints/OwnerNotificationsSP.es6.js'),
    '/organisations/{oid:str}/payment': require('../statepoints/PaymentSP.es6.js'),
    '/organisations/{oid:str}/saas': [() => import('../statepoints-wc/orga-saas.sp.js'), 'OrgaSaaSSP', 'SHARED_SOFTWARES'],
    '/organisations/{oid:str}/grafana': [() => import('../statepoints-wc/orga-grafana.sp.js'), 'GrafanaSP', 'METRICS.grafana'],
    '/organisations/{oid:str}/ticket-center': [() => import('../statepoints-wc/ticket-center.sp.js'), 'TicketCenterSP', 'TICKET_CENTER'],

    '/organisations/{oid:str}/applications/new': require('../statepoints/AppCreationSP.js'),

    '/organisations/{oid:str}/create': [() => import('../statepoints-wc/creation-tunnel-step-one.sp.js'), 'CreationTunnelStepOneSP', 'CREATE'],

    '/organisations/{oid:str}/create/cellar': [() => import('../creation-tunnel/cellar/configure-cellar.sp.js'), 'CreationTunnelConfigureCellarSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/cellar/{addonId:str}/onboarding': [() => import('../creation-tunnel/cellar/onboarding-cellar.sp.js'), 'CreationTunnelOnboardingCellarSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/config-provider': [() => import('../creation-tunnel/config-provider/configure-config-provider.sp.js'), 'CreationTunnelConfigureConfigProviderSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/config-provider/{addonId:str}/onboarding': [() => import('../creation-tunnel/config-provider/onboarding-config-provider.sp.js'), 'CreationTunnelOnboardingConfigProviderSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/docker': [() => import('../creation-tunnel/docker/configure-docker.sp.js'), 'CreationTunnelConfigureDockerSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/docker/{appId:str}/onboarding': [() => import('../creation-tunnel/docker/onboarding-docker.sp.js'), 'CreationTunnelOnboardingDockerSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/dotnet': [() => import('../creation-tunnel/dotnet/configure-dotnet.sp.js'), 'CreationTunnelConfigureDotnetSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/dotnet/{appId:str}/onboarding': [() => import('../creation-tunnel/dotnet/onboarding-dotnet.sp.js'), 'CreationTunnelOnboardingDotnetSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/elasticsearch': [() => import('../creation-tunnel/elasticsearch/configure-elasticsearch.sp.js'), 'CreationTunnelConfigureElasticsearchSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/elasticsearch/{addonId:str}/onboarding': [() => import('../creation-tunnel/elasticsearch/onboarding-elasticsearch.sp.js'), 'CreationTunnelOnboardingElasticsearchSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/elixir': [() => import('../creation-tunnel/elixir/configure-elixir.sp.js'), 'CreationTunnelConfigureElixirSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/elixir/{appId:str}/onboarding': [() => import('../creation-tunnel/elixir/onboarding-elixir.sp.js'), 'CreationTunnelOnboardingElixirSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/fs-bucket': [() => import('../creation-tunnel/fs-bucket/configure-fs-bucket.sp.js'), 'CreationTunnelConfigureFSBucketSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/fs-bucket/{addonId:str}/onboarding': [() => import('../creation-tunnel/fs-bucket/onboarding-fs-bucket.sp.js'), 'CreationTunnelOnboardingFSBucketSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/go': [() => import('../creation-tunnel/go/configure-go.sp.js'), 'CreationTunnelConfigureGoSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/go/{appId:str}/onboarding': [() => import('../creation-tunnel/go/onboarding-go.sp.js'), 'CreationTunnelOnboardingGoSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/haskell': [() => import('../creation-tunnel/haskell/configure-haskell.sp.js'), 'CreationTunnelConfigureHaskellSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/haskell/{appId:str}/onboarding': [() => import('../creation-tunnel/haskell/onboarding-haskell.sp.js'), 'CreationTunnelOnboardingHaskellSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/java-gradle': [() => import('../creation-tunnel/java-gradle/configure-java-gradle.sp.js'), 'CreationTunnelConfigureJavaGradleSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/java-gradle/{appId:str}/onboarding': [() => import('../creation-tunnel/java-gradle/onboarding-java-gradle.sp.js'), 'CreationTunnelOnboardingJavaGradleSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/java-jar': [() => import('../creation-tunnel/java-jar/configure-java-jar.sp.js'), 'CreationTunnelConfigureJavaJarSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/java-jar/{appId:str}/onboarding': [() => import('../creation-tunnel/java-jar/onboarding-java-jar.sp.js'), 'CreationTunnelOnboardingJavaJarSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/java-maven': [() => import('../creation-tunnel/java-maven/configure-java-maven.sp.js'), 'CreationTunnelConfigureJavaMavenSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/java-maven/{appId:str}/onboarding': [() => import('../creation-tunnel/java-maven/onboarding-java-maven.sp.js'), 'CreationTunnelOnboardingJavaMavenSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/java-play1': [() => import('../creation-tunnel/java-play1/configure-java-play1.sp.js'), 'CreationTunnelConfigureJavaPlay1SP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/java-play1/{appId:str}/onboarding': [() => import('../creation-tunnel/java-play1/onboarding-java-play1.sp.js'), 'CreationTunnelOnboardingJavaPlay1SP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/java-play2': [() => import('../creation-tunnel/java-play2/configure-java-play2.sp.js'), 'CreationTunnelConfigureJavaPlay2SP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/java-play2/{appId:str}/onboarding': [() => import('../creation-tunnel/java-play2/onboarding-java-play2.sp.js'), 'CreationTunnelOnboardingJavaPlay2SP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/java-war': [() => import('../creation-tunnel/java-war/configure-java-war.sp.js'), 'CreationTunnelConfigureJavaWarSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/java-war/{appId:str}/onboarding': [() => import('../creation-tunnel/java-war/onboarding-java-war.sp.js'), 'CreationTunnelOnboardingJavaWarSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/jenkins': [() => import('../creation-tunnel/jenkins/configure-jenkins.sp.js'), 'CreationTunnelConfigureJenkinsSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/jenkins/{addonId:str}/onboarding': [() => import('../creation-tunnel/jenkins/onboarding-jenkins.sp.js'), 'CreationTunnelOnboardingJenkinsSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/keycloak': [() => import('../creation-tunnel/keycloak/configure-keycloak.sp.js'), 'CreationTunnelConfigureKeycloakSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/keycloak/{addonId:str}/onboarding': [() => import('../creation-tunnel/keycloak/onboarding-keycloak.sp.js'), 'CreationTunnelOnboardingKeycloakSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/kv': [() => import('../creation-tunnel/kv/configure-kv.sp.js'), 'CreationTunnelConfigureKvSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/kv/{addonId:str}/onboarding': [() => import('../creation-tunnel/kv/onboarding-kv.sp.js'), 'CreationTunnelOnboardingKvSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/mailpace': [() => import('../creation-tunnel/mailpace/configure-mailpace.sp.js'), 'CreationTunnelConfigureMailpaceSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/mailpace/{addonId:str}/onboarding': [() => import('../creation-tunnel/mailpace/onboarding-mailpace.sp.js'), 'CreationTunnelOnboardingMailpaceSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/matomo': [() => import('../creation-tunnel/matomo/configure-matomo.sp.js'), 'CreationTunnelConfigureMatomoSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/matomo/{addonId:str}/onboarding': [() => import('../creation-tunnel/matomo/onboarding-matomo.sp.js'), 'CreationTunnelOnboardingMatomoSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/meteor': [() => import('../creation-tunnel/meteor/configure-meteor.sp.js'), 'CreationTunnelConfigureMeteorSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/meteor/{appId:str}/onboarding': [() => import('../creation-tunnel/meteor/onboarding-meteor.sp.js'), 'CreationTunnelOnboardingMeteorSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/mongodb': [() => import('../creation-tunnel/mongodb/configure-mongodb.sp.js'), 'CreationTunnelConfigureMongodbSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/mongodb/{addonId:str}/onboarding': [() => import('../creation-tunnel/mongodb/onboarding-mongodb.sp.js'), 'CreationTunnelOnboardingMongodbSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/mysql': [() => import('../creation-tunnel/mysql/configure-mysql.sp.js'), 'CreationTunnelConfigureMysqlSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/mysql/{addonId:str}/onboarding': [() => import('../creation-tunnel/mysql/onboarding-mysql.sp.js'), 'CreationTunnelOnboardingMysqlSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/node': [() => import('../creation-tunnel/node/configure-node.sp.js'), 'CreationTunnelConfigureNodeSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/node/{appId:str}/onboarding': [() => import('../creation-tunnel/node/onboarding-node.sp.js'), 'CreationTunnelOnboardingNodeSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/php': [() => import('../creation-tunnel/php/configure-php.sp.js'), 'CreationTunnelConfigurePhpSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/php/{appId:str}/onboarding': [() => import('../creation-tunnel/php/onboarding-php.sp.js'), 'CreationTunnelOnboardingPhpSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/postgresql': [() => import('../creation-tunnel/postgresql/configure-postgresql.sp.js'), 'CreationTunnelConfigurePostgresqlSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/postgresql/{addonId:str}/onboarding': [() => import('../creation-tunnel/postgresql/onboarding-postgresql.sp.js'), 'CreationTunnelOnboardingPostgresqlSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/pulsar': [() => import('../creation-tunnel/pulsar/configure-pulsar.sp.js'), 'CreationTunnelConfigurePulsarSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/pulsar/{addonId:str}/onboarding': [() => import('../creation-tunnel/pulsar/onboarding-pulsar.sp.js'), 'CreationTunnelOnboardingPulsarSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/python': [() => import('../creation-tunnel/python/configure-python.sp.js'), 'CreationTunnelConfigurePythonSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/python/{appId:str}/onboarding': [() => import('../creation-tunnel/python/onboarding-python.sp.js'), 'CreationTunnelOnboardingPythonSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/redis': [() => import('../creation-tunnel/redis/configure-redis.sp.js'), 'CreationTunnelConfigureRedisSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/redis/{addonId:str}/onboarding': [() => import('../creation-tunnel/redis/onboarding-redis.sp.js'), 'CreationTunnelOnboardingRedisSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/ruby': [() => import('../creation-tunnel/ruby/configure-ruby.sp.js'), 'CreationTunnelConfigureRubySP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/ruby/{appId:str}/onboarding': [() => import('../creation-tunnel/ruby/onboarding-ruby.sp.js'), 'CreationTunnelOnboardingRubySP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/rust': [() => import('../creation-tunnel/rust/configure-rust.sp.js'), 'CreationTunnelConfigureRustSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/rust/{appId:str}/onboarding': [() => import('../creation-tunnel/rust/onboarding-rust.sp.js'), 'CreationTunnelOnboardingRustSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/scala': [() => import('../creation-tunnel/scala/configure-scala.sp.js'), 'CreationTunnelConfigureScalaSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/scala/{appId:str}/onboarding': [() => import('../creation-tunnel/scala/onboarding-scala.sp.js'), 'CreationTunnelOnboardingScalaSP', 'CREATION_TUNNEL_ONBOARDING'],
    '/organisations/{oid:str}/create/static': [() => import('../creation-tunnel/static/configure-static.sp.js'), 'CreationTunnelConfigureStaticSP', 'CREATION_TUNNEL_CONFIGURE'],
    '/organisations/{oid:str}/static/{appId:str}/onboarding': [() => import('../creation-tunnel/static/onboarding-static.sp.js'), 'CreationTunnelOnboardingStaticSP', 'CREATION_TUNNEL_ONBOARDING'],

    '/organisations/{oid:str}/applications/{appId:str}': [() => import('../statepoints-wc/app-overview.sp.js'), 'AppOverviewSP', 'OVERVIEW'],
    '/organisations/{oid:str}/applications/{appId:str}/information': require('../statepoints/AppSP.js'),
    '/organisations/{oid:str}/applications/{appId:str}/settings': require('../statepoints/AppConfigurationSP.js'),
    '/organisations/{oid:str}/applications/{appId:str}/vhosts': [() => import('../statepoints-wc/domain-names.sp.js'), 'AppDomainNamesSP', 'DOMAIN_NAMES'],
    '/organisations/{oid:str}/applications/{appId:str}/tcp-redirections': [() => import('../statepoints-wc/app-tcp-redirections.sp.js'), 'AppTcpRedirectionsSP', 'TCP_REDIRECTIONS'],
    '/organisations/{oid:str}/applications/{appId:str}/services-dependencies': require('../statepoints/AppServicesDependenciesSP.es6.js'),
    '/organisations/{oid:str}/applications/{appId:str}/variables': [() => import('../statepoints-wc/app-env-vars.sp.js'), 'AppEnvVariablesSP', 'ENVIRONMENT_VARS'],
    '/organisations/{oid:str}/applications/{appId:str}/exposed-variables': [() => import('../statepoints-wc/app-exposed-variables.sp.js'), 'AppExposedVariablesSP', 'EXPOSED_VARIABLES'],
    '/organisations/{oid:str}/applications/{appId:str}/logs': newLogsFeature
      ? [() => import('../statepoints-wc/app-logs.sp.js'), 'AppLogsSP', 'LOGS']
      : require('../statepoints/LogsSP.es6.js'),
    '/organisations/{oid:str}/applications/{appId:str}/deployments': require('../statepoints/AppDeploymentsSP.js'),
    '/organisations/{oid:str}/applications/{appId:str}/metrics': require('../statepoints/Metrics.es6.js'),
    '/organisations/{oid:str}/applications/{appId:str}/metrics/{type:str}': require('../statepoints/Metrics.es6.js'),

    '/organisations/{oid:str}/addons/new': require('../statepoints/AddonCreationSP.js'),
    '/organisations/{oid:str}/addons/{addonId:str}': addonOverviewModule,
    '/organisations/{oid:str}/addons/{addonId:str}/backups': [() => import('../statepoints-wc/addon-backups.sp.js'), 'AddonBackupsSP', 'ADDON_BACKUPS'],
    '/organisations/{oid:str}/addons/{addonId:str}/informations': require('../statepoints/AddonSP.js'),
    '/organisations/{oid:str}/addons/{addonId:str}/services-dependencies': [() => import('../statepoints-wc/addon-service-dependencies.sp.js'), 'AddonServicesDependenciesSP', 'SERVICES_DEPENDENCIES'],
    '/organisations/{oid:str}/addons/{addonId:str}/migrations': require('../statepoints/AddonMigrationSP.es6.js'),
    '/organisations/{oid:str}/addons/{addonId:str}/migrations/{migrationId:str}': require('../statepoints/AddonMigrationSP.es6.js'),
    '/organisations/{oid:str}/addons/{addonId:str}/logs': require('../statepoints/LogsSP.es6.js'),
    '/organisations/{oid:str}/addons/{addonId:str}/metrics': require('../statepoints/Metrics.es6.js'),
    '/organisations/{oid:str}/addons/{addonId:str}/metrics/{type:str}': require('../statepoints/Metrics.es6.js'),

    '/organisations/{oid:str}/providers/new': require('../statepoints/AddonProviderCreationSP.es6.js'),
    '/organisations/{oid:str}/providers/{providerId:str}': require('../statepoints/AddonProviderSP.js'),
    '/organisations/{oid:str}/providers/{providerId:str}/plans': require('../statepoints/AddonProviderPlansSP.js'),

    '/organisations/{oid:str}/invoices': [() => import('../statepoints-wc/invoice-list.sp.js'), 'BillsSP', 'INVOICES'],
    '/organisations/{oid:str}/invoices/{bid:str}': require('../statepoints/BillSP.js'),

    '/organisations/{oid:str}/oauth-consumers/new': require('../statepoints/OauthConsumerCreationSP.es6.js'),
    '/organisations/{oid:str}/oauth-consumers/{consumerKey:str}': require('../statepoints/OauthConsumerSP.es6.js'),
    '/organisations/{oid:str}/oauth-consumers/{consumerKey:str}/edit': require('../statepoints/OauthConsumerEditSP.es6.js'),

    // WARNING: if we name the param oid it gets removed by AbstractSP
    '/organisations/{ownerId:str}/invoices/{invoiceId:str}/paypal/success': require('../statepoints/PaypalSuccessSP.es6.js'),
    '/organisations/{ownerId:str}/invoices/{invoiceId:str}/paypal/cancel': require('../statepoints/PaypalCanceledSP.es6.js'),
  };
};
