'use strict';

const { StatePoint } = require('path4js');
require('@clevercloud/components/dist/cc-loader.js');
require('@clevercloud/components/dist/cc-smart-container.js');

class LazyStatepoint extends StatePoint {

  constructor (statepointName, title) {
    super();
    this.name = statepointName;
    this.title = title;
  }

  launch (req) {
    const { oid: ownerId, appId, addonId } = req.params;
    const $main = document.querySelector('main');
    this.selector = $main;
    $main.innerHTML = `
      <header class="headbar"></header>
      <cc-smart-container>
        <div class="lazy-statepoint">
          <cc-loader style="position: absolute;left: 0;width: 100%;top: 0;height: 100%"></cc-loader>
        </div>
      </cc-smart-container>
    `;
    const $statepoint = $main.querySelector('.lazy-statepoint');
    const s_unload = Bacon.fromEvent(this, 'onunload').first();
    this.fireEvent('onload', req, $statepoint, { s_unload });

    Console.User.firstToPromise().then((user) => {
      const $smartContainer = $main.querySelector('cc-smart-container');
      $smartContainer.context = { ownerId: ownerId || user.id, appId, addonId };
    });
  };
}

// This behaviour seems weird but we don't get load and unload in the expected order
// so this is a way to solve this and always have the right data-attribute
const statepointsStack = [];

function updateDataStatepoint (sp, statepointName) {
  if (statepointName != null) {
    sp.selector.dataset.lazyStatepoint = statepointName;
  }
  else {
    delete sp.selector.dataset.lazyStatepoint;
  }
}

function getLazyStatepoint (importStatepoint, statepointName, statepointTitle) {

  const sp = new LazyStatepoint(statepointName, statepointTitle);

  sp.on('onload', async (req, container, streams) => {

    statepointsStack.unshift(statepointName);

    // Add special data attribute for lazy statepoints
    updateDataStatepoint(sp, statepointsStack[0]);

    streams.s_unload.toPromise().then(() => {
      statepointsStack.pop();
      updateDataStatepoint(sp, statepointsStack[0]);
    });

    importStatepoint()
      .then(({ load, loadNew }) => {
        sp.selector.querySelector('cc-loader').remove();
        if (loadNew != null) {
          const pathParams = req.params;
          const queryParams = Object.fromEntries(new URL(location.href).searchParams.entries());
          return loadNew({ pathParams, queryParams, $container: container });
        }
        return load(req, container, streams);
      })
      .catch(console.error);
  });

  return sp;
}

module.exports = {
  getLazyStatepoint,
};
