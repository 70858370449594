import { addOauthHeader } from '@clevercloud/client/esm/oauth.js';
import { API_HOST } from '../configuration.js';
import { getInvoice } from '@clevercloud/client/esm/api/v4/billing.js';
import { getTokens, sendToApi } from '../send-to-api.js';
import { prefixUrl } from '@clevercloud/client/esm/prefix-url.js';

export async function fetchInvoice ({ ownerId, invoiceNumber }) {
  return getInvoice({ id: ownerId, invoiceNumber, type: '' })
    .then(sendToApi)
    .then((invoice) => formatInvoice(ownerId, invoice));
}

// INFO: This is duplicated in clever-components for now
async function formatInvoice (ownerId, rawInvoice) {
  return {
    number: rawInvoice.invoice_number,
    emissionDate: rawInvoice.emission_date,
    type: rawInvoice.type || 'INVOICE',
    status: rawInvoice.status,
    total: {
      amount: rawInvoice.total_tax.amount + rawInvoice.total_tax_excluded.amount,
      currency: rawInvoice.total_tax_excluded.currency,
    },
    downloadUrl: await getDownloadUrl(ownerId, rawInvoice.invoice_number),
    paymentUrl: getPaymentUrl(ownerId, rawInvoice.invoice_number),
  };
}

// INFO: This is duplicated in clever-components for now
function getDownloadUrl (ownerId, invoiceNumber) {
  const allTokens = getTokens();
  return getInvoice({ id: ownerId, invoiceNumber, type: '.pdf' })
    .then(prefixUrl(API_HOST))
    .then(addOauthHeader(allTokens))
    .then((requestParams) => {
      const url = new URL(requestParams.url);
      url.searchParams.set('authorization', btoa(requestParams.headers.Authorization));
      return url.toString();
    });
}

function getPaymentUrl (ownerId, invoiceNumber) {
  return (ownerId == null || ownerId.startsWith('user_'))
    ? `/users/me/invoices/${invoiceNumber}`
    : `/organisations/${ownerId}/invoices/${invoiceNumber}`;
}
