module.exports = (function(){
  const $ = require("jquery");
  const _ = require("lodash");
  const Bacon = require("baconjs");

  const Templates = require("./templates.js");

  var $Dropdown = function(settings){
    const T  = _.has(settings, "T") ? settings.T : (key) => `Unknown translation ${key}`;

    var state = {
      container: settings.container,
      map: settings.map,
      isOpen: false,
      defaultText: settings.defaultText || "",
      placeholder: settings.placeholder || T("console.dropdown.default-placeholder"),
      autocomplete: _.has(settings, "autocomplete") ? settings.autocomplete : true,
      multipleValues: _.has(settings, "multipleValues") ? settings.multipleValues : false,
      escapeHTML: _.has(settings, "escapeHTML") ? settings.escapeHTML : true,

      Templates: Templates,
      T: T
    };

    state.b_values = new Bacon.Bus();
    state.s_values = state.b_values.toProperty(null);

    $Dropdown.init(state);

    return state;
  };

  $Dropdown.init = function(state){
    var $dropdown = state.$dropdown = $(Templates["dropdown"](state));
    state.$dropdownResults = $dropdown.find('.dropdown-results');
    $Dropdown.buildList(state);

    state.container.append($dropdown);

    var s_search = $dropdown.find('.dropdown-choice')
      .asEventStream('click')
      .flatMapLatest(function(e){
        if(state.isOpen){
          $Dropdown.close(state);
          return new Bacon.End();
        } else{
          $Dropdown.open(state);
          return Bacon.once(e);
        }
      })
      .flatMapLatest(_.partial($Dropdown.search, state));

    state.s_search = state.s_values.filter(function(val){
      return val;
    }).toEventStream();

    state.b_values.plug(s_search);
    state.s_search.onValue(function(elem){
      $Dropdown.close(state);
      $Dropdown.updateDefault(state, elem);
    });
  };

  $Dropdown.buildList = function(state){
    state.$dropdownResults.empty().append(Templates["dropdown.list-body"](state));
  };

  $Dropdown.search = function(state){
    var s_click = function(state){
      const selector = 'li[data-id][data-value]';
      return state.$dropdownResults.find(selector)
        .asEventStream('click')
        .first()
        .map(e => {
          const $e = $(e.target);
          if($e.attr('data-id') && $e.attr('data-value')) {
            return $e.get(0);
          } else {
            const new$e = $e.parents(selector).get(0);
            return new$e;
          }
        });
    };

    var s_keypress = Bacon.fromEvent(document, 'keydown').takeWhile(function(){
      return state.isOpen;
    });

    state.u_keypress = s_keypress.filter(function(e){
      var k = e.keyCode;
      return k === 38 || k === 40;
    }).onValue(_.partial($Dropdown.navigate, state));

    s_keypress.filter(function(e){
      return e.keyCode === 27; // ESC
    }).onValue(_.partial($Dropdown.close, state));

    var s_enter = s_keypress
      .filter(function(e){ return e.keyCode === 13; })
      .doAction('.preventDefault')
      .flatMapLatest(function(e){
        var element = state.$dropdownResults.find('li[data-id][data-value].active').get(0);
        return element ? Bacon.once(element) : new Bacon.End();
      });

    var s_search = !state.autocomplete ? Bacon.never() : state.$dropdown.find('.dropdown-search input').focus().asEventStream('input')
      .takeWhile(function(){
        return state.isOpen;
      })
      .map(function(e){
        var regExp = new RegExp(e.target.value.trim(), 'i');
        return _.reduce(state.map, function(newMap, list, key){
          if(list.length > 0 && key.match(regExp)){
            newMap[key] = list;
          } else {
            var newList = _.filter(list, function(item){
              return item.name.match(regExp);
            });

            if(newList.length > 0){
              newMap[key] = newList;
            }
          }

          return newMap;
        }, {});
      })
      .flatMapLatest(function(map){
        $Dropdown.buildList(_.extend({}, state, {map: map}));
        return s_click(state);
      });

    var s_firstClick = s_click(state);

    var s_selected = Bacon.mergeAll(s_firstClick, s_search, s_enter);

    s_selected.onValue(function(elem){
      state.$dropdownResults.find('li[data-id][data-value].active').removeClass('active');
      $(elem).addClass('active');
    });

    return s_selected.map(function(elem){
      return {
        id: elem.getAttribute('data-id'),
        value: elem.getAttribute('data-value')
      };
    }).flatMapLatest(function(value){
      return state.s_values.first().map(function(values){
        if(state.multipleValues){
          return [].concat(values || []).concat([value]);
        } else {
          return value;
        }
      });
    });
  };

  $Dropdown.navigate = function(state, e){
    var k = e.keyCode;
    var $lis = state.$dropdownResults.find('li[data-id][data-value]');
    var activeIdx = _.findIndex($lis, function(li){
      return $(li).hasClass('active');
    });
    var $active = $($lis.get(activeIdx));

    var extendedState = _.extend({}, state, {
      activeIdx: activeIdx,
      $active: $active,
      $lis: $lis
    });

    if($active.length === 0){
      $lis.first().addClass('active');
    } else if(k === 38){
      $Dropdown.navigatePrevious(extendedState);
    } else if(k === 40){
      $Dropdown.navigateNext(extendedState);
    }
  };

  $Dropdown.navigatePrevious = function(state){
    if(state.activeIdx <= 0){
      state.$dropdownResults.scrollTop(0);
    } else{
      state.$active.removeClass('active');
      var $next = $(state.$lis.get(state.activeIdx - 1));
      var nextTop = $next.offset().top;
      var currentOffset = state.$dropdownResults.offset().top;
      var nextOffset = state.$dropdownResults.scrollTop() + (nextTop - currentOffset);
      $next.addClass('active');
      if(nextTop - currentOffset < 0){
        state.$dropdownResults.scrollTop(nextOffset);
      }
    }
  };

  $Dropdown.navigateNext = function(state){
    if((state.activeIdx + 1) >= state.$lis.length){
      return;
    }
    state.$active.removeClass('active');
    var $next = $(state.$lis.get(state.activeIdx + 1));
    var nextBottom = $next.offset().top + $next.outerHeight(false);
    var currentOffset = state.$dropdownResults.offset().top + state.$dropdownResults.outerHeight(false);
    var nextOffset = state.$dropdownResults.scrollTop() + nextBottom - currentOffset;
    $next.addClass('active');
    state.$dropdownResults.scrollTop(nextOffset);
  };

  $Dropdown.open = function(state){
    state.$dropdown.find('.dropdown-body').show();
    state.$dropdown.find('i.ion-icon').removeClass('ion-arrow-down-b').addClass('ion-arrow-up-b');
    state.isOpen = true;

    Bacon.fromEvent(document, 'click')
      .takeWhile(function(){ return state.isOpen; })
      .filter(function(e){ return $(e.target).parents('.dropdown').length === 0; })
      .first()
      .onValue(_.partial($Dropdown.close, state));
  };

  $Dropdown.close = function(state){
    state.$dropdown.find('.dropdown-body').hide();
    state.$dropdown.find('i.ion-icon').removeClass('ion-arrow-up-b').addClass('ion-arrow-down-b');
    state.isOpen = false;
    if(state.u_keypress){
      state.u_keypress();
      state.u_keypress = null;
    }
  };

  $Dropdown.updateDefault = function(state, elem){
    var $container = state.$dropdown.find('.dropdown-choice-value').removeClass('default-text');
    if(!state.multipleValues){
      if(state.escapeHTML) {
        $container.text(elem.value);
      } else {
        $container.html(elem.value);
      }
    } else {
      $container.empty();
      _.each(elem, function(e){
        $container.append(Templates["dropdown.multiple-values-item"]({ state, e }));
      });

      $Dropdown.listenRemoveItem(state, $container);
    }
  };

  $Dropdown.listenRemoveItem = function(state, $container){
    var s_values = $container.find('span i')
      .asEventStream('click')
      .doAction('.stopPropagation')
      .doAction('.preventDefault')
      .flatMapLatest(function(e){
        var id = $(e.target).attr('data-id').trim();
        return state.s_values
          .first()
          .map(function(elems){
            if(!elems){
              elems = [];
            }

            return _.filter(elems, function(elem){
              return elem.id !== id;
            });
          });
      });

    state.b_values.plug(s_values);
  };

  $Dropdown.clearSelected = function(state){
    state.container.find("span.dropdown-choice-value").empty();
  };

  return $Dropdown;
})();
