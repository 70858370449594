module.exports = (() => {
  const Bacon = require("baconjs");
  const _ = require("lodash");
  const $ = require("jquery");

  const Templates = require("../../../generated/templates.js");
  const $Dropdown = require('@clevercloud/console3-dropdown');
  const T = require("../../models/technical/translation.js");

  const { sendToApi } = require('../../send-to-api.js');
  const notificationApi = require('@clevercloud/client/esm/api/v2/notification.js');

  const $OwnerNotifications = (settings) => {
    const state = {
      $container: settings.$container,
      ownerId: settings.ownerId,
      s_requestUnload: settings.s_requestUnload,
      SummaryProxy: settings.SummaryProxy
    };

    state.b_hooks = new Bacon.Bus();
    state.s_hooks = state.b_hooks
      .toProperty()
      .takeUntil(state.s_requestUnload.first());

    $OwnerNotifications.init(state);

    return state;
  };

  $OwnerNotifications.refreshData = (state) => {
    const isOrga = state.ownerId.indexOf("orga_") === 0;
    const s_webhooks = $OwnerNotifications.getWebHooks(state);
    const s_mailhooks = $OwnerNotifications.getMailHooks(state);
    const s_formats = $OwnerNotifications.getWebhookFormats(state);
    const s_events = $OwnerNotifications.getAvailableEvents(state);
    const s_owner = state.SummaryProxy.fetchOrga(isOrga ? state.ownerId : null);
    // Has both apps and addons
    const s_ownerApps = s_owner.map(owner => ({apps: owner.applications, addons: owner.addons}));

    const s_orgaMembers = isOrga ?
      API.organisations._.members.get().withParams([state.ownerId]).send() :
      Bacon.constant(null);

    const s_data = Bacon.combineTemplate({
      webhooks: s_webhooks,
      mailhooks: s_mailhooks,
      formats: s_formats,
      events: s_events,
      owner: s_owner,
      apps: s_ownerApps,
      orgaMembers: s_orgaMembers
    }).takeUntil(state.s_requestUnload.first());

    state.b_hooks.plug(s_data);

    return s_data;
  };

  $OwnerNotifications.getWebhookFormats = () => {
    const prom = notificationApi.getWebhookFormats().then(sendToApi);
    return Bacon.fromPromise(prom);
  };

  $OwnerNotifications.getAvailableEvents = () => {
    const prom = notificationApi.getAvailableEvents().then(sendToApi);
    return Bacon.fromPromise(prom);
  };

  $OwnerNotifications.getWebHooks = ({ ownerId }) => {
    const prom = notificationApi.getWebhooks({ ownerId }).then(sendToApi);
    return Bacon.fromPromise(prom);
  };

  $OwnerNotifications.getMailHooks = ({ ownerId }) => {
    const prom = notificationApi.getEmailhooks({ ownerId }).then(sendToApi);
    return Bacon.fromPromise(prom);
  };

  $OwnerNotifications.postWebHook = ({ ownerId }, webhook) => {
    const data = {
      urls: [{
        url: webhook.url,
        format: webhook.eventFormat,
      }],
      events: webhook.events,
      scope: webhook.scope,
      name: webhook.name,
    };
    const prom = notificationApi.createWebhook({ ownerId }, data).then(sendToApi);
    return Bacon.fromPromise(prom);
  };

  $OwnerNotifications.postMailHook = ({ ownerId }, mailHook) => {
    const data = {
      notified: mailHook.notified,
      events: mailHook.events,
      scope: mailHook.scope,
      name: mailHook.name,
    };
    const prom = notificationApi.createEmailhook({ ownerId }, data).then(sendToApi);
    return Bacon.fromPromise(prom);
  };

  $OwnerNotifications.deleteWebhook = ({ ownerId }, id) => {
    const prom = notificationApi.deleteWebhook({ ownerId, id }).then(sendToApi)
    return Bacon.fromPromise(prom);
  };

  $OwnerNotifications.deleteMailHook = ({ ownerId }, id) => {
    const prom = notificationApi.deleteEmailhook({ ownerId, id }).then(sendToApi)
    return Bacon.fromPromise(prom);
  };

  $OwnerNotifications.showAddHooks = (state) => {
    $OwnerNotifications.toggleAddHooks(state, true);
  };

  $OwnerNotifications.hideAddHooks = (state) => {
    $OwnerNotifications.toggleAddHooks(state, false);
  };

  $OwnerNotifications.toggleAddHooks = (state, displayed) => {
    const $addHook = state.$container.find('.add-hook');
    const $listHooks = state.$container.find('.display-hooks');

    if(displayed){
      $listHooks.hide();
      $addHook.show();
    } else {
      $addHook.hide();
      $listHooks.show();
    }
  };

  $OwnerNotifications.init = (state) => {
    $OwnerNotifications.refreshData(state);

    state.s_hooks.onValue(obj => $OwnerNotifications.initUI(state, obj));
  };

  $OwnerNotifications.initUI = (state, {webhooks, mailhooks, formats, events, owner, apps, orgaMembers}) => {
    state.$container.html(Templates["owner-notifications"]());

    $OwnerNotifications.displayMailHooks(state, mailhooks, apps, events, owner, orgaMembers);
    $OwnerNotifications.handleTabs(state, webhooks, mailhooks, apps, formats, events, owner, orgaMembers);
  };

  $OwnerNotifications.handleTabs = (state, webhooks, mailhooks, apps, formats, events, owner, orgaMembers) => {
    const $handler = state.$container.find('.notifications-hooks-change');
    const $divs = state.$container.find('div.tab');

    $handler.find('button').click(e => {
      const currentlyUsed = $handler.attr('data-current-tab');
      let $targetButton = $(e.target);
      // if e.target is one of the button's child
      if(!$targetButton.is("button")) {
        $targetButton = $targetButton.parents("button");
      }
      const targetTab = $targetButton.attr('data-target').trim();

      // Shouldn't happen since the button must be disabled
      if(targetTab === currentlyUsed){
        return;
      } else {
        $divs.hide();
        state.$container.find(`.${targetTab}`).show();

        if(targetTab === "mailhooks"){
          $OwnerNotifications.displayMailHooks(state, mailhooks, apps, events, owner, orgaMembers);
        } else if(targetTab === "webhooks"){
          $OwnerNotifications.displayWebHooks(state, webhooks, apps, formats, events);
        }

        $handler.attr('data-current-tab', targetTab);
        $handler.find('button').removeAttr('disabled');
        $targetButton.attr('disabled', 'disabled');
      }
    });
  };

  $OwnerNotifications.displayMailHooks = (state, mailhooks, apps, events, owner, orgaMembers) => {
    const mailhooksDisplay = _.map(mailhooks, mailhook => {
      if(mailhook.notified){
        const type = mailhook.notified[0].type;
        const targets = _.chain(mailhook.notified)
          .map('target')
          .map(target => {
            if(type === "userid"){
              const member = _.find(orgaMembers, member => member.member.id === target);
              return member ? member.member.name : target;
            } else {
              return target;
            }
          });

        return _.extend({}, mailhook, {
          notified: {type: mailhook.notified[0].type, targets: targets}
        });
      } else {
        return mailhook;
      }
    });

    const $mailhooks = state.$container
      .find("div.emailhooks")
      .html(Templates["owner-notifications.mailhooks"]({
        mailhooks: mailhooksDisplay,
        owner: owner
      }));

    $mailhooks
      .find('.add-mailhook')
      .click(() => $OwnerNotifications.addNewMailHook(state, $mailhooks, apps, events, orgaMembers));

    if(mailhooks.length > 0){
      $mailhooks
        .find('table.mailhooks .remove')
        .click(e => $OwnerNotifications.removeMailHook(state, e));
    }
  };

  $OwnerNotifications.displayWebHooks = (state, webhooks, apps, formats, events) => {
    const $webhooks = state.$container
      .find("div.webhooks")
      .html(Templates["owner-notifications.webhooks"]({
        webhooks: webhooks
      }));

    $webhooks
      .find('.add-webhook')
      .click(() => $OwnerNotifications.addNewWebhook(state, $webhooks, apps, formats, events));

    if(webhooks.length > 0){
      $webhooks
        .find('table.webhooks .remove')
        .click(e => $OwnerNotifications.removeWebhook(state, e));
    }
  };

  $OwnerNotifications.addNewWebhook = (state, $webhooks, apps, formats, events) => {
    const $container = state.$container.find('.add-hook').html(Templates["owner-notifications.add-hook"]({
      formats: formats,
      hookType: "webhook"
    }));

    $container
      .find('button[type="cancel"]')
      .click(e => {
        e.preventDefault();
        $OwnerNotifications.hideAddHooks(state);
      });

    $OwnerNotifications.showAddHooks(state);

    const _events = _.map(events.meta_events, e => {
      return {
        id: e.key,
        name: T(`console.owner-notifications.events.${e.key}`)
      };
    });

    const s_scope = $Dropdown({
      container: state.$container.find('.dropdown-scope-apps'),
      map: {[T("APPLICATIONS")]: apps.apps, [T("ADDONS")]: apps.addons},
      isOpen: false,
      defaultText: T("console.owner-notifications.add-hook.scope-placeholder"),
      placeholder: "",
      autocomplete: true,
      multipleValues: true,

      Templates: Templates,
      T
    })
      .s_search
      .toProperty([])
      .map(scopes => {
        if(scopes.length > 0){
          return _.map(scopes, scope => scope.id);
        } else {
          return null;
        }
      });

    const s_events = $Dropdown({
      container: state.$container.find('.dropdown-scope-events'),
      map: {events: _events},
      isOpen: false,
      defaultText: T("console.owner-notifications.add-hook.events-placeholder"),
      placeholder: "",
      autocomplete: false,
      multipleValues: true,

      Templates: Templates,
      T
    })
      .s_search
      .toProperty([])
      .map(events => {
        if(events.length > 0){
          return _.map(events, event => event.id);
        } else {
          return null;
        }
      });

    const s_submit = state
      .$container
      .find('.add-hook form')
      .asEventStream('submit')
      .doAction('.preventDefault');

    const s_formElements = Bacon.combineTemplate({
      scope: s_scope,
      events: s_events,
      e: s_submit
    });

    const s_add = s_formElements
      .map(({scope, events, e}) => {
        const webhook = {
          name: e.target["webhook-name"].value.trim(),
          events: events,
          scope: scope,
          eventFormat: e.target["webhook-event-format"].value.trim(),
          url: e.target["webhook-target-url"].value.trim()
        };

        return {webhook, e};
      })
      .flatMapLatest(({webhook, e}) => {
        const s_post = $OwnerNotifications.postWebHook(state, webhook);

        $(e.target).find('button[type="submit"]').loadStream(s_post);

        return s_post;
      })
      .flatMapLatest(() => $OwnerNotifications.refreshData(state));

    s_add.onValue(() => { /* Lazy */ });
    s_add.onError($Notification.displayError);
  };

  $OwnerNotifications.removeWebhook = (state, e) => {
    const attr = "data-webhook-id";
    const webhookId = $(e.target).parents(`tr[${attr}]`).attr(attr).trim();

    const s_delete = $OwnerNotifications.deleteWebhook(state, webhookId)
      .flatMapLatest(() => $OwnerNotifications.refreshData(state));

    $(e.target).loadStream(s_delete);

    s_delete.onValue(() => { /* Lazy */ });
    s_delete.onError($Notification.displayError);
  };

  $OwnerNotifications.removeMailHook = (state, e) => {
    const attr = "data-mailhook-id";
    const $target = $(e.target);
    const mailhookId = $target.parents(`tr[${attr}]`).attr(attr).trim();

    const s_delete = $OwnerNotifications.deleteMailHook(state, mailhookId)
      .flatMapLatest(() => $OwnerNotifications.refreshData(state));

    $target.loadStream(s_delete);

    s_delete.onValue(() => { /* Lazy */ });
    s_delete.onError($Notification.displayError);
  };

  $OwnerNotifications.addNewMailHook = (state, $mailhooks, apps, events, orgaMembers) => {
    const $container = state.$container.find('.add-hook').html(Templates["owner-notifications.add-hook"]({
      hookType: "emailhook",
      isOrga: state.ownerId.indexOf("orga_") === 0
    }));

    $container
      .find('button[type="cancel"]')
      .click(e => {
        e.preventDefault();
        $OwnerNotifications.hideAddHooks(state);
      });

    $OwnerNotifications.showAddHooks(state);

    const s_notify = $OwnerNotifications.addNewMailHookNotify(state, $mailhooks, orgaMembers);

    const _events = _.chain(events.meta_events)
      .filter(e => e.key === "META_DEPLOYMENT_RESULT")
      .map(e => {
        return {
          id: e.key,
          name: e.key
        };
      })
      .value();

    const s_scope = $Dropdown({
      container: state.$container.find('.dropdown-scope-apps'),
      map: {applications: apps.apps},
      isOpen: false,
      defaultText: "",
      placeholder: "",
      autocomplete: true,
      multipleValues: true,

      Templates: Templates,
      T
    })
      .s_search
      .toProperty([])
      .map(scopes => {
        if(scopes.length > 0){
          return _.map(scopes, scope => scope.id);
        } else {
          return null;
        }
      });

    const s_events = $Dropdown({
      container: state.$container.find('.dropdown-scope-events'),
      map: {events: _events},
      isOpen: false,
      defaultText: "",
      placeholder: "",
      autocomplete: false,
      multipleValues: true,

      Templates: Templates,
      T
    })
      .s_search
      .toProperty([])
      .map(events => {
        if(events.length > 0){
          return _.map(events, event => event.id);
        } else {
          return null;
        }
      });

    const s_submit = state
      .$container
      .find('.add-hook form')
      .asEventStream('submit')
      .doAction('.preventDefault');

    const s_formElements = Bacon.combineTemplate({
      scope: s_scope,
      events: s_events,
      notify: s_notify,
      e: s_submit
    });

    const s_add = s_formElements
      .map(({scope, events, notify, e}) => {
        const notified = _.map(notify.values, v => ({type: notify.type, target: v.trim()}));
        const mailHook = {
          name: e.target["webhook-name"].value.trim(),
          events: events,
          scope: scope,
          notified: notified.length > 0 ? notified : [{type: notify.type, target: null}]
        };

        return {mailHook, e};
      })
      .flatMapLatest(({mailHook, e}) => {
        const s_post = $OwnerNotifications.postMailHook(state, mailHook);

        $(e.target).find('button[type="submit"]').loadStream(s_post.mapError());

        return s_post;
      })
      .flatMapLatest(() => $OwnerNotifications.refreshData(state));

    s_add.onValue(() => { /* Lazy */ });
    s_add.onError($Notification.displayError);
  };

  $OwnerNotifications.addNewMailHookNotify = (state, $mailhooks, orgaMembers) => {
    const $notifyContainer = state.$container.find('div.mailhook-notify-target-content');
    const $select = state.$container.find('select[name="mailhook-notify-target"]');
    const defaultOption = $select.find('option').get(0).value;

    return $select
      .asEventStream('change')
      .map(e => e.target.value)
      .toProperty(defaultOption)
      .flatMapLatest(type => {
        if(type === "organisation"){
          $notifyContainer.empty();
          return Bacon.once({type: type, values: null});
        } else if(type === "userid"){
          return $OwnerNotifications.hookNotifyUserIds(state, $notifyContainer, orgaMembers)
            .map(values => ({type, values}));
        } else if(type === "email"){
          return $OwnerNotifications.hookNotifyEmail(state, $notifyContainer)
            .map(values => ({type, values}));
        } else {
          return Bacon.once(new Bacon.Error(new Error("Unrecognized type")));
        }
      });
  };

  $OwnerNotifications.hookNotifyUserIds = (state, $notifyContainer, orgaMembers) => {
    const _orgaMembers = _.map(orgaMembers, m => {
      return _.extend({}, m.member, {
        name: m.member.name || m.member.email
      });
    });

    $notifyContainer.html(Templates["owner-notifications.notify-user-id"]());

    return $Dropdown({
      container: $notifyContainer.find('.dropdown-notify'),
      map: {members: _orgaMembers},
      isOpen: false,
      defaultText: "",
      placeholder: "",
      autocomplete: true,
      multipleValues: true,

      Templates: Templates,
      T
    }).s_search.map(members => {
      return _.map(members, m => m.id);
    });
  };

  $OwnerNotifications.hookNotifyEmail = (state, $notifyContainer) => {
    $notifyContainer.html(Templates["owner-notifications.notify-email"]());

    return $notifyContainer
      .find('input[name="notify-content-emails"]')
      .asEventStream('change')
      .map(e => e.target.value)
      .map(value => value.split(','));
  };

  return $OwnerNotifications;
})();
