import loginAs from './modules/loginAs/main.es6.js';
import { addOauthHeader } from '@clevercloud/client/esm/oauth.js';
import { API_CONSUMER_KEY, API_CONSUMER_SECRET, API_HOST, UNAUTHORIZED_BEHAVIOR } from './configuration.js';
import { get as getSelf } from '@clevercloud/client/esm/api/v2/organisation.js';
import { login, logout } from './login-oauth.js';
import { prefixUrl } from '@clevercloud/client/esm/prefix-url.js';
import { request } from '@clevercloud/client/esm/request.fetch.js';
import { onApiError } from './lib/api-error-handlers.js';

export function getTokens () {
  const consoleTokens = loginAs.getTokens();
  return {
    OAUTH_CONSUMER_KEY: API_CONSUMER_KEY,
    OAUTH_CONSUMER_SECRET: API_CONSUMER_SECRET,
    API_OAUTH_TOKEN: consoleTokens.user_oauth_token,
    API_OAUTH_TOKEN_SECRET: consoleTokens.user_oauth_token_secret,
  };
}

export function sendToApi (requestParams) {
  const allTokens = getTokens();
  return Promise.resolve(requestParams)
    .then(prefixUrl(API_HOST))
    .then(addOauthHeader(allTokens))
    .then(request)
    .catch((error) => {
      onApiError(error);
      throw error;
    });
}

// special case for the console
export function getCrispTicketCenterhash () {
  const allTokens = getTokens();
  return getSelf({})
    .then(prefixUrl(API_HOST))
    .then(addOauthHeader(allTokens))
    .then((requestParams) => {
      const url = new URL(location.origin);
      url.pathname = '/crispticketcenterhash';
      return {
        ...requestParams,
        url: url.toString(),
      };
    })
    .then(request);
}
