module.exports = (function() {
  var path4js = require('path4js');
  var Yajas = require('yajas');
  var $OrgaInformations = require('../modules/orga-informations/main.es6.js');

  var sp = new (require("./AbstractSP.js"))({
    name: 'OrgaCreationSP'
  }, {countries: Console.configuration.COUNTRIES});

  sp.on('onload', function(req, $container) {
    var $form = $container.find('form.orga-creation');

    $form.submit(function(e) {
      e.preventDefault();

      var orga = $OrgaInformations.onOrgaSubmit.call(this);
      sp.createOrganisation($container, orga);
    });

    $form.find('[name="orga-is-company"]').click(function() {
      var orga = {
        isCompany: $(this).val() === "true"
      };
      var tplParams = {countries: Console.configuration.COUNTRIES, orga: orga, readonly: false, vatData: {}};
      $form.find('.company-details').html(Templates["OrgaSP.company-details"](tplParams));
      if(orga.isCompany){
        $form.find('input[name="orga-no-vat"]').click(_.partial($OrgaInformations.onOrgaNoVAT, $form));
        $form.find('[name="orga-vat"]').focusout(_.partial($OrgaInformations.onVATFocusOut, $form));
      }
    });
  });

  sp.createOrganisation = function($container, orga) {
    var s_orga = API.organisations.post().send(JSON.stringify(orga))
      .delay(1000) // Wait a bit before fetching all the data
      .flatMapLatest(function(newOrga){
        // New organisation, we have to build the summary ourselves
        // with the new organisation and empty informations
        return SummaryProxy
          .addOrganisation(newOrga.id)
          .map(newOrga);
      });

    s_orga.onValue(sp.redirectToOrganisation);
    s_orga.onError($Notification.displayError);

    $container.find("[type='submit']").loadStream(s_orga, 1000);
  };

  sp.redirectToOrganisation = function(orga) {
    Yajas.path4js.launchPath(path4js.Request.fromUri('/organisations/' + orga.id));
  };

  return sp;
})();
