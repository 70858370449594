var $ = require("jquery");
var Bacon = require("baconjs");
var _ = require('lodash');
const { login } = require('../../login-oauth.js');

var $Modal = require('../modals/main.js');

var $Headbar = module.exports;

// This will store the current unsubscribe method for the "click" event of the redeploy button
var u_deploy;
var s_newFavouriteDomain = Bacon.fromEvent(window, 'domain-management-primary-change').startWith().toProperty();

$Headbar.update = function(Templates, T, API, SummaryProxy, InstanceProxy, DeploymentProxy, VhostsProxy, request, s_requestUnload) {
  if(request.params.appId) {
    var s_owner = SummaryProxy.fetchOrgaOnce(request.params.oid).toProperty();

    var s_app = s_owner.flatMapLatest(function(owner) {
      var app = _.find(owner.applications, function(app) {
        return app.id == request.params.appId;
      });

      return app ? Bacon.once(app) : new Bacon.Error({message: "APPLICATION_NOT_FOUND"});
    }).toProperty();

    Bacon.onValues(s_owner, s_app, function(owner, app) {
      var returnError = function(obj){
        if(obj && obj.type && obj.type === "error"){
          return Bacon.once(new Bacon.Error(obj));
        } else{
          return Bacon.once(obj);
        }
      };

      var s_instances = InstanceProxy
        .fetchAll(request.params.appId, request.params.oid)
        .takeUntil(s_requestUnload)
        .flatMapLatest(returnError)
        .toProperty();

      var s_initialDomains = VhostsProxy
        .fetch(owner.id, app.id)
        .flatMapLatest(returnError)
        .toProperty();

      var s_deployment = s_owner.flatMapLatest(function(owner) {
        return DeploymentProxy
          .fetchFirst(owner.id, app.id)
          .takeUntil(s_requestUnload)
          .flatMapLatest(returnError);
      })
      .toProperty();

      Bacon.mergeAll(s_instances, s_deployment, s_initialDomains)
        .onError(function(err){
          if(err.id === 2001){ // Not connected
            login();
          }
        });

      const s_domains = Bacon.combineWith(s_initialDomains.skipErrors(), s_newFavouriteDomain, (initialDomains, newFavouriteEvent) => {
        if (newFavouriteEvent == null) {
          return initialDomains;
        }

        return initialDomains.map((domain) => ({ ...domain, favourite: domain.fqdn === newFavouriteEvent.detail }));
      });

      $Headbar.app({
        container: $(request.statepoint.selector).find(".headbar"),
        owner,
        app,
        s_instances: s_instances.skipErrors(),
        s_domains,
        s_deployment: s_deployment.skipErrors(),
        title: request.statepoint.title,

        API,
        Templates,
        T
      });
    });
  } else if(request.params.addonId) {
    var s_owner = SummaryProxy.fetchOrgaOnce(request.params.oid).toProperty();

    var s_addon = s_owner.flatMapLatest(function(owner) {
      var addon = _.find(owner.addons, function(addon) {
        return addon.id == request.params.addonId;
      });

      return addon ? Bacon.once(addon) : new Bacon.Error({message: "APPLICATION_NOT_FOUND"});
    }).toProperty();

    Bacon.onValues(s_owner, s_addon, (owner, addon) => {
      $Headbar.addon({
        container: $(request.statepoint.selector).find(".headbar"),
        owner: owner,
        addon: addon,
        title: request.statepoint.title,

        Templates: Templates,
        T: T
      });
    });
  }
  $Headbar.help.init({
    container: $(request.statepoint.selector).find(".headbar"),

    Templates: Templates
  });
};

$Headbar.app = function(settings) {
  var state = {
    container: $(settings.container),
    owner: settings.owner,
    app: settings.app,
    s_instances: settings.s_instances.toProperty(),
    s_domains: settings.s_domains.toProperty(),
    s_deployment: settings.s_deployment.toProperty(),
    title: settings.title,
    beta: $(settings.container).hasClass("beta"),

    API: settings.API,
    Templates: settings.Templates,
    T: settings.T
  };

  $Headbar.app.init(state);

  return state;
};

$Headbar.addon = function(settings) {
  var state = {
    container: $(settings.container),
    owner: settings.owner,
    addon: settings.addon,
    title: settings.title,
    beta: $(settings.container).hasClass("beta"),

    Templates: settings.Templates,
    T: settings.T
  };

  $Headbar.addon.init(state);
};

$Headbar.app.init = function(state) {
  state = _.extend({}, state, {
    headbarTitle: T(state.title) + (state.container.hasClass('no-app-name') ? '' : "- " + state.app.name)
  });

  $(state.container).append(state.Templates["headbar.app"](state));

  Bacon.onValues(state.s_instances, state.s_domains, state.s_deployment, function(instances, domains, lastDeployment) {
    if(u_deploy){
      u_deploy();
    }
    if(lastDeployment){
      u_deploy = $Headbar.app.displayDeployButton(state, instances, lastDeployment);
      $Headbar.app.displayAppLink(state, domains);
    }
  });
};

$Headbar.addon.init = function(state) {
  state = _.extend({}, state, {
    headbarTitle: T(state.title) + (state.addon.name || state.addon.id),
    app: state.addon
  });

  $(state.container).append(state.Templates["headbar.addon"](state));
}

$Headbar.app.displayDeployButton = function(state, instances, lastDeployment) {
  var orgaId = state.owner.id.indexOf("orga_") === 0 && state.owner.id;
  var running = _.size(instances) > 0;

  var $actions = $(state.container).find(".headbar-context-action.deploy-button").html(state.Templates["headbar.deploy-button"]({
    running: running
  }));

  var $deploy = $actions.find("button.deploy");
  var s_deploy = $deploy.asEventStream("click").flatMapLatest(function(){
    if(Console.LoginAs.isLoggedAsAdmin()) {
      return Bacon.once(new Bacon.Error(new Error("You can't redeploy using the LoginAs feature.")));
    }

    $('.tipsy').remove();
    if(lastDeployment.state === "FAIL"){
      var $modal = $Modal({
        type: 'confirmation',
        title: state.T('console.deployments.deploy-failed-commit-question-modal'),
        body: lastDeployment.commit ? state.T('console.deployments.deploy-failed-commit-body-modal', { commitId: lastDeployment.commit.substr(0, 6) }) : state.T('console.deployments.deploy-failed-no-commit-body-modal'),
        submitButtonText: state.T('console.deployments.deploy-failed-commit-submit-modal'),

        Templates: state.Templates
      });

      $modal.s_confirm.onValue(function(){
        $Modal.remove($modal);
      });

      return $modal.s_confirm;
    } else{
      return Bacon.once();
    }
  }).flatMapLatest(function() {
    var s_response = (orgaId ?
      state.API.organisations._.applications._.instances.post().withParams([orgaId, state.app.id, orgaId]).send() :
      state.API.self.applications._.instances.post().withParams([state.app.id]).send()).delay(1000);

    $deploy.loadStream(s_response);

    return s_response;
  });

  s_deploy.onError($Notification.displayError);

  return s_deploy.onValue(function() {
    $('.tipsy').fadeOut();
    var $actions = $(state.container).find(".headbar-context-action.deploy-button").html(state.Templates["headbar.readlogs-button"]({
      orgaId: orgaId,
      appId: state.app.id
    }));
  });
};

$Headbar.app.displayAppLink = function(state, domains) {
  if(domains.length > 0) {
    var customDomain = _.find(domains, function(domain){
      return domain.fqdn && domain.fqdn.match('.cleverapps.io') === null;
    });

    var fqdn;
    var favourite = _.find(domains, function(domain){
      return domain.favourite;
    });

    if(favourite){
      fqdn = favourite.fqdn;
    } else if(customDomain){
      fqdn = customDomain.fqdn;
    } else{
      fqdn = _.head(domains).fqdn;
    }

    $(state.container).find(".headbar-context-action.app-link").html(state.Templates["headbar.applink-button"]({
      fqdn: fqdn.indexOf('*.') === 0 ? "www." + fqdn.substr(2) : fqdn
    }));
  } else {
    $(state.container).find(".headbar-context-action.app-link").remove();
  }
};

$Headbar.help = function(settings){
  var state = {
    container: settings.container,
    Templates: settings.Templates
  };

  $Headbar.help.init(state);
};

$Headbar.help.init = function(state){

  const { prefix } = window.location.pathname.match(/(?<prefix>\/.*\/(?:orga_[0-9a-f-]+|me))/)?.groups ?? { };
  const ticketCenterHref = (typeof prefix === 'string')
    ? '/ticket-center-choice'
    : prefix + '/ticket-center';

  state = {
    ...state,
    href: window.location.href,
    ticketCenterHref,
  };

  $Headbar.help.display(state);
};

$Headbar.help.display = function(state){
  $(state.container).prepend(state.Templates["headbar.help"](state));
};
