/**
 * When the server starts, a configuration file is generated at "public/js/inject-generated-configuration.js".
 * It contains all the options needed for the client side of the console.
 * This file does not exist at build time because the server is not started yet.
 * This means our JS modules cannot directly require/import "public/js/inject-generated-configuration.js" to retrieve the configuration, the build would fail.
 *
 * To get around this problem:
 * - the generated "public/js/inject-generated-configuration.js" file exposes the configuration object on `window.__CONSOLE_CONFIGURATION__`.
 * - the module "src/configuration.js", always available at build time exposes `window.__CONSOLE_CONFIGURATION__`
 *
 * This way, JS modules who need configuration values can directly require/import "src/configuration.js".
 * The build will work but the actual configuration values will be generated when the server starts.
 */
const generatedConfig = window.__CONSOLE_CONFIGURATION__;

/**
 * The configuration can be overridden at runtime in the console by going to "/config.html".
 * Overridden values are stored in the local storage.
 */
const localConfiguration = JSON.parse(localStorage.getItem('CONSOLE_CONFIGURATION') ?? '{}');

module.exports = {
  ...generatedConfig,
  ...localConfiguration,
};
