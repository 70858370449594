module.exports = (() => {
  let Bacon = require('baconjs');
  let $ = require('jquery');
  let _ = require('lodash');
  let Yajas = require('yajas');
  let path4js = require('path4js');

  let Templates = require('../../../generated/templates.js');
  let T = require('../../models/technical/translation.js');

  // keyCodes for navigation into search
  // LEFT / UP / RIGHT / DOWN
  const searchNavigationCodes = [9, 37, 38, 39, 40];
  const legacyAddons = ["mongo_", "couch_", "postgresql_", "mysql_"];

  let QuickSearch = (config) => {
    let state = {
      $container: config.$container,
      s_summary: config.s_summary.map(QuickSearch.orgasAsArray),
      s_request: config.s_request,
      keypress: {},
      initialSearch: config.initialSearch
    };

    state.$container.html(Templates["quick-search"]({initialSearch: state.initialSearch}));
    if(state.initialSearch){
      QuickSearch.setCursorAtEnd(state);
    }
    QuickSearch.search(state);
    return state;
  };

  QuickSearch.search = (state) => {
    let b_stop = new Bacon.Bus();
    let s_firstStop = b_stop.first();
    s_firstStop.onValue(() => QuickSearch.close(state));

    let s_inputKeyDown = QuickSearch
      .listenKeyUp(state)
      .takeUntil(s_firstStop);

    let s_search = s_inputKeyDown
      .map(e => e.target.value.trim().toLowerCase())
      .toProperty(state.initialSearch && state.initialSearch.toLowerCase())
      .filter(val => val && val.length > 0)
      .skipDuplicates(_.isEqual);

    let s_results = Bacon
      .combineWith(s_search, state.s_summary.first(), state.s_request.first(), QuickSearch.sortResults)
      .takeUntil(s_firstStop);

    let s_concat = s_results.map(QuickSearch.concatResults);

    s_results.onValue(results => QuickSearch.displayResults(state, results, b_stop));

    let s_keypress = QuickSearch.listenKeyPress()
      .takeUntil(s_firstStop);

    s_keypress
      .filter(e => searchNavigationCodes.indexOf(e.keyCode) > -1)
      .flatMapLatest(e => {
        return s_concat
          .first()
          .map(results => {
            return {
              e: e,
              results: results
            };
          });
      })
      .onValue(obj => QuickSearch.navigate(state, obj.results, obj.e));

    let s_enter = s_keypress.filter(e => e.keyCode === 13);

    s_enter
      .flatMapLatest(() => s_concat.first())
      .takeUntil(s_firstStop)
      .onValue(results => {
        if(QuickSearch.jumpTo(state, results)) {
          b_stop.push(null);
        }
      });

    let s_inputKeyDownEsc = s_inputKeyDown
      .filter(e => e.keyCode === 27);

    let s_esc = Bacon
      .fromEvent(document, 'keydown')
      .filter(e => e.keyCode === 27)
      .takeUntil(s_firstStop)
      .first();

    let s_quit = Bacon
      .mergeAll(s_inputKeyDownEsc, s_esc)
      .takeUntil(s_firstStop)
      .first();

    s_esc.onValue(() => b_stop.push(null));

    let s_outtaClick = $(document)
      .asEventStream('click')
      .takeUntil(s_firstStop)
      .filter(e => {
        return e.target &&
          e.target.className &&
          typeof e.target.className === "string" &&
          e.target.className.indexOf('quick-search-container') > -1;
      })
      .first()
      .onValue(() => b_stop.push(null));
  };

  QuickSearch.sortResults = (val, summary, request) => {
    let currentOrga = request.params.oid ?
      summary.originalOrganisations[request.params.oid] :
      summary.user;

    let owners = QuickSearch.buildUrls([].concat([summary.user]).concat(summary.organisations));
    let inCurrentOrga = QuickSearch.buildUrls([].concat(currentOrga.applications).concat(currentOrga.addons), currentOrga.id);
    let anywhere = _.reduce(owners, (arr, owner) => {
      if(owner.id !== currentOrga.id){
        return arr.concat(QuickSearch.buildUrls(owner.applications, owner.id)).concat(QuickSearch.buildUrls(owner.addons, owner.id));
      }

      return arr;
    }, []);

    let orgasSearch = _.filter(owners, _.partial(QuickSearch.compareAttributes, val));
    let anywhereSearch = _.filter(anywhere, _.partial(QuickSearch.compareAttributes, val));
    let inCurrentOrgaSearch = _.filter(inCurrentOrga, _.partial(QuickSearch.compareAttributes, val));

    let sortItems = (obj) => obj.name || obj.id;

    let sortedOrgasSearch = _.sortBy(orgasSearch, sortItems);
    let sortedAnywhereSearch = _.sortBy(anywhereSearch, sortItems);
    let sortedInCurrentOrgasSearch = _.sortBy(inCurrentOrgaSearch, sortItems);

    return {
      currentOrga: sortedInCurrentOrgasSearch,
      orgas: sortedOrgasSearch,
      others: sortedAnywhereSearch,
      request: request
    };
  };

  QuickSearch.compareAttributes = (val, obj) => {
    try{
      // Because we can use regex to search !
      return (obj.name && obj.name.toLowerCase().match(val)) || (obj.id && obj.id.match(val));
    } catch(e){
      return null;
    }
  };

  QuickSearch.orgasAsArray = (summary) => {
    let orgas = _.reduce(summary.organisations, (orgas, orga) => {
      return orgas.concat([orga]);
    }, []);

    return {
      user: summary.user,
      organisations: orgas,
      originalOrganisations: summary.organisations
    };
  };

  QuickSearch.buildUrls = (items, ownerId) => {
    let isUser = ownerId && ownerId.indexOf('user_') === 0;
    return _.map(items, item => {
      let path;
      if(item.id.indexOf('app_') === 0){
        if(_.has(item, 'providerId')){
          path = "addons"; // FS BUCKET for PHP FTP ap
        } else{
          path = "applications";
        }
      } else if(item.id.indexOf('addon_') === 0 || _.find(legacyAddons, l => item.id.indexOf(l) === 0)){
        path = "addons";
      }

      let url;

      // If it's not an organisation but an app / addon
      if(path){
        url = isUser ?
          '/users/me/' + path + '/' + item.id :
          '/organisations/' + ownerId + '/' + path + '/' + item.id;
      } else{
        url = item.id.indexOf('orga_') === 0 ?
          '/organisations/' + item.id :
          '/users/me';
      }

      return _.extend({}, item, {
        url: url
      });
    });
  };

  QuickSearch.displayResults = (state, results, b_stop) => {
    let $container = state.$container.find('.results').empty().css('display', 'flex');
    let displayCurrentOrga = results.currentOrga.length > 0;

    if(displayCurrentOrga){
      $container.append(Templates["quick-search-results"]({results: results.currentOrga, title: T("console.quick-search.current-organisation")}));
    }

    let orgasTitle = results.orgas.length > 0 ?
      T("console.quick-search.organisations") :
      T("console.quick-search.no-organisations");

    let othersTitle = results.others.length > 0 ?
      T("console.quick-search.others") :
      T("console.quick-search.no-others");

    $container.append(Templates["quick-search-results"]({results: results.orgas, title: orgasTitle}));
    $container.append(Templates["quick-search-results"]({results: results.others, title: othersTitle}));

    let firstElem;
    if(displayCurrentOrga){
      firstElem = _.head(results.currentOrga) || {};
    } else if(results.orgas.length > 0){
      firstElem = _.head(results.orgas);
    } else if(results.others.length > 0){
      firstElem = _.head(results.others);
    } else{
      firstElem = {};
    }

    let $elem = $container.find('li[data-id="' + firstElem.id + firstElem.name + '"]');
    $elem.addClass('active');

    state.$container
      .find('li[data-id]')
      .click(e => {
        $(e.target).find('a').click();
      });

    state.$container
      .find('li[data-id]')
      .mouseenter(e => {
        let $originalActive = state.$container.find('li[data-id].active');
        let $newActive = $(e.target);
        let newActive = $newActive.get(0);
        if(newActive && newActive.nodeName !== "LI") {
          $newActive = $newActive.parents('li');
        }
        $newActive.addClass('active');

        $originalActive.removeClass('active');
        $newActive
          .addClass('active')
          .one('mouseleave', e => {
            $newActive.removeClass('active');
            $originalActive.addClass('active');
          });
      });

    state.$container
      .find('li[data-id] a')
      .click(() => b_stop.push(null));
  };

  QuickSearch.listenKeyPress = () => {
    return $(document)
      .off('keydown')
      .asEventStream('keydown')
      .filter(e => { // NAVIGATION / ENTER
        let k = e.keyCode;
        return searchNavigationCodes.indexOf(k) > -1 || k === 13;
      })
      .doAction('.preventDefault');
  };

  QuickSearch.navigate = (state, elements, event) => {
    let $active = state.$container.find('li.active');
    let $columns = state.$container.find('.quick-search-results');
    let k = event.keyCode;

    let $nextElem;
    if($active.length === 0){
      if(k === 38){
        $nextElem = QuickSearch.navigateUp(state, $active, elements);
      } else if(k === 40){
        $nextElem = QuickSearch.navigateDown(state, $active, elements);
      } else if(k === 39 || k === 9){
        $nextElem = QuickSearch.navigateRight(state, $active, $columns);
      } else if(k === 37){
        $nextElem = QuickSearch.navigateLeft(state, $active, $columns);
      }
    } else{
      let currentId = $active.attr('data-id');
      let currentRealId = $active.attr('data-real-id');
      let isAddon = $active.attr('data-is-addon');

      let index = _.findIndex(elements, elem => {
        if(elem.id === currentRealId){
          if(isAddon === "true"){
            return _.has(elem, "providerId");
          } else{
            return true;
          }
        } else{
          return false;
        }
      });

      let currentColumn = _.find($columns, column => {
        return $(column).find('li[data-id="' + currentId + '"].active').length > 0;
      });
      let $currentUl = $(currentColumn).find('ul');

      let nextIndex;

      if(k === 38){ // UP
        $nextElem = QuickSearch.navigateUp(state, $active, elements, currentId, index);
      } else if(k === 40){ // DOWN
        $nextElem = QuickSearch.navigateDown(state, $active, elements, currentId, index);
      } else if(k === 39 || k === 9){ // RIGHT
        $nextElem = QuickSearch.navigateRight(state, $active, $columns, elements, currentId, index, currentColumn);
      } else if(k === 37){ // LEFT
        $nextElem = QuickSearch.navigateLeft(state, $active, $columns, elements, currentId, index, currentColumn);
      }

      // We also want to adjust the scrollbar when user do a right / left jump
      if(k !== 40){
        let nextTop = $nextElem.offset().top;
        let $nextUl = $nextElem.parents('ul');
        let currentOffset = $nextUl.offset().top;
        let nextOffset = $nextUl.scrollTop() + (nextTop - currentOffset);
        if(nextTop - currentOffset < 0){
          $nextUl.scrollTop(nextOffset);
        }
      }

      if(k !== 38){
        let $nextUl = $nextElem.parents('ul');
        let nextBottom = $nextElem.offset().top + $nextElem.outerHeight(false);
        let currentOffset = $nextUl.offset().top + $nextUl.outerHeight(false);
        let nextOffset = $nextUl.scrollTop() + nextBottom - currentOffset;
        $nextUl.scrollTop(nextOffset);
      }
    }

    state.$container.find('li.active').removeClass('active');
    $nextElem.addClass('active');
  };

  QuickSearch.navigateUp = (state, $active, elements, currentId, index) => {
    let nextId;
    if($active.length === 0){
      let lastElem = _.last(elements);
      nextId = lastElem.id + lastElem.name;
    } else{
      let nextIndex;
      if((index - 1) < 0){
        nextIndex = elements.length - 1;
      } else{
        nextIndex = index - 1;
      }

      let newElem = elements[nextIndex];
      nextId = newElem.id + newElem.name;
    }

    return state.$container.find('li[data-id="' + nextId + '"]');
  };

  QuickSearch.navigateDown = (state, $active, elements, currentId, index) => {
    let nextId;
    if($active.length === 0){
      let firstElem = _.head(elements);
      nextId = firstElem.id + firstElem.name;
    } else{
      let nextIndex;
      if((index + 1) >= elements.length){
        nextIndex = 0;
      } else{
        nextIndex = index + 1;
      }

      let newElem = elements[nextIndex];
      nextId = newElem.id + newElem.name;
    }

    return state.$container.find('li[data-id="' + nextId + '"]');
  };

  QuickSearch.navigateRight = (state, $active, $columns, elements, currentId, index, currentColumn) => {
    let $currentColumn = $(currentColumn);
    let $nextElem;

    if($active.length === 0){
      $nextElem = $($columns.get(1)).find('li[data-id]:first-child');
    } else{
      let $nextColumn = QuickSearch.getNextNonEmptyColumn($columns, $currentColumn);
      $nextElem = QuickSearch.horizontalNavigate($currentColumn, $nextColumn);
    }

    return $nextElem;
  };

  QuickSearch.navigateLeft = (state, $active, $columns, elements, currentId, index, currentColumn) => {
    let $currentColumn = $(currentColumn);
    let $nextElem;
    let columnsLength = $columns.length - 1;

    if($active.length === 0){
      $nextElem = $($columns.get(columnsLength)).find('li[data-id]:first-child');
    } else{
      let $nextColumn = QuickSearch.getPreviousNonEmptyColumn($columns, $currentColumn);
      $nextElem = QuickSearch.horizontalNavigate($currentColumn, $nextColumn);
    }

    return $nextElem;
  };

  QuickSearch.getNextNonEmptyColumn = ($columns, $currentColumn) => {
    let $nextColumn;
    if($currentColumn.is(':last-child')){
      $nextColumn = $($columns.get(0));
    } else{
      $nextColumn = $currentColumn.next();
    }

    return $nextColumn.find('li[data-id]').length > 0 ? $nextColumn : QuickSearch.getNextNonEmptyColumn($columns, $nextColumn);
  };

  QuickSearch.getPreviousNonEmptyColumn = ($columns, $currentColumn) => {
    let $nextColumn;
    if($currentColumn.is(':first-child')){
      $nextColumn = $($columns.get($columns.length - 1));
    } else{
      $nextColumn = $currentColumn.prev();
    }

    return $nextColumn.find('li[data-id]').length > 0 ? $nextColumn : QuickSearch.getPreviousNonEmptyColumn($columns, $nextColumn);
  };

  QuickSearch.horizontalNavigate = ($currentColumn, $nextColumn) => {
    let $nextElem;
    let currentPositionInList = _.findIndex($currentColumn.find('li[data-id]'), li => {
      return $(li).hasClass('active');
    });
    let $nextColumnLis = $nextColumn.find('li[data-id]');

    let nextColumnElementsLength = $nextColumnLis.length;

    if(currentPositionInList >= nextColumnElementsLength){
      $nextElem = $($nextColumnLis.get(nextColumnElementsLength - 1));
    } else{
      $nextElem = $($nextColumnLis.get(currentPositionInList));
    }

    return $nextElem;
  };

  QuickSearch.jumpTo = (state, elements) => {
    let $node = state.$container.find('li.active');

    if($node.length > 0){
      let nodeId = $node.attr('data-real-id');
      let isAddon = $node.attr('data-is-addon');
      let url = _.find(elements, elem => {
        if(elem.id === nodeId){
          if(isAddon === "true"){
            return _.has(elem, 'providerId');
          } else{
            return true;
          }
        } else{
          return false;
        }
      }).url;

      Yajas.path4js.launchPath(path4js.Request.fromUri(url));
      return true;
    } else {
      return false;
    }
  };

  QuickSearch.concatResults = (results) => {
    return [].concat(results.currentOrga).concat(results.orgas).concat(results.others);
  };

  QuickSearch.close = (state) => {
    $(document).off('keydown');
    state.$container.find('input').off('keyup');
    state.$container.hide();
    state.$container.find('.results').empty().hide();
    state.$container.find('input').val(null);
  };

  QuickSearch.listenKeyUp = (state) => {
    return state.$container
      .show()
      .find('input')
      .focus()
      .asEventStream('keyup');
  };

  QuickSearch.setCursorAtEnd = (state) => {
    const input = state.$container.find('input.search').get(0);
    input.selectionStart = input.selectionEnd = input.value.length;
  };

  return QuickSearch;
})();
