import Bacon from 'baconjs';
import { API_HOST } from '../configuration.js';
import { get as getAddon } from '@clevercloud/client/esm/api/v2/addon.js';
import {
  getAccessLogsFromWarp10,
  getRequestsFromWarp10,
  getToken,
} from './access-logs.js';
import { getAddonProvider, getAllAddonProviders, getCreditPrice } from '@clevercloud/client/esm/api/v2/product.js';
import { getTokens, sendToApi } from '../send-to-api.js';
import { CcZone } from '@clevercloud/components/dist/cc-zone.js';

// TODO: move to clever-client
const instanceState = require('../helpers/instance-state.js');

// TODO: move to clever-client
export function getDefaultLoadBalancer (ownerId, appId) {
  return Promise.resolve({
    method: 'get',
    url: `/v4/load-balancers/organisations/${ownerId}/applications/${appId}/load-balancers/default`,
    headers: { Accept: 'application/json' },
  });
}

export async function fetchHeatmapPoints (ownerId, appId) {
  const oauthTokens = getTokens();
  const warpToken = await getToken({ orgaId: ownerId }, API_HOST, oauthTokens);
  return getAccessLogsFromWarp10({ ownerId, appId, durationHours: 24, warpToken });
}

export async function fetchRequests (ownerId, appId) {
  const oauthTokens = getTokens();
  const warpToken = await getToken({ orgaId: ownerId }, API_HOST, oauthTokens);
  return getRequestsFromWarp10({ ownerId, appId, durationHours: 1, warpToken });
}

// We use SummaryProxy to retrieve a stream for the app.
// Because the summary does not contain instance details (for scalability) at first load,
// we load app details from API if `app.instance` is missing.
export function getAppStream (ownerId, appId) {
  return Console.SummaryProxy.fetchOrga(ownerId)
    .flatMapLatest((owner) => {
      const app = owner.applications.find((app) => app.id === appId);
      return (app != null)
        ? Bacon.once(app)
        : Bacon.once(new Bacon.Error(new Error('Application not found')));
    })
    .skipDuplicates(_.isEqual)
    .flatMapLatest((app) => {
      // If instance is not defined on app,
      // it means it comes from the initial summary.
      // To retrieve and cache scalability info,
      // we force un update on the whole orga.
      if (app.instance == null) {
        SummaryProxy.updateApplications(ownerId);
      }
      return app;
    })
    .toProperty();
}

export function getAddonStream (ownerId, addonId) {
  return Console.SummaryProxy.fetchOrga(ownerId)
    .flatMapLatest((owner) => {
      const addon = owner.addons.find((addon) => addon.id === addonId);
      return (addon != null)
        ? Bacon.once(addon)
        : Bacon.fromPromise(getAddon({ id: ownerId, addonId }).then(sendToApi));
    })
    .skipDuplicates(_.isEqual)
    .flatMapLatest((addon) => {
      if (addon.provider == null) {
        const p_addon = getAddon({ id: ownerId, addonId }).then(sendToApi);
        return Bacon.fromPromise(p_addon);
      }
      return addon;
    })
    .toProperty();
}

export function getDeploymentsStream (ownerId, appId) {
  return Console.DeploymentProxy.fetch(ownerId, appId).toProperty();
}

export function getInstancesStream (ownerId, appId) {
  return Console.InstanceProxy.fetchAll(appId, ownerId).toProperty();
}

export function getAppInfos (ownerId, rawApp) {

  const app = {
    name: rawApp.name,
    lastDeploymentLogsUrl: getLogsUrl(ownerId, rawApp.id),
  };

  // If instance is not defined on app,
  // it means it comes from the initial summary
  return (rawApp.instance == null)
    ? {
      ...app,
      commit: rawApp.commit,
      variantName: rawApp.instanceVariant,
      variantLogo: rawApp.variantLogoUrl,
    }
    : {
      ...app,
      commit: rawApp.commitId,
      variantName: rawApp.instance.variant.name,
      variantLogo: rawApp.instance.variant.logo,
    };
}

export function getRunningCommit (status, rawInstances) {
  return (status === 'stopped' || status === 'start-failed')
    ? null
    : rawInstances
      .filter((i) => i.state === 'UP')
      .reduce((a, b) => b.commit, null);
}

export function getStartingCommit (status, rawInstances) {
  return (status === 'stopped' || status === 'start-failed' || status === 'running' || status === 'restart-failed')
    ? null
    : rawInstances
      .filter((i) => instanceState.deploying(i))
      .reduce((a, b) => b.commit, null);
}

export function formatDeployments (deployments, ownerId, appId) {
  return deployments
    .filter(({ action }) => (action !== 'UNDEPLOY'))
    .filter(({ action }) => (action !== 'DOWNSCALE'))
    .filter(({ action, state }) => (action !== 'CANCEL' && state !== 'WIP'))
    .map(({ state, action, date: ts, uuid }) => {
      const date = new Date(ts).toISOString();
      const logsUrl = getLogsUrl(ownerId, appId, uuid);
      return { state, action, date, logsUrl };
    })
    .slice(0, 3);
}

export function formatInstances (status, rawInstances) {
  const runningInstances = (status === 'stopped' || status === 'start-failed')
    ? []
    : _(rawInstances)
      .filter((i) => i.state === 'UP')
      .map((i) => ({ flavorName: i.flavor.name }))
      .countBy('flavorName')
      .map((count, flavorName) => ({ flavorName, count }))
      .value();

  const deployingInstances = (status === 'stopped' || status === 'start-failed' || status === 'running' || status === 'restart-failed')
    ? []
    : _(rawInstances)
      .filter((i) => instanceState.deploying(i))
      .map((i) => ({ flavorName: i.flavor.name }))
      .countBy('flavorName')
      .map((count, flavorName) => ({ flavorName, count }))
      .value();

  return { running: runningInstances, deploying: deployingInstances };
}

// TODO: some cache here, please
let EURO_CREDIT_PRICE_CACHE;

export function fetchEuroCreditPrice () {
  if (EURO_CREDIT_PRICE_CACHE != null) {
    return Promise.resolve(EURO_CREDIT_PRICE_CACHE);
  }
  return getCreditPrice().then(sendToApi)
    .then((prices) => {
      const price = prices.find((p) => p.currency === 'EUR');
      if (price == null) {
        // TODO: what do we do?
        throw new Error('');
      }
      EURO_CREDIT_PRICE_CACHE = price.value;
      return price.value;
    });
}

function getLogsUrl (ownerId, appId, deploymentUuid) {
  const ownerHref = (ownerId == null || ownerId.startsWith('user_'))
    ? '/users/me'
    : '/organisations/' + ownerId;
  return (deploymentUuid != null)
    ? `${ownerHref}/applications/${appId}/logs?deploymentId=${deploymentUuid}`
    : `${ownerHref}/applications/${appId}/logs`;
}

export function getEsOptionsFlavors () {
  return Promise.resolve({
    method: 'get',
    url: `/v2/providers/es-addon/tmp/services-flavors`,
    headers: { Accept: 'application/json' },
    // no query params
    // no body
  });
}

export function getMonthlyPrice (credits, instancesCount = 1) {
  return fetchEuroCreditPrice().then((creditInEuros) => {
    return 30 * 24 * 6 * credits * creditInEuros * instancesCount;
  });
}

export function filterZones (zones, isMachineLearning) {
  return zones
    .filter((z) => {
      return isMachineLearning
        ? z.tags.includes('for:applications-ml')
        : z.tags.includes('for:applications');
    })
    .map((z) => getZoneWithText(z));
}

export function cleanZoneTags (zone) {
  const tags = zone.tags.filter((t) => !t.startsWith('for:'));
  return { ...zone, tags };
}

export function getZoneWithText (zone) {
  return { ...zone, text: CcZone.getText(zone) };
}

export function isMachineLearning (instanceType) {
  return instanceType.toLowerCase().startsWith('ml_');
}

export function getAddonZones (provider, allZones, plan) {
  return plan.zones
    .map((zoneName) => allZones.find(({ name }) => name === zoneName))
    .filter((z) => z != null);
}

function filterProviderPlans (addonProvider) {
  const plans = addonProvider.plans.filter((plan) => {
    return plan.zones.length > 0;
  });
  return { ...addonProvider, plans };
}

export function getAllAddonProvidersFiltered (orgaId) {
  return getAllAddonProviders({ orgaId })
    .then(sendToApi)
    .then((addonProviderList) => {
      // Remove plans that don't have any zones
      return addonProviderList.map((addonProvider) => filterProviderPlans(addonProvider));
    })
}

export function getAddonProviderFiltered (providerId, orgaId) {
  return getAddonProvider({ provider_id: providerId, orgaId })
    .then(sendToApi)
    .then((addonProvider) => filterProviderPlans(addonProvider));
}

export function getMateriaKvInfo ({ ownerId, id }) {
  return Promise.resolve({
    method: 'get',
    url: `/v4/materia/organisations/${ownerId}/materia/databases/${id}`,
    headers: { Accept: 'application/json' },
    // no query params
    // no body
  });
}
