module.exports = (() => {
  const Bacon = require("baconjs");
  const _ = require("lodash");

  const DomEvents = {};

  DomEvents.getEvents = () => {
    return [
      "transitionstart",
      "transitioncancel",
      "transitionend",
      "transitionrun",
      "resize"
    ];
  };

  DomEvents.listenForEvents = () => {
    return _.reduce(DomEvents.getEvents(), (events, event) => {
      if(window[`on${event}`] === undefined){
        const error = new Error(`Event type ${event} is not supported on this browser`);
        events[event] = Bacon.once(new Bacon.Error(error));
      } else {
        events[event] = Bacon.fromEvent(window, event);
      }

      return events;
    }, {});
  };

  return DomEvents;
})();
