module.exports = (function() {
  var NumberFormat = require('../helpers/numberformat.js');
  var T = require('../models/technical/translation.js');
  var $Pricer = require('../modules/pricer/view.js');
  var Templates = require('../../generated/templates.js');

  var sp = new (require("./AbstractSP.js"))({
    name: "AppConfigurationSP",
    title: "APP_CONFIGURATION"
  });

  var orgaId;
  var appId;

  sp.getStreams = function(req){
    orgaId = req.params.oid;
    appId = req.params.appId;

    var s_price = sp.getPrice();

    var s_app = sp.getApp();

    var s_instances = s_app.flatMapLatest(function(app){
      var s_fromId = !orgaId ? SummaryProxy.fetchUserOnce().map(function(user){ return user.id; }) : Bacon.once(orgaId);
      return s_fromId.flatMapLatest(function(fromId){
        var query = {
          app: app.id,
          for: fromId
        };
        return API.products.instances['_-_'].get().withParams([app.instance.type, app.instance.version]).withQuery(query).send();
      });
    });

    return {
      s_price: s_price,
      s_app: s_app,
      s_instances: s_instances
    };
  };

  sp.on('onload', async function(req, $container, streams) {

    await import(/* webpackChunkName: "jquery-ui.lazy" */ 'jquery-ui');

    Bacon.onValues(streams.s_price, streams.s_app, _.partial(sp.displayCurrentConsumption, $container));
    Bacon.onValues(streams.s_price, streams.s_app, streams.s_instances, _.partial(sp.displayConfiguration, $container));

    streams.s_app.onValue(_.partial(sp.displayCurrentConfiguration, $container));
  });

  sp.getApp = function(){
    return SummaryProxy.fetchOrgaOnce(orgaId).flatMapLatest(function(owner) {
      var app = _.find(owner.applications, function(app) {
        return app.id === appId;
      });
      if(app){
        return orgaId ?
          API.organisations._.applications._.get().withParams([orgaId, appId]).send() :
          API.self.applications._.get().withParams([appId]).send();
      } else{
        return Bacon.once(new Bacon.Error({message: T("APPLICATION_NOT_FOUND")}));
      }
    }).toProperty();
  };

  sp.getPrice = function(){
    return API.products.prices.get().send().map(function(prices) {
      return _.find(prices, function(price) {
        return price.currency === "EUR";
      });
    });
  };

  sp.displayCurrentConfiguration = function($container, app) {
    $container.find('.current-configuration').html(Templates['AppConfigurationSP.current-configuration'](app.instance));
  };

  sp.displayConfiguration = function($container, price, app, instances) {
    var $form = $container.find("form");

    var flavors = instances.flavors;
    var defaultFlavor = instances.defaultFlavor;
    var appInstance = _.extend({}, app.instance, {flavors: flavors});

    var autoscalability = app.instance.minInstances !== app.instance.maxInstances || app.instance.minFlavor.name !== app.instance.maxFlavor.name;

    var staticConfiguration = !autoscalability ? appInstance : {
      minInstances: 1,
      maxInstances: 1,
      minFlavor: defaultFlavor,
      maxFlavor: defaultFlavor
    };

    var dynamicConfiguration = autoscalability ? appInstance : {
      minInstances: 1,
      maxInstances: 4,
      minFlavor: defaultFlavor,
      maxFlavor: _.last(flavors)
    };

    var $pricer = $Pricer({
      selector: $form.find(".pricer"),
      instance: appInstance,
      price: price,
      configuration: {
        autoscalability: autoscalability,
        staticConfiguration: staticConfiguration,
        dynamicConfiguration: dynamicConfiguration
      }
    });

    $form.submit(function(e) {
      e.preventDefault();

      $pricer.configuration.take(1).onValue(function(conf) {
        var data = _.extend({id: app.id}, conf.autoscalability ? conf.dynamicConfiguration : conf.staticConfiguration);
        data.minFlavor = data.minFlavor.name;
        data.maxFlavor = data.maxFlavor.name;

        var s_appUpdate = orgaId ?
          API.organisations._.applications._.put().withParams([orgaId, appId]).send(JSON.stringify(data)) :
          API.self.applications._.put().withParams([appId]).send(JSON.stringify(data));

        var s_app = s_appUpdate.flatMapLatest(function(app) {
          return SummaryProxy.updateApplications(orgaId).map(app);
        });

        s_app.delay(1000).onValue(function(newApp){
          $Notification.displaySuccess({message: T("APP_CONFIGURATION_HAS_BEEN_SAVED")});
          sp.displayCurrentConfiguration($container, newApp);
        });

        var s_newApp = s_app.flatMapLatest(function(){
          return sp.getApp();
        });

        var s_price = s_app.flatMapLatest(function(){
          return sp.getPrice();
        });

        Bacon.onValues(s_price, s_newApp, _.partial(sp.displayCurrentConsumption, $container));

        s_app.filter(function(newApp){
          return !_.isEqual(app.instance.minFlavor, newApp.instance.minFlavor) || app.instance.minInstances !== newApp.instance.minInstances;
        }).flatMapLatest(function(newApp){
          var s_instances = orgaId ?
            API.organisations._.applications._.instances.get().withParams([orgaId, appId]).send() :
            API.self.applications._.instances.get().withParams([appId]).send();

          return s_instances.filter(function(instances){
            return instances.length > 0;
          }).map(newApp);
        }).onValue(function(newApp){
          $Notification.displaySuccess({message: T("console.app-configuration.app-redeploy")});
          app = _.clone(newApp);
        });

        s_app.onError($Notification.displayError);

        $form.find("[type='submit']").loadStream(s_app, 1000);
      });
    });
  };

  sp.displayCurrentConsumption = function($container, price, app) {
    var minimum = app.instance.minFlavor.price * app.instance.minInstances * price.value * 6 * 24; // Drops/10min -> €/day
    var maximum = app.instance.maxFlavor.price * app.instance.maxInstances * price.value * 6 * 24; // Drops/10min -> €/day

    $container.find('.minimum-consumption').text(NumberFormat.format(minimum, 2) + ' €/day');
    $container.find('.maximum-consumption').text(NumberFormat.format(maximum, 2) + ' €/day');
  };

  return sp;
})();
