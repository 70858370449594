module.exports = (function(){
  let path4js = require("path4js");
  let Yajas = require("yajas");

  let $OauthConsumerCreation = require('../modules/oauth-consumer-creation/main.es6.js');
  let Templates = require('../../generated/templates.js');

  let sp = new (require("./AbstractSP.js"))({
    name: 'OauthConsumerCreationSP'
  });

  let orgaId;

  sp.on('onload', function(req, $container){
    orgaId = req.params.oid || null;

    let s_create = $OauthConsumerCreation({
      $container: $container.find('.create-oauth-consumer'),
      orgaId: orgaId,

      API: API,
      Templates: Templates,
      SummaryProxy: SummaryProxy
    });

    s_create.onValue(consumer => {
      Yajas.path4js.launchPath(path4js.Request.fromUri((orgaId ? "/organisations/" + orgaId : "/users/me") + "/oauth-consumers/" + consumer.key));
    });

    s_create.onError($Notification.displayError);
  });

  return sp;
})();
