module.exports = (function() {

  var sp = new (require("./AbstractSP.js"))({
    name: 'AddonProviderSP'
  });

  var orgaId;
  var providerId;

  sp.getStreams = function(req){
    orgaId = req.params.oid;
    providerId = req.params.providerId;

    var s_provider = API.organisations._.addonproviders._.get().withParams([orgaId, providerId]).send();

    return {
      s_provider: s_provider
    };
  };

  sp.on('onload', function(req, $container, streams) {
    streams.s_provider.onValue(_.partial(sp.displayInformation, $container));
    streams.s_provider.onError($Notification.displayError);
  });

  sp.displayInformation = function($container, provider, error) {
    var $form = $container.find('form.provider').html(Templates['AddonProviderSP.form']({
      information: provider,
      error: error
    }));

    $form.unbind('submit').submit(function(e) {
      e.preventDefault();

      var body = JSON.stringify(_.extend({}, provider, {
        "name": this.name.value,
        "shortDesc": this.shortDesc.value,
        "longDesc": this.longDesc.value,
        "logoUrl": this.logoUrl.value,
        "supportEmail": this.supportEmail.value,
        "website": this.website.value,
        "twitterName": this.twitterName.value,
        "googlePlusName": this.googlePlusName.value,
        "analyticsId": this.analyticsId.value
      }));

      var s_provider = API.organisations._.addonproviders._.put().withParams([orgaId, provider.id]).send(body).map(function(){
        return SummaryProxy.updateProviders(orgaId);
      });

      s_provider.onValue(function(){
        $Notification.displaySuccess({message: T("console.addon-provider-updated")});
      });
      s_provider.onError(function(error){
        sp.displayInformation($container, body, error);
      });

      $form.find("[type='submit']").loadStream(s_provider, 1000);
    });
  };

  return sp;
})();
