// WARNING: We won't be able to transform this into ESM as long as we have T('key') in our templates

const { DEFAULT_LANGUAGE } = require('../../configuration.js');

// Translations coming from the translation API
const APP_TRANSLATIONS_MODULES = {
  EN: () => import(/* webpackChunkName: "app.en.lang" */ '../../../generated/translations.en.js'),
  FR: () => import(/* webpackChunkName: "app.fr.lang" */ '../../../generated/translations.fr.js'),
};

// Translations coming from Clever Components
const { addTranslations, setLanguage } = require('@clevercloud/components/dist/i18n.js');
const COMPONENTS_TRANSLATIONS_MODULES = {
  EN: () => import(/* webpackChunkName: "components.en.lang" */ '@clevercloud/components/dist/translations.en.js'),
  FR: () => import(/* webpackChunkName: "components.fr.lang" */ '@clevercloud/components/dist/translations.fr.js'),
};

let currentLang;
const appTranslations = {};

/**
 *
 * @param {Object|String} keyStrOrObj
 * @param {String} keyStrOrObj.id
 * @param {String} keyStrOrObj.message
 * @param {Object?} data
 * @param {Boolean?} ignoreMissingTranslation
 * @returns {String}
 */
function i18n (keyStrOrObj, data, ignoreMissingTranslation) {

  const key = (keyStrOrObj.id != null) ? 'CCAPI_MESSAGES_' + keyStrOrObj.id : keyStrOrObj;
  const translation = getTranslation(key);

  if (translation != null) {
    return (typeof translation === 'function')
      ? translation(data)
      : translation;
  }

  if (keyStrOrObj.message != null) {
    return keyStrOrObj.message;
  }

  if (!ignoreMissingTranslation) {
    reportMissingTranslation(keyStrOrObj);
  }

  return 'Unknown translation';
};

function getTranslation (key) {
  try {
    return appTranslations[currentLang][key];
  }
  catch (e) {
    return null;
  }
}

function getLanguage (userLanguage) {

  // If we're not logged as admin, try to use the user's settings
  if (!LoginAs.isLoggedAsAdmin()) {

    // If we have the server side user setting, we use it
    if (userLanguage != null) {
      return userLanguage;
    }

    // If we have a cached (local storage) user setting, we use it
    const languageFromLocalStorage = localStorage.getItem('DEFAULT_LANGUAGE');
    if (languageFromLocalStorage != null) {
      return languageFromLocalStorage;
    }
  }

  // All other cases default to configured option
  return DEFAULT_LANGUAGE.toUpperCase();
}

i18n.setLanguageTranslator = async function (userLanguage, reloadPage = false) {

  const language = getLanguage(userLanguage);

  if (currentLang === language) {
    return;
  }

  currentLang = language;

  // Don't save setting in local storage if we're logged as admin
  if (!LoginAs.isLoggedAsAdmin()) {
    localStorage.setItem('DEFAULT_LANGUAGE', language);
  }

  if (reloadPage) {
    window.location.reload();
    return;
  }

  await Promise.all([
    APP_TRANSLATIONS_MODULES[language]().then(({ lang, translations }) => {
      appTranslations[lang] = translations;
    }),
    COMPONENTS_TRANSLATIONS_MODULES[language]().then(({ lang, translations }) => {
      addTranslations(lang, translations);
      setLanguage(lang);
    }),
  ]);
};

function reportMissingTranslation (key) {
  if (key.message != null) {
    const error = new Error('Got unknown translation message');
    console.error(error);
  }
  else {
    const error = new Error('Unknown translation for key: ' + key);
    console.error(error);
  }
};

i18n.getAvailableLanguages = function () {
  return {
    EN: 'English',
    FR: 'Français',
  };
};

i18n.getCurrentLanguage = function () {
  return currentLang;
};

module.exports = i18n;
