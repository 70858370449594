module.exports = (function() {
  let QrCode;
  var $ = require('jquery');
  var _ = require('lodash');
  var Yajas = require('yajas');
  var path4js = require('path4js');
  var utf8 = require('utf8');

  var $Modal = require('../modules/modals/main.js');
  var Templates = require('../../generated/templates.js');
  var Querystring = require('../helpers/querystring.js');

  var sp = new (require("./AbstractSP.js"))({
    name: 'UserSecuritySP'
  });

  var b_password = new Bacon.Bus();
  var s_password = b_password.toProperty(null);

  var timeout_id;

  sp.getStreams = function(req, $container){
    var s_user = API.self.get().send();

    return {
      s_user: s_user
    };
  };

  sp.on('onload', async function (req, $container, streams) {
    await import(/* webpackChunkName: "qrcodejs2" */ 'qrcodejs2').then((mod) => {
      QrCode = mod.default;
    });
    sp.displayPasswordChange($container);
    streams.s_user.onValue(_.partial(sp.displayMFAContainers, $container));
    sp.flushPasswords();
  });

  sp.displayPasswordChange = function($container) {
    var $form = $container.find('form.password');

    $form.submit(function(e) {
      e.preventDefault();

      if(this.newPassword.value === this.confirmedPassword.value) {
        var $button = $(this).find("[type='submit']");

        var s_res = API.self.change_password.put().send(JSON.stringify({
          oldPassword: this.oldPassword.value,
          newPassword: this.newPassword.value,
          dropTokens: this.revokeTokens.checked,
        }));
        s_res.onValue(function(message) {
          $Notification.displaySuccess(message);
          $form.get(0).reset();
        });
        s_res.onError($Notification.displayError);

        $button.loadStream(s_res);
      } else{
        $Notification.displayError({message: T("PASSWORDS_DONT_MATCH")});
      }
    });
  };

  sp.displayMFAContainers = function($container, user){
    $container.find('.register-mfa').hide();
    $container.find('.mfa-overview-container').show();

    var $activated = $container.find('.mfa-enabled');
    var $disabled = $container.find('.mfa-disabled');

    var s_isEnabled = Bacon.constant(user.preferredMFA === "NONE" ? false : true);

    s_isEnabled
      .filter(function(enabled){
        return !enabled;
      })
      .flatMapLatest(function(){
        return sp.enableMFA($container, user);
      })
      .flatMapLatest(API.self.get().send)
      .onValue(_.partial(sp.displayMFAContainers, $container));

    s_isEnabled
      .filter(function(enabled){
        return enabled;
      })
      .flatMapLatest(function(){
        return sp.manageMFA($container);
      })
      .flatMapLatest(API.self.get().send)
      .onValue(_.partial(sp.displayMFAContainers, $container));
  };

  sp.enableMFA = function($container, user){
    // Reset MFA register
    var $qrCode = $container.find('.qrcode').empty();
    var $noQrCode = $container.find('.no-qrcode').empty();
    var $input = $container.find('.pin-code').val('');

    var $overview = $container.find('.mfa-overview-container');
    var $registerMFA = $container.find('.register-mfa');

    $overview.find('.mfa-overview').html(Templates["UserSecuritySP.mfa-overview"]({
      mfaEnabled: false
    }));

    var $enable = $overview.find('.enable-mfa');
    var s_enable = $enable
      .asEventStream('click')
      .flatMapLatest(_.partial(sp.getUserPassword, s_password))
      .flatMapLatest(function(password){
        var request = API.self.mfa._.post().withParams(['totp']);
        var s_stream = sp.testUserPassword(request, password, b_password);

        $enable.loadStream(s_stream);
        return s_stream;
      });

    s_enable.onError($Notification.displayError);

    var s_pinCode = s_enable
      .skipErrors()
      .flatMapLatest(function(totp){
        $overview.hide();
        $registerMFA.show();

        var qs = Querystring.parse(_.last(totp.url.split("?")));

        var secret = qs.secret;
        var issuer = qs.issuer.replace(/\+/g, ' ');
        var email = user.email;
        var timeBased = totp.url.indexOf("totp") > -1 ? true : false;

        var qrCodeOptions = {
          text: totp.url,
          width: 150,
          height: 150
        };

        new QrCode($qrCode.get(0), qrCodeOptions);
        $qrCode.removeAttr('title');
        $noQrCode.html(Templates["UserSecuritySP.no-qrcode"]({
          secret: secret,
          issuer: issuer,
          email: email,
          timeBased: timeBased
        }));

        return $container
          .find('form.mfa-confirmation')
          .asEventStream('submit')
          .doAction('.preventDefault')
          .flatMapLatest(function(e){
            return sp.getUserPassword(s_password).map(function(password){
              return {
                password: password,
                button: $(e.currentTarget).find('button[type=submit]'),
                $form: $(e.currentTarget)
              };
            });
          });
      })
      .map(function(data){
        var code = data.$form
          .find('input.pin-code')
          .val()
          .trim();

        const revokeTokens = data.$form
          .find('input[name="revokeTokens"]')
          .is(':checked');

        return _.extend({}, data, {
          code: parseInt(code),
          revokeTokens,
        });
      });

    var s_confirmation = s_pinCode.flatMapLatest(function(data){
      var body = JSON.stringify({code: data.code, revokeTokens: data.revokeTokens});
      var request = API.self.mfa._.confirmation.post().withParams(['totp']);
      var s_confirm = sp.testUserPassword(request, data.password, b_password, body);

      data.button.loadStream(s_confirm);
      return s_confirm;
    });

    s_confirmation.onError(function(error){
      $container.find('input.pin-code').attr('data-status', 'error');
      $container.find('.pin-code-error').css('display', 'inline-block');
      $Notification.displayError(error);
    });

    return s_confirmation.skipErrors();
  };

  sp.manageMFA = function($container){
    var $overview = $container.find('.mfa-overview');
    $overview.html(Templates["UserSecuritySP.mfa-overview"]({
      mfaEnabled: true
    }));

    sp.backupCodes($overview);
    var s_disable = sp.disableMFA($overview);

    s_disable.onError($Notification.displayError);

    return s_disable.skipErrors();
  };

  sp.disableMFA = function($container){
    return $container
      .find('button.disable-mfa')
      .asEventStream('click')
      .flatMapLatest(function(e){
        return sp
          .getUserPassword(s_password)
          .map(function(password){
            return {
              button: $(e.currentTarget),
              password: password
            };
          });
      })
      .flatMapLatest(function(data){
        var request = API.self.mfa._.delete().withParams(['totp']);
        var s_delete = sp.testUserPassword(request, data.password, b_password);

        data.button.loadStream(s_delete);
        return s_delete;
      });
  };

  sp.getUserPassword = function(s_password){
    return s_password
      .first()
      .flatMapLatest(function(password){
        if(password){
          return Bacon.once(password);
        } else{
          return sp.askPassword(false);
        }
      });
  };

  sp.backupCodes = function($container){
    var s_backupCodes = $container
      .find('button.backup-codes')
      .asEventStream('click')
      .flatMapLatest(function(e){
        return sp
          .getUserPassword(s_password)
          .map(function(password){
            return {
              password: password,
              button: $(e.currentTarget)
            };
          });
      })
      .flatMapLatest(function(data){
        var request = API.self.mfa._.backupcodes.get().withParams(['totp']);
        var s_codes = sp.testUserPassword(request, data.password, b_password);

        data.button.loadStream(s_codes);
        return s_codes;
      })
      .map(function(codes){
        return _.chain(codes).map('code').chunk(5).value();
      });

    s_backupCodes.onValue(function(codes){
        var $modal = $Modal({
          type: 'information',
          title: T("console.user-security.print-backup-codes-modal-title"),
          body: Templates["modal.backup-codes"]({backupCodes: codes}),
          Templates: Templates
        });

        $modal.s_confirm.onValue(function(){
          $Modal.remove($modal);
        });
      });

    s_backupCodes.onError($Notification.displayError);

    return s_backupCodes;
  };

  sp.askPassword = function(wrongPassword){
    var s_user = API.self.get().send();

    return s_user
      .flatMapLatest(function(user){
        if(!user.hasPassword){
          return API.self.emails.get().send()
            .map(function(emails){
              return _.extend({}, user, {
                secondaryEmails: emails
              });
            })
            .flatMapLatest(function(user){
              return sp.githubUser(user);
            });
        } else{
          return Bacon.once();
        }
      })
      .flatMapLatest(function(){
        var $modal = $Modal({
          type: 'user-input',
          title: T("console.user-security.modal-title-enter-password"),
          // TODO: Form input submit toussa
          body: Templates["modal.mfa-password"]({
            wrongPassword: wrongPassword
          }),
          submitButtonText: T("console.user-security.modal-submit"),
          Templates: Templates
        });

        var s_password = $modal
          .s_confirm
          .map(function(e){
            return $modal.$modal.find('input[name="user-input"]').val().trim();
          });

        s_password.onValue(function(){
          $Modal.remove($modal);
        });

        return s_password;
      });
  };

  // We use this to test the user's password
  // displays the modal and re-executes the request
  // if password is wrong
  sp.testUserPassword = function(request, password, b_password, body){
    var s_request = request
      .withHeaders({
        'X-Clever-Password': btoa(utf8.encode(password)),
      })
      .send(body || null)
      .flatMapError(function(error){
        if(error.id === 2001){
          return sp.askPassword(true).flatMapLatest(function(password){
            // We push the password each and every time
            // Even if it's wrong, the good one will apear at some point
            if(b_password){
              b_password.push(password);
            }
            return sp.testUserPassword(request, password, b_password || undefined, body);
          });
        } else{
          return new Bacon.Error(error);
        }
      });

    if(b_password){
      b_password.push(password);
    }

    return s_request.first();
  };

  sp.flushPasswords = function(){
    s_password
      // Only filter actual password, not initial value or flush value
      .filter(function(password){ return password; })
      .onValue(function(){
        clearTimeout(timeout_id);
        timeout_id = setTimeout(function(){
          b_password.push(null);
        }, 60 * 1000); // Flush every minute
      });

    Console
      .s_requestUnload
      .first()
      .onValue(function(){
        clearTimeout(timeout_id);
        b_password.push(null);
      });
  };

  sp.githubUser = function(user){
    var $modalEmail = $Modal({
      type: 'information',
      title: T("console.user-security.set-password-modal-title"),
      body: T("console.user-security.set-password-modal", {email: user.email}),
      submitButtonText: T("SEND"),
      Templates: Templates
    });

    var s_resetPassword = $modalEmail
      .s_confirm
      .flatMapLatest(function(){
        var s_forgot = API.password_forgotten.post().withHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
          "Accept": "",
        }).send({login: user.email});

        $Modal.loadStream($modalEmail, s_forgot);

        return s_forgot;
      })
      .flatMapLatest(function(){
        $Modal.remove($modalEmail);
        var $modalSent = $Modal({
          type: 'information',
          title: T("console.user-security.password-request-sent-modal-title"),
          body: T("console.user-security.password-request-sent-modal", {email: user.email}),
          submitButtonText: T("console.user-security.modal-submit"),
          Templates: Templates
        });

        return $modalSent.s_confirm.map($modalSent);
      })
      .flatMapLatest(function($modalSent){
        return API.self.get().send().flatMapLatest(function(self){
          return self.hasPassword ? Bacon.once($modalSent) : sp.githubUser(self);
        });
      })
      .map($modalSent => {
        $Modal.remove($modalSent);
        return $modalSent;
      });

    s_resetPassword.onError($Notification.displayError);

    return s_resetPassword;
  };

  return sp;
})();
