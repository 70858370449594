export function getAppDocumentationInfos(type) {
  if (type === 'docker+docker') {
    return {
      name: 'Docker',
      link: `${Console.DOC_BASE_URL}doc/applications/docker/`,
    }
  }
  else if (type === 'dotnet+dotnet') {
    return {
      name: '.NET',
      link: `${Console.DOC_BASE_URL}doc/applications/dotnet/`,
    }
  }
  else if (type === 'erlang+elixir') {
    return {
      name: 'Elixir',
      link: `${Console.DOC_BASE_URL}doc/applications/elixir/`,
    }
  }
  else if (type === 'go+go') {
    return {
      name: 'Go',
      link: `${Console.DOC_BASE_URL}doc/applications/golang/`,
    }
  }
  else if (type === 'haskell+haskell') {
    return {
      name: 'Haskell',
      link: `${Console.DOC_BASE_URL}doc/applications/haskell/`,
    }
  }
  else if (type === 'java+gradle') {
    return {
      name: 'Gradle',
      link: `${Console.DOC_BASE_URL}doc/applications/java/java-gradle/`,
    }
  }
  else if (type === 'java+jar') {
    return {
      name: 'Jar',
      link: `${Console.DOC_BASE_URL}doc/applications/java/java-jar/`,
    }
  }
  else if (type === 'java+maven') {
    return {
      name: 'Maven',
      link: `${Console.DOC_BASE_URL}doc/applications/java/java-maven/`,
    }
  }
  else if (type === 'java+play1') {
    return {
      name: 'Play! 1',
      link: `${Console.DOC_BASE_URL}guides/play-framework-1/`,
    }
  }
  else if (type === 'java+play2') {
    return {
      name: 'Play! 2',
      link: `${Console.DOC_BASE_URL}guides/play-framework-2/`,
    }
  }
  else if (type === 'java+sbt') {
    return {
      name: 'Scala',
      link: `${Console.DOC_BASE_URL}doc/applications/scala/`,
    }
  }
  else if (type === 'java+war') {
    return {
      name: 'War/Ear',
      link: `${Console.DOC_BASE_URL}doc/applications/java/java-war/`,
    }
  }
  else if (type === 'node+node') {
    return {
      name: 'Node.js',
      link: `${Console.DOC_BASE_URL}doc/applications/javascript/nodejs/`,
    }
  }
  else if (type === 'node+meteor') {
    return {
      name: 'Meteor.js',
      link: `${Console.DOC_BASE_URL}doc/applications/javascript/meteor/`,
    }
  }
  else if (type === 'php+php') {
    return {
      name: 'PHP',
      link: `${Console.DOC_BASE_URL}doc/applications/php/`,
    }
  }
  else if (type === 'php+static-apache') {
    return {
      name: 'Static',
      link: `${Console.DOC_BASE_URL}doc/applications/static/`,
    }
  }
  else if (type === 'python+python') {
    return {
      name: 'Python',
      link: `${Console.DOC_BASE_URL}doc/applications/python/`,
    }
  }
  else if (type === 'ruby+ruby') {
    return {
      name: 'Ruby',
      link: `${Console.DOC_BASE_URL}doc/applications/ruby/`,
    }
  }
  else if (type === 'rust+rust') {
    return {
      name: 'Rust',
      link: `${Console.DOC_BASE_URL}doc/applications/rust/`,
    }
  }
  return {};
}

export function getAddonDocumentationInfos(type) {
  if (type === 'cellar-addon') {
    return {
      name: 'Cellar',
      link: `${Console.DOC_BASE_URL}doc/addons/cellar/`,
    }
  }
  else if (type === 'config-provider') {
    return {
      name: 'Config. provider',
      link: `${Console.DOC_BASE_URL}doc/addons/config-provider/`,
    }
  }
  else if (type === 'es-addon') {
    return {
      name: 'Elastic Stack',
      link: `${Console.DOC_BASE_URL}doc/addons/elastic/`,
    }
  }
  else if (type === 'fs-bucket') {
    return {
      name: 'FS Buckets',
      link: `${Console.DOC_BASE_URL}doc/addons/fs-bucket/`,
    }
  }
  else if (type === 'jenkins') {
    return {
      name: 'Jenkins',
      link: `${Console.DOC_BASE_URL}doc/addons/jenkins/`,
    }
  }
  else if (type === 'mailpace') {
    return {
      name: 'MailPace',
      link: `${Console.DOC_BASE_URL}doc/addons/mailpace/`,
    }
  }
  else if (type === 'mongodb-addon') {
    return {
      name: 'MongoDB',
      link: `${Console.DOC_BASE_URL}doc/addons/mongodb/`,
    }
  }
  else if (type === 'mysql-addon') {
    return {
      name: 'MySQL',
      link: `${Console.DOC_BASE_URL}doc/addons/mysql/`,
    }
  }
  else if (type === 'postgresql-addon') {
    return {
      name: 'PostgreSQL',
      link: `${Console.DOC_BASE_URL}doc/addons/postgresql/`,
    }
  }
  else if (type === 'addon-pulsar') {
    return {
      name: 'Pulsar',
      link: `${Console.DOC_BASE_URL}doc/addons/pulsar/`,
    }
  }
  else if (type === 'redis-addon') {
    return {
      name: 'Redis',
      link: `${Console.DOC_BASE_URL}doc/addons/redis/`,
    }
  }
  return {};
}
