module.exports = (function(){

          var _ = require("lodash");

          this["Templates"] = this["Templates"] || {};

this["Templates"]["dropdown"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="dropdown ' +
__e( multipleValues ? 'multiple' : '' ) +
'">\n  <div class="dropdown-choice">\n    <span class="dropdown-choice-value ' +
__e( defaultText.length > 0 ? 'default-text' : '' ) +
'">\n      ';
 if(escapeHTML) {;
__p += '\n        ' +
__e( defaultText ) +
'\n      ';
}else{;
__p += '\n        ' +
((__t = ( defaultText )) == null ? '' : __t) +
'\n      ';
};
__p += '\n    </span>\n    <i class="ion-icon ion-arrow-down-b"></i>\n  </div>\n  <div class="dropdown-body">\n    ';
 if(autocomplete){ ;
__p += '\n      <div class="dropdown-search">\n        <div class="input-search">\n          <input type="text" placeholder="' +
__e( placeholder ) +
'"/>\n        </div>\n      </div>\n    ';
};
__p += '\n    <div class="dropdown-results"></div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["dropdown.list-body"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ul>\n  ';
 if(_.size(map) > 0){
    _.each(map, function(list, key){ ;
__p += '\n      <li>\n        ' +
((__t = ( Templates["dropdown.list-item"]({
          list: list,
          key: key,
          escapeHTML: escapeHTML
        }) )) == null ? '' : __t) +
'\n      </li>\n    ';
});
  } else{ ;
__p += '\n    <li>\n      ' +
((__t = ( Templates["dropdown.list-no-results"]({T: T}) )) == null ? '' : __t) +
'\n    </li>\n  ';
};
__p += '\n</ul>\n';

}
return __p
};

this["Templates"]["dropdown.list-item"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<strong>' +
__e( key ) +
'</strong>\n<ul>\n  ';
 _.each(list, function(val){ ;
__p += '\n    <li data-id="' +
__e( val.id ) +
'" data-value="' +
__e( val.name ) +
'">\n      ';
 if(escapeHTML) {;
__p += '\n        ' +
__e( val.name ) +
'\n      ';
} else {;
__p += '\n        ' +
((__t = ( val.name )) == null ? '' : __t) +
'\n      ';
};
__p += '\n    </li>\n  ';
});
__p += '\n</ul>\n';

}
return __p
};

this["Templates"]["dropdown.list-no-results"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<strong>' +
__e( T("console.dropdown.no-results") ) +
'</strong>\n';

}
return __p
};

this["Templates"]["dropdown.multiple-values-item"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<span class="value"><i class="ion ion-android-close" data-id="' +
__e( e.id ) +
'"></i>\n  ';
 if(state.escapeHTML){ ;
__p += '\n    ' +
__e( e.value ) +
'\n  ';
} else {;
__p += '\n    ' +
((__t = ( e.value )) == null ? '' : __t) +
'\n  ';
};
__p += '\n</span>\n';

}
return __p
};

          return this["Templates"];

        })();