module.exports = (function() {

  var path4js = require('path4js');
  const { logout } = require('../login-oauth.js');

  var sp = new path4js.SimpleStatePoint({
    work: function(req) {
      sp.fireEvent('onload', req);
    }
  });

  sp.on('onload', function() {

    function finishLogout () {
      logout();

      /* Change url in order to avoid a HTTP redirection to /auth/logout on user login */
      history.pushState(null, null, "/");
      window.location.href = Console.configuration.API_HOST + "/v2/session/login";
    };

    const oauth_token = Console.LoginAs.getTokens().user_oauth_token;

    API.self.tokens._.delete().withParams([oauth_token]).send()
      .onValue(() => finishLogout());
  });

  return sp;
})();
